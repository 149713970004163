import { isMobile as SourceIsMobile } from 'SourceUtil/Mobile/isMobile';

export * from 'SourceUtil/Mobile/isMobile';

/*
** Overridden to change mobile/desktop breakpoint
 */
export const isMobile = {
    ...SourceIsMobile,
    any: () => window.matchMedia('(max-width: 1240px)').matches
};

export default isMobile;
