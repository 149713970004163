import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import {
    MyAccountForgotPassword as SourceMyAccountForgotPassword
} from 'SourceComponent/MyAccountForgotPassword/MyAccountForgotPassword.component';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

/** @namespace Scandipwa/Component/MyAccountForgotPassword/Component */
export class MyAccountForgotPasswordComponent extends SourceMyAccountForgotPassword {
    /**
     * Overridden to change button label
     */
    renderForgotPasswordForm() {
        const { onForgotPasswordSuccess, onFormError } = this.props;

        return (
            <Form
              key="forgot-password"
              onSubmit={ onForgotPasswordSuccess }
              onError={ onFormError }
            >
                <Field
                  type={ FIELD_TYPE.email }
                  label={ __('Email') }
                  attr={ {
                      id: 'email',
                      name: 'email',
                      class: 'ForgotPassword-Input_type_email',
                      placeholder: __('Your email address'),
                      autocomplete: 'email'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email
                  } }
                  addRequiredTag
                />
                <div block="MyAccountOverlay" elem="Buttons">
                    <button
                      block="Button"
                      type="submit"
                      mix={ { block: 'MyAccountOverlay', elem: 'ResetPassword' } }
                    >
                        { __('Submit') }
                    </button>
                </div>
            </Form>
        );
    }
}

export default MyAccountForgotPasswordComponent;
