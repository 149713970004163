import { EVENT_GTM_GENERAL_INIT } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const containerProps = (args, callback, instance) => {
    const { item: { item_class } = {}, onItemClick, event } = instance.props;

    const isDownload = item_class.includes('type_downloadable');

    const afterOnItemClick = () => {
        if (isDownload) {
            if (onItemClick) {
                event(EVENT_GTM_GENERAL_INIT);
                onItemClick();
            } else {
                event(EVENT_GTM_GENERAL_INIT);
            }
        }
    };

    return {
        ...callback(...args),
        onItemClick: afterOnItemClick
    };
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export default {
    'Scandipwa/Component/MenuItem/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Scandipwa/Component/MenuItem/Container': {
        'member-function': {
            containerProps
        }
    }
};
