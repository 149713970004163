export const SET_FOOTER_VISIBLE = 'SET_FOOTER_VISIBLE';

/**
 * Set footer visibility
 * Done to hide footer on some pages
 * @param {boolean} isVisible boolean if footer should be visible
 * @returns {void}
* @namespace Scandipwa/Store/Footer/Action/setFooterVisible */
export const setFooterVisible = (isVisible) => ({
    type: SET_FOOTER_VISIBLE,
    isVisible
});
