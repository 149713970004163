/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { getPriceFilterLabel } from 'Util/Category';

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        currencyRate: state.ProductListInfoReducer.currencyRate
    };
};

export const getFilterOptionsForPrice = (args, callback, instance) => {
    const { currency_code, currencyRate } = instance.props;
    const priceFilterValue = args[0][0];
    const [fromValue, toValue] = priceFilterValue.split('_');

    const label = getPriceFilterLabel(fromValue * currencyRate, toValue * currencyRate, currency_code);

    return [{ priceFilterValue, label }];
};

export default {
    'Component/ResetAttributes/Container': {
        'member-function': {
            getFilterOptionsForPrice
        }
    },
    'Component/ResetAttributes/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
