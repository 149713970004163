export * from 'SourceUtil/Orders/Orders';

export const ORDER_STATUSES = {
    new: 'new',
    processing: 'processing',
    closed: 'closed',
    complete: 'complete',
    canceled: 'canceled',
    denied: 'denied',
    reserved: 'reserved',
    ihfApproved: 'ihf_approved',
    ihfDeclined: 'ihf_declined',
    showroomOnly: 'showroom_only',
    review: 'in_review'
};

export const ORDER_STATUSES_MAP = {
    [ORDER_STATUSES.closed]: {
        finished: true
    },
    [ORDER_STATUSES.complete]: {
        finished: true
    },
    [ORDER_STATUSES.canceled]: {
        finished: true
    },
    [ORDER_STATUSES.denied]: {
        finished: true
    }
};

/** @namespace Scandipwa/Util/Orders/getCarFromOrder */
export const getCarFromOrder = (order) => order?.find(({ is_car }) => !!is_car);

/** @namespace Scandipwa/Util/Orders/getOptionsFromOrder */
export const getOptionsFromOrder = (order) => {
    const optionItems = {};
    const optionArray = order?.filter(({ is_car }) => !is_car);
    const optionTotal = optionArray?.reduce((acc, option) => {
        if (option.type_id === 'dynamic') {
            return acc;
        }

        optionItems[option.sku] = option;
        return acc + Number(option?.attributes?.price?.attribute_value);
    }, 0);

    return {
        items: optionItems,
        total: optionTotal
    };
};

/** @namespace Scandipwa/Util/Orders/getInsuranceFromOrder */
export const getInsuranceFromOrder = (order) => {
    const insurance = order?.find(({ is_car, type_id }) => !is_car && type_id === 'dynamic');

    if (insurance) {
        const {
            price_range: {
                minimum_price: {
                    default_price: {
                        value = 0
                    } = {}
                } = {}
            } = {}
        } = getCarFromOrder(order);

        const {
            attributes: {
                price_percentage: {
                    attribute_value
                } = {}
            } = {}
        } = insurance;
        const insurancePrice = value * Number(attribute_value);

        return {
            insurance,
            insurancePrice
        };
    }

    return false;
};
