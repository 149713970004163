/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { lazy } from 'react';

export const PriceSlider = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "price-slider" */
    '../component/PriceSlider'
));

const renderPriceSwatch = (args, callback, instance) => {
    const {
        parameters,
        updateConfigurableVariant,
        configurable_options: {
            price: {
                attribute_code,
                attribute_label
            } = {}
        } = {},
        priceSliderKey
    } = instance.props;

    return (
        <PriceSlider
          key={ priceSliderKey }
          parameters={ parameters }
          priceCode={ attribute_code }
          priceLabel={ attribute_label }
          updateConfigurableVariant={ updateConfigurableVariant }
        />
    );
};

export default {
    'Component/CategoryConfigurableAttributes/Component': {
        'member-function': {
            renderPriceSwatch
        }
    }
};
