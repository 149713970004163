export const ADVANCE_STEP = 'ADVANCE_STEP';
export const RESET_STEPS = 'RESET_STEPS';
export const CHANGE_STEP = 'CHANGE_STEP';
export const INCREMENT_VIEWED = 'INCREMENT_VIEWED';

/** @namespace Scandipwa/Store/ProductStep/Action/advanceStep */
export const advanceStep = (payload) => ({
    type: ADVANCE_STEP,
    payload
});

/** @namespace Scandipwa/Store/ProductStep/Action/resetStep */
export const resetStep = (payload) => ({
    type: RESET_STEPS,
    payload
});

/** @namespace Scandipwa/Store/ProductStep/Action/changeStep */
export const changeStep = (payload) => ({
    type: CHANGE_STEP,
    payload
});

/** @namespace Scandipwa/Store/ProductStep/Action/incrementParamsViewed */
export const incrementParamsViewed = (payload) => ({
    type: INCREMENT_VIEWED,
    payload
});
