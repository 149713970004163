import { PureComponent } from 'react';

import './DownloadIcon.style';

/** @namespace Scandipwa/Component/DownloadIcon/Component */
export class DownloadIconComponent extends PureComponent {
    render() {
        return (
            <svg
              block="DownloadIcon"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M6.5 8L10 11.5L13.5 8" />
                <path d="M10 1.5V11.5" />
                <path d="M2.5 11.293V16.624H17.5127V11.293" />
            </svg>
        );
    }
}

export default DownloadIconComponent;
