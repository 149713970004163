import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { STATE_FORGOT_PASSWORD_SUCCESS } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
    MyAccountForgotPasswordContainer as SourceMyAccountForgotPasswordContainer
} from 'SourceComponent/MyAccountForgotPassword/MyAccountForgotPassword.container';
import { updateMeta } from 'Store/Meta/Meta.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import transformToNameValuePair from 'Util/Form/Transform';
import history from 'Util/History';

export { mapStateToProps };

/** @namespace Scandipwa/Component/MyAccountForgotPassword/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Scandipwa/Component/MyAccountForgotPassword/Container */
export class MyAccountForgotPasswordContainer extends SourceMyAccountForgotPasswordContainer {
    static propTypes = {
        ...SourceMyAccountForgotPasswordContainer.propTypes,
        updateMeta: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        isPopUp: PropTypes.bool
    };

    componentDidMount() {
        this.updateMetaTitle();
    }

    updateMetaTitle() {
        const { updateMeta } = this.props;

        updateMeta({
            title: __('Find Password')
        });
    }

    // Overridden to add success notification
    async onForgotPasswordSuccess(_form, fields) {
        const {
            forgotPassword,
            setSignInState,
            setLoadingState,
            showNotification,
            hideActiveOverlay,
            isPopUp = false
        } = this.props;

        setLoadingState(true);

        try {
            await forgotPassword(transformToNameValuePair(fields));
            setSignInState(STATE_FORGOT_PASSWORD_SUCCESS);
            // eslint-disable-next-line max-len
            showNotification('success', __('If there is an account associated with the provided address you will receive an email with a link to reset your password.'));
        } finally {
            setLoadingState(false);
            if (isPopUp) {
                hideActiveOverlay();
                history.goBack();
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountForgotPasswordContainer);
