import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductBundleOptionContainer as SourceProductBundleOptionContainer
} from 'SourceComponent/ProductBundleOption/ProductBundleOption.container';
import { ItemOptionsType } from 'Type/ProductList.type';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandipwa/Component/ProductBundleOption/Container */
export class ProductBundleOptionContainer extends SourceProductBundleOptionContainer {
    static propTypes = {
        ...this.propTypes,
        options: ItemOptionsType
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductBundleOptionContainer);
