import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import { MixType, ModsType } from 'Type/Common.type';
import { noopFn } from 'Util/Common';

import DragScroll from './DragScroll.component';

/** @namespace Scandipwa/Component/DragScroll/Container */
export class DragScrollContainer extends PureComponent {
    static propTypes = {
        children: PropTypes.arrayOf(PropTypes.node),
        mix: MixType,
        mods: ModsType,
        setRef: PropTypes.func
    };

    static defaultProps = {
        children: [],
        mix: {},
        mods: {},
        setRef: noopFn
    };

    state = {
        isScrolling: false,
        clientX: 0,
        scrollX: 0
    };

    scrollRef = createRef();

    componentDidMount() {
        const { setRef } = this.props;
        setRef(this.scrollRef.current);

        this.scrollRef.current.addEventListener('mousedown', this.handleMouseDown.bind(this));
        document.addEventListener('mouseup', this.handleMouseUp.bind(this));
        this.scrollRef.current.addEventListener('mouseleave', this.handleMouseUp.bind(this));
        this.scrollRef.current.addEventListener('mousemove', this.handleMouseMove.bind(this));
        this.scrollRef.current.addEventListener('wheel', this.handleScroll.bind(this));
    }

    componentWillUnmount() {
        this.scrollRef.current.removeEventListener('mousedown', this.handleMouseDown.bind(this));
        document.removeEventListener('mouseup', this.handleMouseUp.bind(this));
        this.scrollRef.current.removeEventListener('mouseleave', this.handleMouseUp.bind(this));
        this.scrollRef.current.removeEventListener('mousemove', this.handleMouseMove.bind(this));
        this.scrollRef.current.removeEventListener('mousemove', this.handleScroll.bind(this));
    }

    containerProps() {
        const { isScrolling } = this.state;
        const {
            children,
            mix,
            mods,
            setRef
        } = this.props;
        const { scrollRef } = this;

        return {
            isScrolling,
            children,
            scrollRef,
            mix,
            mods,
            setRef
        };
    }

    handleScroll(e) {
        e.preventDefault();
        this.scrollRef.current.scrollLeft += e.deltaY;
    }

    handleMouseDown(e) {
        this.setState({
            isScrolling: true,
            clientX: e.clientX,
            scrollX: -this.scrollRef.current.scrollLeft
        });
    }

    handleMouseUp() {
        this.setState({ isScrolling: false });
    }

    handleMouseMove(e) {
        const { isScrolling } = this.state;

        if (isScrolling) {
            this.setState((prevState) => {
                const { clientX, scrollX } = prevState;
                const scroll = scrollX + e.clientX - clientX;
                this.scrollRef.current.scrollLeft = -scroll;

                return {
                    scrollX: scroll,
                    clientX: e.clientX
                };
            });
        }
    }

    render() {
        return (
            <DragScroll
              { ...this.containerProps() }
            />
        );
    }
}

export default DragScrollContainer;
