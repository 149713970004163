import { Field } from 'Util/Query';

export const aroundGetProductInterfaceFields = (args, callback, instance) => {
    const fields = callback.apply(instance, args);

    fields.push(
        instance._getPriceRangeField()
    );

    return fields;
};

export const _getCartProductField = (args, callback, instance) => new Field('product')
    .addFieldList([
        'id',
        'sku',
        'stock_status',
        'salable_qty',
        instance._getStockItemField(),
        instance._getProductThumbnailField(),
        instance._getAttributesField(true, true),
        instance._getPriceRangeField()
    ]);

export default {
    'Query/ProductList/Query': {
        'member-function': {
            _getCartProductInterfaceFields: aroundGetProductInterfaceFields,
            _getCartProductField
        }
    }
};
