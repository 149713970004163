import { ProductListQuery as SourceProductListQuery } from 'SourceQuery/ProductList.query';
import { Field, Fragment } from 'Util/Query';

/** @namespace Scandipwa/Query/ProductList/Query */
export class ProductListQuery extends SourceProductListQuery {
    _getProductsField() {
        const { isSupportive = false } = this.options;

        const products = new Field('products')
            .addFieldList(
                isSupportive ? this._getSupportiveProductFields() : this._getProductFields()
            );

        this._getProductArguments().forEach((arg) => products.addArgument(...arg));

        return products;
    }

    /*
    * Overridden to add isOrder flag
    */
    _getCartProductInterfaceFields(isOrder = false) {
        return [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'url',
            'salable_qty',
            this._getStockItemField(),
            this._getProductThumbnailField(),
            this._getCartConfigurableProductFragment(),
            this._getAttributesField(false, !isOrder),
            this._getProductLinksField(),
            ...(isOrder ? [this._getCategoriesField()] : [])
        ];
    }

    /*
    * Added automatic related product fields to product interface
     */
    _getProductInterfaceFields(isVariant, isForLinkedProducts = false, isForWishlist = false) {
        const {
            isPlp = false,
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false
        } = this.options;

        // set option to always request images for product variants if they're requested for wishlist
        if (isForWishlist) {
            this.options.isForWishlist = true;
        }

        // Basic fields except for stock fields (JAID-658) returned always
        const fields = [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'salable_qty',
            this._getPriceRangeField()
        ];

        // Additional fields, which we want to return always, except when it's variants on PLP (due to hugh number of items)
        if (!(isPlp && isVariant) || isForWishlist) {
            fields.push(
                this._getProductImageField(),
                this._getProductThumbnailField(),
                this._getProductSmallField(),
                this._getShortDescriptionField(),
                'special_from_date',
                'special_to_date',
                this._getTierPricesField()
            );
        }

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(
                this._getAttributesField(isVariant)
            );
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push(
                'url',
                this._getUrlRewritesFields(),
                this._getReviewCountField(),
                this._getRatingSummaryField(),
                this._getCustomizableProductFragment(),
                this._getCategoriesField()
            );

            // if variants are not needed
            if (!noVariants) {
                fields.push(
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment(),
                    this._getGroupedProductItems()
                );
            }
        }

        // prevent linked products from looping
        // added auto related fields
        if (isForLinkedProducts) {
            fields.push(
                this._getProductLinksField(),
                this._getARPField()
            );
        }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                'stock_status',
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment(),
                this._getARPField()
            );

            // for variants of PDP requested product
            // added auto related fields
            if (!isVariant) {
                fields.push(
                    'canonical_url',
                    'meta_title',
                    'meta_keyword',
                    'meta_description',
                    this._getCategoriesField(),
                    this._getReviewsField(),
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment(),
                    this._getProductLinksField()
                );
            }
        }

        return fields;
    }

    /*
    *   Added automatic related field
     */
    _getARPField() {
        return new Field('automatic_related_products')
            .addFieldList(this._getARPFields());
    }

    /*
    * Added auto related field list
     */
    _getARPFields() {
        return [
            'position',
            'link_type',
            'linked_product_sku',
            'rule_id',
            'block_name',
            'one_product_added',
            'insurance_rule'
        ];
    }

    _getMediaGalleryFields() {
        return [
            ...super._getMediaGalleryFields(),
            'gallery_image_type'
        ];
    }

    _getSupportiveProductFields() {
        const { isPlp = false, isForWishlist = false } = this.options;

        const supportiveFieldList = this._getSupportiveProductFieldList();
        const variantsFieldName = isPlp && !isForWishlist ? 'variants_plp' : 'variants';

        const variantsField = new Field(variantsFieldName)
            .setAlias('variants')
            .addField(new Field('product').addFieldList(supportiveFieldList));

        const items = new Field('items').addFieldList([
            ...supportiveFieldList,
            new Fragment('ConfigurableProduct').addField(variantsField)
        ]);

        return [items];
    }

    _getSupportiveProductFieldList() {
        return [
            'id',
            'sku',
            'stock_status',
            this._getStockItemField()
        ];
    }
}
export default new ProductListQuery();
