/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/** @namespace Scandipwa/Component/CalendarIcon/Component */
export class CalendarIconComponent extends PureComponent {
  static propTypes = {
      isDisabled: PropTypes.bool
  };

  static defaultProps = {
      isDisabled: false
  };

  render() {
      const { isDisabled } = this.props;
      if (isDisabled) {
          return (
           <svg
             block="CalendarIcon"
             width="20"
             height="20"
             viewBox="0 0 20 20"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
           >
            <path d="M2.5 5C2.5 4.07953 3.24619 3.33333 4.16667 3.33333H15.8333C16.7538 3.33333 17.5 4.07953 17.5 5V16.6667C17.5 17.5872 16.7538 18.3333 15.8333 18.3333H4.16667C3.24619 18.3333 2.5 17.5872 2.5 16.6667V5Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.5 8.33333H17.5" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.084 1.66667V0.916667H12.584V1.66667H14.084ZM12.584 5V5.75H14.084V5H12.584ZM12.584 1.66667V5H14.084V1.66667H12.584Z" fill="#999999" />
            <path d="M7.41602 1.66667V0.916667H5.91602V1.66667H7.41602ZM5.91602 5V5.75H7.41602V5H5.91602ZM5.91602 1.66667V5H7.41602V1.66667H5.91602Z" fill="#999999" />
           </svg>
          );
      }

      return (
          <svg
            block="CalendarIcon"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2.5 5C2.5 4.07953 3.24619 3.33333 4.16667 3.33333H15.8333C16.7538 3.33333 17.5 4.07953 17.5 5V16.6667C17.5 17.5872 16.7538 18.3333 15.8333 18.3333H4.16667C3.24619 18.3333 2.5 17.5872 2.5 16.6667V5Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.5 8.33333H17.5" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.084 1.66667V0.916667H12.584V1.66667H14.084ZM12.584 5V5.75H14.084V5H12.584ZM12.584 1.66667V5H14.084V1.66667H12.584Z" fill="black" />
            <path d="M7.41602 1.66667V0.916667H5.91602V1.66667H7.41602ZM5.91602 5V5.75H7.41602V5H5.91602ZM5.91602 1.66667V5H7.41602V1.66667H5.91602Z" fill="black" />
          </svg>
      );
  }
}

export default CalendarIconComponent;
