export * from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.config';

export const CITY_LIST = [
    () => __('Doha'),
    () => __('Al Daayen'),
    () => __('Al Khor'),
    () => __('Al Rayyan'),
    () => __('Al Shahaniyah'),
    () => __('Al Shamal'),
    () => __('Al Wakra'),
    () => __('Economic Zone'),
    () => __('Lusail'),
    () => __('Marina'),
    () => __('Mesaieed'),
    () => __('Pearl Qatar'),
    () => __('Ras Laffan'),
    () => __('Umm Salal'),
    () => __('West Bay')
];

export const SALUTATION_LIST = [
    () => __('Mr.'),
    () => __('Ms.')
];

export const BOOKING_SUCCESS = 'BOOKING_SUCCESS';
