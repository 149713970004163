import { cloneElement } from 'react';

import ProductCard from 'Component/ProductCard';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';

import { ROOT, SEARCH } from '../component/GoogleTagManager/GoogleTagManager.config';
import {
    EVENT_GTM_IMPRESSIONS_PLP,
    EVENT_GTM_IMPRESSIONS_SEARCH
} from '../component/GoogleTagManager/GoogleTagManager.events';

const state = (original) => ({
    ...original,
    lastSearch: ''
});

const renderItems = (args, callback, instance) => {
    const {
        items,
        event,
        location: {
            pathname
        },
        isLoading
    } = instance.props;

    const { fired = false } = instance.state;

    const isSearch = pathname.includes(SEARCH);

    if (isSearch && !fired && !isLoading) {
        instance.setState({ fired: true });
        const search = pathname.split(ROOT).pop();

        event(EVENT_GTM_IMPRESSIONS_SEARCH, {
            products: items, list: SEARCH, search
        });
    }

    return callback(...args);
};

const renderPageItems = (args, callback, instance) => {
    const {
        items,
        selectedFilters,
        event,
        mix: {
            mods: {
                layout = GRID_LAYOUT
            } = {},
            block = ''
        },
        location: {
            pathname,
            search
        }
    } = instance.props;
    const { fired = false } = instance.state;

    const isSearch = pathname.includes(SEARCH);

    if (!isSearch) {
        if (!(Object.keys(items).length === 0) && !fired) {
            instance.setState({ fired: true });

            if (event) {
                event(EVENT_GTM_IMPRESSIONS_PLP, {
                    products: items, list: block, pathname, search
                });
            }
        }
    }

    const elems = callback(...args);

    return elems.map((elem, i) => (
        cloneElement(
            elem,
            { position: i + 1 }
        )
    ));
};

export default {
    'Component/ProductListPage/Component': {
        'member-function': {
            renderPageItems,
            renderItems
        },
        'member-property': {
            state
        }
    }
};
