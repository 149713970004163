import {
    COLOR_EXTERIOR,
    COLOR_INTERIOR,
    ENGINE,
    TRIM
} from 'Component/ProductActions/ProductActions.config';

import { NOT_APPLICABLE } from '../component/GoogleTagManager/GoogleTagManager.config';
import { CONFIGURABLE, SIMPLE } from '../util/Product/Types';
import {
    getBrand,
    getCategories, getDataAttribute,
    getPrice
} from './baseProduct.data';

/** @namespace GtmNew/EventData/ActionProduct/Data/getDimensionOne */
export const getDimensionOne = (type_id, product, configurableVariantIndex, mainSku) => {
    if (type_id !== CONFIGURABLE) {
        return NOT_APPLICABLE;
    }

    if (!configurableVariantIndex && mainSku && type_id === CONFIGURABLE) {
        const { variants } = product;
        return variants.find(({ sku }) => sku === mainSku).name;
    }

    if (!isNaN(configurableVariantIndex)) {
        const {
            name = ''
        } = product.variants[configurableVariantIndex];

        return name;
    }
};

/** @namespace GtmNew/EventData/ActionProduct/Data/getDimensionTwo */
export const getDimensionTwo = (type_id, product, configurableVariantIndex, inQtyChange = false, mainSku) => {
    if (type_id !== CONFIGURABLE) {
        return NOT_APPLICABLE;
    }

    if (!isNaN(configurableVariantIndex)) {
        if (inQtyChange) {
            return mainSku;
        }
        const {
            sku
        } = product.variants[configurableVariantIndex];

        return sku;
    }
};

/** @namespace GtmNew/EventData/ActionProduct/Data/getCustomDimensionsQtyChange */
export const getCustomDimensionsQtyChange = (item) => {
    const { sku: actionSku, product: { variants = [], dimensions, type_id } } = item;

    if (type_id === SIMPLE) {
        return dimensions ? JSON.parse(dimensions) : null;
    }

    if (type_id === 'configurable') {
        const correctVarint = variants.find(({ sku }) => sku === actionSku);
        const custom = correctVarint.dimensions;

        return custom ? JSON.parse(custom) : null;
    }
};

/** @namespace GtmNew/EventData/ActionProduct/Data/getCustomDimensions */
export const getCustomDimensions = (product, confIndex) => {
    const {
        type_id = SIMPLE, dimensions, variants
    } = product;

    if (type_id === SIMPLE) {
        return dimensions ? JSON.parse(dimensions) : null;
    }

    if (type_id === 'configurable') {
        const custom = variants[confIndex].dimensions;
        return custom ? JSON.parse(custom) : null;
    }
};

/** @namespace GtmNew/EventData/ActionProduct/Data/getDimension1 */
export const getDimension1 = (product, change = '') => {
    if (change === 'change') {
        const { sku, product: { type_id } } = product;

        if (type_id === 'simple') {
            return NOT_APPLICABLE;
        }

        return sku;
    }
    const { sku, type_id } = product;

    if (type_id === 'simple') {
        return NOT_APPLICABLE;
    }

    return sku;
};

/** @namespace GtmNew/EventData/ActionProduct/Data/getDimension2 */
export const getDimension2 = (product, change = '') => {
    if (change === 'change') {
        const {
            product: {
                name,
                type_id
            }
        } = product;

        if (type_id === 'simple' || !name) {
            return NOT_APPLICABLE;
        }

        const { product: { variants } } = product;
        const dimension2 = variants[0].name;

        return dimension2;
    }
    const {
        name,
        type_id
    } = product;

    if (type_id === 'simple' || !name) {
        return NOT_APPLICABLE;
    }

    return name;
};

/** @namespace GtmNew/EventData/ActionProduct/Data/getDimension3 */
export const getDimension3 = (product, change = '') => {
    if (change === 'change') {
        const { product: { type_id } } = product;

        return type_id;
    }
    const { type_id } = product;

    return type_id;
};

/** @namespace GtmNew/EventData/ActionProduct/Data/productEventData */
export const productEventData = (product) => {
    const {
        name,
        sku,
        type_id,
        price = null,
        parentSku = ''
    } = product;

    return {
        id: parentSku || sku,
        name,
        price: getPrice(product, type_id, price),
        quantity: 1,
        brand: getBrand(product),
        // TODO: categories doesn't work for order queries for some reason. As it is not necessary field, removed now.
        // category: getCategories(categories),
        variant: getDataAttribute(product, TRIM),
        dimension1: getDataAttribute(product, ENGINE),
        dimension2: getDataAttribute(product, COLOR_INTERIOR),
        dimension3: getDataAttribute(product, COLOR_EXTERIOR),
        dimension4: parentSku ? sku : NOT_APPLICABLE
    };
};

/** @namespace GtmNew/EventData/ActionProduct/Data/optionsEventData */
export const optionsEventData = (options) => options.map((option) => {
    const {
        sku,
        name,
        type_id,
        price = null
    } = option;

    return {
        id: sku,
        name,
        price: getPrice(option, type_id, price),
        brand: getBrand(option),
        // TODO: categories doesn't work for order queries for some reason. As it is not necessary field, removed now.
        // category: getCategories(categories),
        variant: getDataAttribute(option, TRIM),
        quantity: 1
    };
});

/** @namespace GtmNew/EventData/ActionProduct/Data/productQtyChangeData */
export const productQtyChangeData = ({ product: item, configurableVariantIndex = false }) => {
    const {
        qty = false, price, sku: mainSku = '', product: {
            type_id = SIMPLE, sku: parentSku, name: parentName, variants, dimensions
        }, product
    } = item;

    return {
        name: parentName,
        id: parentSku,
        price: (type_id === CONFIGURABLE)
            ? getPrice(variants[0], type_id, price, false)
            : getPrice(product, type_id, price, false),
        brand: getBrand(product),
        dimension1: getDimension1(item, 'change'),
        dimension2: getDimension2(item, 'change'),
        dimension3: getDimension3(item, 'change'),
        dimension4: mainSku,
        quantity: Math.abs(qty)
    };
};
