/* eslint-disable max-lines */
/* eslint-disable react/forbid-prop-types, @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import RemoveOptionButton from 'Component/RemoveOptionButton';
import ResetIcon from 'Component/ResetIcon';
import { formatPrice } from 'Util/Price';

import './RelatedProductTotals.style';
/** @namespace Scandipwa/Component/RelatedProductTotals/Component */
export class RelatedProductTotalsComponent extends PureComponent {
    static propTypes = {
        removeOption: PropTypes.func.isRequired,
        total: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
        clearOptions: PropTypes.func.isRequired,
        items: PropTypes.object.isRequired,
        categorizedItems: PropTypes.object.isRequired,
        isMobile: PropTypes.bool.isRequired,
        isCompact: PropTypes.bool.isRequired,
        categorizedItemsTotals: PropTypes.objectOf(PropTypes.any).isRequired,
        isInsurance: PropTypes.bool.isRequired,
        insurance: PropTypes.objectOf(PropTypes.any).isRequired,
        removeInsurance: PropTypes.func.isRequired
    };

    renderItemCount() {
        const {
            items, insurance: {
                product
            },
            isInsurance
        } = this.props;

        const { length: itemsLength } = Object.keys(items);
        const insuranceLength = product.sku ? 1 : 0;

        const length = isInsurance ? insuranceLength : itemsLength;

        const lengthText = length === 1 ? __('item is selected') : __('items are selected');

        return (
            <div
              block="RelatedProductTotals"
              elem="ItemsCount"
            >
                <div block="Count">
                    <span>{ `${length} ` }</span>
                    { lengthText }
                </div>
                { this.renderClearAll() }
            </div>
        );
    }

    renderClearAll() {
        const { clearOptions, removeInsurance, isInsurance } = this.props;
        return (
            <button onClick={ isInsurance ? removeInsurance : clearOptions } block="ClearAll">
                <ResetIcon />
                <span>
                    { __('Clear All') }
                </span>
            </button>
        );
    }

    renderSelectedItems() {
        const {
            items, categorizedItems, isCompact, isInsurance, insurance: {
                product
            }
        } = this.props;

        if (isInsurance && product.sku) {
            return (
                <div
                  block="RelatedProductTotals"
                  elem="Items"
                >
                    { this.renderInsuranceItem() }
                </div>
            );
        }

        if (isInsurance) {
            return null;
        }

        if (!items || Object.keys(items).length === 0) {
            return null;
        }

        const renderItems = Object.keys(categorizedItems).map(
            (
                category
            ) => this.renderRuleItems(category)
        );

        return (
            <div
              block="RelatedProductTotals"
              elem="Items"
              mods={ { isCompact } }
            >
                { renderItems }
            </div>
        );
    }

    renderMobileSelectedItems() {
        const {
            items, categorizedItems, isInsurance, insurance: {
                product
            }
        } = this.props;

        if (isInsurance && product.sku) {
            return (
                <div
                  block="RelatedProductTotals"
                  elem="Items"
                >
                <div
                  block="RelatedProductTotals"
                  elem="Category"
                >
                    <span block="CategoryLabel">{ __('Insurance') }</span>
                    { this.renderInsuranceItem() }
                </div>
                </div>
            );
        }

        if (isInsurance) {
            return null;
        }

        if (!items || Object.keys(items).length === 0) {
            return null;
        }

        const renderItems = Object.keys(categorizedItems).map(
            (category) => {
                const currentCat = category;

                if (categorizedItems[category]?.length > 0) {
                    return (
                    <div
                      block="RelatedProductTotals"
                      elem="Category"
                    >
                        <span block="CategoryLabel">{ category }</span>
                        { this.renderRuleItems(currentCat) }
                    </div>
                    );
                }

                return null;
            }
        );

        return (
            <div
              block="RelatedProductTotals"
              elem="Items"
            >
                { renderItems }
            </div>
        );
    }

    renderRuleItems(category) {
        const { items, categorizedItems } = this.props;

        return categorizedItems[category].map((prodSku) => {
            const {
                sku,
                name,
                type_id,
                price_range: {
                    maximum_price: {
                        final_price
                    }
                },
                productPrice: {
                    price: {
                        finalPrice: productPriceFinalPrice
                    }
                }
            } = items[prodSku] || {};

            const price = type_id === 'dynamic' ? productPriceFinalPrice : final_price;

            return this.renderItem(sku, name, price, category);
        });
    }

    renderInsuranceItem() {
        const {
            isCompact, currency, removeInsurance,
            insurance: {
                product: {
                    sku,
                    name
                },
                value
            }
        } = this.props;

        return (
            <div
              key={ sku }
              block="RelatedProductTotals"
              elem="Item"
            >
                <div
                  block="RelatedProductTotals"
                  elem="Name"
                >
                    <span block="ItemName">{ name }</span>
                </div>
                <div
                  block="RelatedProductTotals"
                  elem="Price"
                >
                    <span block="ItemPrice">{ `+ ${formatPrice(value, currency)}` }</span>
                    { !isCompact
                        ? <RemoveOptionButton handler={ removeInsurance } />
                        : null }
                </div>
            </div>
        );
    }

    renderItem(sku, name, price, rule) {
        const { removeOption, isCompact } = this.props;
        return (
            <div
              key={ sku }
              block="RelatedProductTotals"
              elem="Item"
            >
                <div
                  block="RelatedProductTotals"
                  elem="Name"
                >
                    <span block="ItemName">{ name }</span>
                </div>
                <div
                  block="RelatedProductTotals"
                  elem="Price"
                >
                    <span block="ItemPrice">{ `+ ${formatPrice(price.value, price.currency)}` }</span>
                    { !isCompact
                        ? <RemoveOptionButton handler={ removeOption } handlerParams={ { sku, rule } } />
                        : null }
                </div>
            </div>
        );
    }

    renderTotalItem(index, name, price) {
        const { currency } = this.props;

        return (
            <div
              key={ index }
              block="RelatedProductTotals"
              elem="Item"
            >
                <div
                  block="RelatedProductTotals"
                  elem="Name"
                >
                    <span block="ItemName">{ `${name}:` }</span>
                </div>
                <div
                  block="RelatedProductTotals"
                  elem="Price"
                >
                    <span block="ItemPrice">{ `+ ${formatPrice(price, currency)}` }</span>
                </div>
            </div>
        );
    }

    renderTotals() {
        const {
            total, currency, isInsurance, isCompact, items
        } = this.props;

        const shouldNotRender = isInsurance || (isCompact && total < 0) || !items.length;

        if (shouldNotRender) {
            return null;
        }

        const price = total ? `+ ${formatPrice(total, currency)}` : '+ 0';

        return (
            <div
              block="RelatedProductTotals"
              elem="TotalsWrapper"
            >
                <div
                  block="RelatedProductTotals"
                  elem="TotalUpgrades"
                >
                    <div block="Label">
                        { __('Total') }
                    </div>
                    <div block="Price">
                        { price }
                    </div>

                </div>

            </div>
        );
    }

    renderCategorizedTotals() {
        const { categorizedItemsTotals } = this.props;

        return categorizedItemsTotals ? Object.entries(categorizedItemsTotals).map(
            ([key, value], index) => (value > 0 ? this.renderTotalItem(index, key, value) : null)
        ) : null;
    }

    render() {
        const {
            isMobile, isCompact
        } = this.props;
        const selectedItems = isMobile ? this.renderMobileSelectedItems() : this.renderSelectedItems();

        if (isCompact) {
            return (
                <div block="RelatedProductTotals">
                    { this.renderCategorizedTotals() }
                    { this.renderTotals() }
                </div>
            );
        }

        return (
            <div block="RelatedProductTotals">
                { this.renderItemCount() }
                { selectedItems }
                { this.renderTotals() }
            </div>
        );
    }
}

export default RelatedProductTotalsComponent;
