import { FieldSelectContainer as SourceContainer } from 'SourceComponent/FieldSelect/FieldSelect.container';

/** @namespace Scandipwa/Component/FieldSelect/Container */
export class FieldSelectContainer extends SourceContainer {
    state = {
        ...this.state,
        fieldValue: '',
        expandingIsOver: false
    };

    getOptions() {
        const {
            options,
            attr: {
                id = 'select',
                selectPlaceholder = __('Select item...'),
                noPlaceholder
            } = {}
        } = this.props;

        if (noPlaceholder) {
            return options;
        }

        return [
            {
                id: `${id}-placeholder`,
                name: `${id}-placeholder`,
                label: selectPlaceholder,
                value: '',
                sort_order: -100,
                isPlaceholder: true,
                isAvailable: false
            },
            ...options
        ];
    }

    /**
     * Overridden to set fieldValue state value
     */
    handleSelectListOptionClick(option) {
        const { changeValueOnDoubleClick, events: { onChange } = {} } = this.props;
        const { value, target: { value: targetValue } = {} } = option;

        const fieldValue = value || targetValue || '';

        this.setState({ fieldValue });

        if (changeValueOnDoubleClick) {
            this.fieldRef.value = this.fieldRef.value === value ? '' : fieldValue;
        } else {
            this.fieldRef.value = fieldValue;
        }

        if (onChange) {
            onChange(fieldValue, this.fieldRef.id);
        }
    }

    /**
     * Overridden to add class when select is fully extended
     */

    handleSelectExpand() {
        if (!this.isSelectDisabled()) {
            this.setState(({ isExpanded }) => ({ isExpanded: !isExpanded }));
            this.toggleExtendingIsOver();
        }
    }

    toggleExtendingIsOver = () => {
        const { expandingIsOver } = this.state;
        if (expandingIsOver) {
            this.setState({ expandingIsOver: false });
            return;
        }
        setTimeout(() => {
            this.setState({ expandingIsOver: true });
            // eslint-disable-next-line no-magic-numbers
        }, 200);
    };

    /**
     * Overridden to not open when disabled
     */

    isSelectDisabled() {
        const { options, isDisabled } = this.props;

        return !options.length || isDisabled;
    }

    /**
     * Overridden to pass fieldValue prop
     */
    containerProps() {
        const { fieldValue, expandingIsOver } = this.state;

        return {
            ...super.containerProps(),
            fieldValue,
            expandingIsOver
        };
    }
}

export default FieldSelectContainer;
