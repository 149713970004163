import { PureComponent } from 'react';

import './ResetIcon.style';

/** @namespace Scandipwa/Component/ResetIcon/Component */
export class ResetIconComponent extends PureComponent {
    render() {
        return (
            <svg
              block="ResetIcon"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M8.93091 2C10.2266 1.99963 11.4824 2.44859
                12.4842 3.27039C13.486 4.09218 14.1718 5.23591 14.4248
                6.50671C14.6778 7.77751 14.4822 9.09673 13.8715
                10.2395C13.2607 11.3823 12.2726 12.2779 11.0755
                12.7737C9.8784 13.2696 8.54643 13.3349 7.30652
                12.9587C6.06661 12.5825 4.99552 11.7879 4.27582
                10.7104C3.55612 9.63298 3.23232 8.33927 3.35962
                7.0498C3.48692 5.76034 4.05744 4.55492 4.97394 3.63898"
                />
                <path d="M2 3.76791L5.14001 3.2959L6.013 6.26889" />
            </svg>
        );
    }
}

export default ResetIconComponent;
