/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
// import PropTypes from 'prop-types';

import { createPortal } from 'react-dom';

import ClickOutside from 'Component/ClickOutside';
import Popup from 'Component/Popup/Popup.component';
import { toggleScroll } from 'Util/Browser';

import './ToolTipPopup.style';
/** @namespace Scandipwa/Component/ToolTipPopup/Component */
export class ToolTipPopupComponent extends Popup {
    renderContent() {
        const {
            children, contentMix, direction, hasCloseBtn
        } = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        return (
            <ClickOutside onClick={ this.handleClickOutside }>
                <div block="Popup" elem="Content" mix={ contentMix } mods={ { direction } }>
                { hasCloseBtn && (
                        <header block="Popup" elem="Header">
                                { this.renderTitle() }
                                { this.renderCloseButton() }
                        </header>
                ) }
                    { this.renderMobilePopupRectangle() }
                    { this.renderNotifications() }
                    { children }
                </div>
            </ClickOutside>
        );
    }

    renderMobilePopupRectangle() {
        const { isMobile } = this.props;

        if (isMobile) {
            return (
                <div block="Popup" elem="TopWrapper">
                    <span block="Rectangle" />
                </div>
            );
        }

        return null;
    }

    freezeScroll() {
        this.YoffsetWhenScrollDisabled = window.pageYOffset || document.body.scrollTop;
        toggleScroll(false);
    }

    onVisible() {
        const { onVisible, isMobile } = this.props;

        if (isMobile) {
            this.freezeScroll();

            window.addEventListener('popstate', this.hidePopUp);

            window.history.pushState(
                {
                    popupOpen: true
                },
                '',
                `${location.pathname}${location.search}${location.hash}`
            );
        }

        this.overlayRef.current.focus();

        onVisible();
    }

    onHide() {
        const { onHide, isMobile } = this.props;

        if (isMobile) {
            this.unfreezeScroll();

            window.removeEventListener('popstate', this.hidePopUp);
        }

        onHide();
    }

    hidePopUp() {
        const {
            hideActiveOverlay, onClose, setActive, isMobile
        } = this.props;
        const isVisible = this.getIsVisible();
        setActive();

        if (isVisible) {
            if (isMobile) {
                this.unfreezeScroll();
            }
            hideActiveOverlay();
            onClose();
        }
    }

    hidePopupAndGoBack() {
        const { isMobile } = this.props;
        this.hidePopUp();

        if (isMobile) {
            window.history.back();
        }
    }

    renderDesktop() {
        const { mix, areOtherOverlaysOpen } = this.props;
        const isVisible = this.getIsVisible();
        return (
            <div
              ref={ this.overlayRef }
              block="Popup"
              mods={ { isVisible, isInstant: areOtherOverlaysOpen } }
              mix={ { ...mix, mods: { ...mix.mods, isVisible } } }
            >
                { this.renderContent() }
            </div>
        );
    }

    renderMobile() {
        const { mix, areOtherOverlaysOpen } = this.props;
        const isVisible = this.getIsVisible();

        return createPortal(
            <div
              ref={ this.overlayRef }
              block="Popup"
              mods={ { isVisible, isInstant: areOtherOverlaysOpen } }
              mix={ { ...mix, mods: { ...mix.mods, isVisible } } }
            >
                { this.renderContent() }
            </div>,
            document.body
        );
    }

    render() {
        const { isMobile } = this.props;

        if (isMobile) {
            return this.renderMobile();
        }

        return this.renderDesktop();
    }
}

export default ToolTipPopupComponent;
