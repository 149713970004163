/* eslint-disable max-len */
import { PureComponent } from 'react';

import './ErrorIcon.style';

/** @namespace Scandipwa/Component/ErrorIcon/Component */
export class ErrorIconComponent extends PureComponent {
    render() {
        return (
            <svg
              block="ErrorIcon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8Z" />
                <path d="M7.11114 8.88892H8.88892V3.55558H7.11114L7.11114 8.88892Z" />
                <path d="M7.55569 8.44446H8.44458V4.00001H7.55569L7.55569 8.44446Z" />
                <path d="M7.99992 9.77779C7.26354 9.77779 6.66659 10.3747 6.66659 11.1111C6.66659 11.8475 7.26354 12.4445 7.99992 12.4445C8.7363 12.4445 9.33325 11.8475 9.33325 11.1111C9.33325 10.3747 8.7363 9.77779 7.99992 9.77779Z" />
                <path d="M8.00003 10.2222C7.50911 10.2222 7.11114 10.6202 7.11114 11.1111C7.11114 11.602 7.50911 12 8.00003 12C8.49095 12 8.88892 11.602 8.88892 11.1111C8.88892 10.6202 8.49095 10.2222 8.00003 10.2222Z" />
            </svg>
        );
    }
}

export default ErrorIconComponent;
