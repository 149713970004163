import {
    Meta as SourceMeta
} from 'SourceComponent/Meta/Meta.component';

/** @namespace Scandipwa/Component/Meta/Component */
export class MetaComponent extends SourceMeta {
    /**
     * Overridden to only render the dynamic title as oppose to
     * rendering the default title first then the the dynamic
     */
    renderTitle() {
        const {
            title_prefix,
            title_suffix,
            title
        } = this.props;

        const titlePrefix = title_prefix ? `${ title_prefix } | ` : '';
        const titleSuffix = title_suffix ? ` | ${ title_suffix }` : '';

        return (
            <title>
                { `${ titlePrefix }${ title }${ titleSuffix }` }
            </title>
        );
    }

    /**
     * Overriden to add 'property' prop
     * in case name is not set
     */
    renderMeta() {
        const { metadata } = this.props;

        return (
            <>
                { this.renderTitle() }
                { this.renderCanonical() }
                { metadata.map((tag) => {
                    const {
                        name = null,
                        property = null,
                        content = null
                    } = tag;

                    return (
                        <meta
                          key={ name || property }
                          name={ name }
                          property={ property }
                          content={ content }
                        />
                    );
                }) }
            </>
        );
    }
}

export default MetaComponent;
