import {
    UPDATE_BASE_ORDER_INFO,
    UPDATE_DELIVERY_INFO,
    UPDATE_IHF_AVAILABLE,
    UPDATE_ORDER,
    UPDATE_PAYMENT_INFO,
    UPDATE_RESERVATION_PAYMENT_DATA,
    UPDATE_STATUS
} from './Reservation.action';

/** @namespace Scandipwa/Store/Reservation/Reducer/getInitialState */
export const getInitialState = () => ({
    order: [],
    isOrderLoading: false,
    baseOrderInfo: {},
    reservationPaymentData: [],
    paymentData: {},
    deliveryData: {},
    isIhfAvailable: false
});

/** @namespace Scandipwa/Store/Reservation/Reducer/ReservationReducer */
export const ReservationReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_ORDER:
        const { payload: order } = action;

        return {
            ...state,
            order
        };
    case UPDATE_STATUS:
        const { payload: isOrderLoading } = action;

        return {
            ...state,
            isOrderLoading
        };
    case UPDATE_BASE_ORDER_INFO:
        const { payload: baseOrderInfo } = action;

        return {
            ...state,
            baseOrderInfo
        };
    case UPDATE_RESERVATION_PAYMENT_DATA:
        const { payload: reservationPaymentData } = action;

        return {
            ...state,
            reservationPaymentData
        };
    case UPDATE_PAYMENT_INFO:
        const { payload: paymentData } = action;

        return {
            ...state,
            paymentData
        };
    case UPDATE_DELIVERY_INFO:
        const { payload: deliveryData } = action;

        return {
            ...state,
            deliveryData
        };
    case UPDATE_IHF_AVAILABLE:
        const { payload: isIhfAvailable } = action;

        return {
            ...state,
            isIhfAvailable
        };
    default:
        return state;
    }
};

export default ReservationReducer;
