import { SUCCESS_STEP } from 'Route/OrderReservation';

import { EVENT_GTM_CHECKOUT, EVENT_GTM_PURCHASE } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export class OrderReservationContainerPlugin {
    isFired = false;

    afterComponentDidUpdate = this.afterComponentDidUpdate.bind(this);

    afterComponentWillUnmount = this.afterComponentWillUnmount.bind(this);

    afterComponentWillUnmount(args, callback, instance) {
        this.isFired = false;

        callback.apply(instance, args);
    }

    afterComponentDidUpdate(args, callback, instance) {
        const [prevProps, prevState] = args;
        const { step: prevStep } = prevState;
        const { reservationData: { isOrderLoading: prevIsOrderLoading } } = prevProps;
        const { step, paymentMethod } = instance.state;
        const {
            event, reservationData, reservationData: { isOrderLoading }, location
        } = instance.props;
        const purchasePayload = {
            ...reservationData,
            orderPaymentMethod: paymentMethod,
            location
        };

        const isFirstLoad = !this.isFired && prevIsOrderLoading && !isOrderLoading && reservationData?.order?.length;

        if (isFirstLoad) {
            if (step === SUCCESS_STEP) {
                event(EVENT_GTM_PURCHASE, purchasePayload);
            } else {
                event(EVENT_GTM_CHECKOUT, { ...reservationData, step });
            }
            this.isFired = true;
        } else if (prevStep !== step && reservationData?.order.length) {
            if (step === SUCCESS_STEP) {
                event(EVENT_GTM_PURCHASE, purchasePayload);
            } else {
                event(EVENT_GTM_CHECKOUT, { ...reservationData, step });
            }
        }

        callback.apply(instance, args);
    }

    async asyncEventDispatch(event, name, payload) {
        const eventDelay = 5;
        await new Promise((resolve) => setTimeout(resolve, eventDelay));
        event(name, payload);
    }
}

const { afterComponentDidUpdate, afterComponentWillUnmount } = new OrderReservationContainerPlugin();

export default {
    'Scandipwa/Route/OrderReservation/Container': {
        'member-function': {
            componentDidUpdate: afterComponentDidUpdate,
            componentWillUnmount: afterComponentWillUnmount
        }
    },
    'Scandipwa/Route/OrderReservation/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
