/* eslint-disable react/no-unknown-property */
/* eslint-disable max-len */
/* eslint-disable max-lines */
import { Fragment } from 'react';

import ExpandableContent from 'Component/ExpandableContent';
import ProductMonthlyPrice from 'Component/ProductMonthlyPrice';
import {
    ProductAttributes as SourceProductAttributes
} from 'SourceComponent/ProductAttributes/ProductAttributes.component';
import { formatPrice } from 'Util/Price/Price';

import './ProductAttributes.override.style';

/** @namespace Scandipwa/Component/ProductAttributes/Component */
export class ProductAttributesComponent extends SourceProductAttributes {
    /*
    * Overridden to add add adjustments to rendering configurable attributes
    *
    */
    renderAttributes(attributes) {
        if (!Object.keys(attributes).length) {
            return null;
        }

        return (
            <dl block="ProductAttributes" elem="Attributes">
                { Object.values(attributes).map((element) => {
                    if (element && Object.keys(element).length > 1 && element?.attribute_value) {
                        return this.renderAttribute(element);
                    }

                    return null;
                }) }
            </dl>
        );
    }

    /*
    * Overridden to render vehicle and color attribute sections
    *
    */
    renderGroups() {
        const {
            attributesWithValues,
            colorsWithValues,
            isSummaryStep,
            isDealersOfferStep,
            price: {
                valueFormatted = ''
            }
        } = this.props;

        const groupMap = {
            Vehicle: {
                attributes: attributesWithValues,
                hasPrice: !isDealersOfferStep,
                price: valueFormatted,
                name: __('Vehicle')
            },
            Color: {
                attributes: colorsWithValues,
                hasPrice: false,
                name: __('Color')
            }
        };

        return (
            Object.keys(groupMap).map(
                (group, idx) => {
                    const {
                        attributes, hasPrice, price = 0, name
                    } = groupMap[group];

                    if (Object.keys(attributes).length < 1) {
                        return null;
                    }

                    const isSummaryFirstAttributeBlock = isSummaryStep && idx === 0;

                    if (isDealersOfferStep) {
                        const header = () => (
                            <div block="ProductAttributes" elem="AttributeHeader">
                                <h2 block="ProductAttributes" elem="Group">
                                    { name }
                                </h2>
                                { hasPrice && (
                                    <div block="ProductAttributes" elem="AttributeHeaderPrice">
                                        { price }
                                    </div>
                                ) }
                            </div>
                        );

                        return (
                            <ExpandableContent
                              heading={ header() }
                              mix={ { block: 'ProductAttributes', elem: 'AttributeExpandableBlock' } }
                              isArrow
                              isContentExpanded
                              renderIcon
                              key={ group }
                            >
                                <div
                                  block="ProductAttributes"
                                  elem="AttributeBlock"
                                  mods={ { isSummaryFirstAttributeBlock } }
                                >
                                    { this.renderAttributes(attributes) }
                                </div>
                            </ExpandableContent>
                        );
                    }

                    return (
                        <div
                          block="ProductAttributes"
                          elem="AttributeBlock"
                          mods={ { isSummaryFirstAttributeBlock } }
                          key={ group }
                        >
                            <div block="ProductAttributes" elem="AttributeHeader">
                                <h2 block="ProductAttributes" elem="Group">
                                    { name }
                                </h2>
                                { hasPrice && (
                                    <div block="ProductAttributes" elem="AttributeHeaderPrice">
                                        { price }
                                    </div>
                                ) }
                            </div>
                            { this.renderAttributes(attributes) }
                        </div>
                    );
                }
            )
        );
    }

    renderOptions() {
        const { isDealersOfferStep, optionItems } = this.props;

        if (!Object.keys(optionItems).length) {
            return null;
        }

        if (isDealersOfferStep) {
            return (
                <ExpandableContent
                  heading={ this.renderOptionsHeader() }
                  mix={ { block: 'ProductAttributes', elem: 'AttributeExpandableBlock' } }
                  isArrow
                  isContentExpanded
                  renderIcon
                >
                    <div block="ProductAttributes" elem="AttributeBlock">
                        { this.renderOptionItems() }
                    </div>
                </ExpandableContent>
            );
        }

        return (
            <div block="ProductAttributes" elem="AttributeBlock">
                { this.renderOptionsHeader() }
                { this.renderOptionItems() }
            </div>
        );
    }

    renderInsurance() {
        const {
            insurance: {
                product: {
                    sku
                } = {}
            } = {},
            isDealersOfferStep
        } = this.props;

        if (!sku) {
            return null;
        }

        if (isDealersOfferStep) {
            return (
                <ExpandableContent
                  heading={ this.renderInsuranceHeader() }
                  mix={ { block: 'ProductAttributes', elem: 'AttributeExpandableBlock' } }
                  isArrow
                  isContentExpanded
                  renderIcon
                >
                    <div block="ProductAttributes" elem="AttributeBlock">
                        { this.renderInsuranceItem() }
                    </div>
                </ExpandableContent>
            );
        }

        return (
            <div block="ProductAttributes" elem="AttributeBlock">
                { this.renderInsuranceHeader() }
                { this.renderInsuranceItem() }
            </div>
        );
    }

    renderOptionsHeader() {
        const {
            optionItems, optionTotal, isDealersOfferStep, optionTotalCurrency, insurance: { value }
        } = this.props;

        const total = value ? optionTotal - value : optionTotal;
        const numberOfOptions = isDealersOfferStep ? '' : ` (${Object.keys(optionItems).length})`;

        return (
            <div block="ProductAttributes" elem="AttributeHeader">
                <h2 block="ProductAttributes" elem="Group">
                    { `${__('Options')}${numberOfOptions}` }
                </h2>
                { !isDealersOfferStep && (
                    <div block="ProductAttributes" elem="AttributeHeaderPrice">
                        { formatPrice(total, optionTotalCurrency) }
                    </div>
                ) }
            </div>
        );
    }

    renderInsuranceHeader() {
        const {
            insurance: {
                value
            }, isDealersOfferStep, optionTotalCurrency
        } = this.props;

        return (
            <div block="ProductAttributes" elem="AttributeHeader">
                <h2 block="ProductAttributes" elem="Group">
                    { __('Insurance') }
                </h2>
                { !isDealersOfferStep && (
                    <div block="ProductAttributes" elem="AttributeHeaderPrice">
                        { formatPrice(value, optionTotalCurrency) }
                    </div>
                ) }
            </div>
        );
    }

    renderInsuranceItem() {
        const {
            insurance: {
                product: {
                    sku, name
                },
                value
            },
            optionTotalCurrency: currency = 'QAR'
        } = this.props;

        return (
            <dl block="ProductAttributes" elem="Attributes">
                <Fragment key={ sku }>
                    <dt block="ProductAttributes" elem="AttributeLabel">
                        { name }
                    </dt>
                    <dd block="ProductAttributes" elem="ValueLabel">
                        { `+${formatPrice(Math.round(value), currency)}` }
                    </dd>
                </Fragment>
            </dl>
        );
    }

    renderOptionItems() {
        const { optionItems } = this.props;

        const items = Object.keys(optionItems).map(
            (key) => {
                const {
                    name,
                    type_id,
                    price_range: {
                        maximum_price: {
                            final_price: priceRangePrice
                        }
                    },
                    productPrice: {
                        price: {
                            finalPrice: productPriceFinalPrice = 0
                        } = {}
                    } = {}
                } = optionItems[key] || {};

                const { value, currency } = type_id === 'dynamic' ? productPriceFinalPrice : priceRangePrice;

                return (
                    <Fragment key={ key }>
                        <dt block="ProductAttributes" elem="AttributeLabel">
                            { name }
                        </dt>
                        <dd block="ProductAttributes" elem="ValueLabel">
                            { `+${formatPrice(value, currency)}` }
                        </dd>
                    </Fragment>
                );
            }
        );

        return (
            <dl block="ProductAttributes" elem="Attributes">
                { items }
            </dl>
        );
    }

    renderTotals() {
        const
            {
                price: {
                    value,
                    currency
                },
                optionTotal
            } = this.props;

        const totalValue = value + optionTotal;

        return (
            <div block="ProductAttributes" elem="ProductTotalAmount">
                <div block="ProductTotalAmount" elem="Text">
                    { __('Total Amount') }
                </div>
                <div block="ProductTotalAmount" elem="ValueContainer">
                    <div block="ProductTotalAmount" elem="Value">
                        <span>{ formatPrice(totalValue, currency) }</span>
                    </div>
                    <ProductMonthlyPrice
                      block="ProductTotalAmount"
                      elem="SecondaryValue"
                      price={ totalValue }
                      currency={ currency }
                      isInfoButton={ false }
                      id="ProdAttr"
                    />
                </div>

            </div>
        );
    }

    /**
     * Added to render head part in SummaryStep
     */
    renderSummaryHeader() {
        return (
            <div block="ProductAttributes" elem="SummaryHeader">
                <div block="ProductAttributes" elem="VehicleIcon" />
                <div block="ProductAttributes" elem="ThankYou">
                    { __('Thank you.') }
                    <span>
                        { __('Your quotation') }
                    </span>
                    <span>
                        { __('has been completed.') }
                    </span>
                </div>
                <div block="ProductAttributes" elem="Note">
                    { __('Save your quotation to reserve your vehicle. Your Hyundai vehicle purchase journey can be continued online or in our showroom.') }
                </div>
            </div>
        );
    }

    /**
     * Added to remove repeating code in renderContent
     */
    renderContentInsideAttributes() {
        const { isSummaryStep, isDealersOfferStep } = this.props;

        return (
            <>
                { isSummaryStep && this.renderSummaryHeader() }
                { this.renderGroups() }
                { this.renderOptions() }
                { this.renderInsurance() }
                { !isSummaryStep && !isDealersOfferStep && this.renderTotals() }
            </>
        );
    }

    /**
     * Overridden to use isContentExpandable prop
     */
    renderContent() {
        const { areDetailsLoaded, isContentExpandable } = this.props;
        const heading = areDetailsLoaded ? __('Details') : '';

        if (!isContentExpandable) {
            return (
                <div block="ProductAttributes" elem="Content">
                    { this.renderContentInsideAttributes() }
                </div>
            );
        }

        return (
            <ExpandableContent
                // show placeholder if the details are not loaded
              heading={ heading }
              mix={ { block: 'ProductAttributes', elem: 'Content' } }
            >
                { this.renderContentInsideAttributes() }
            </ExpandableContent>
        );
    }
}

export default ProductAttributesComponent;
