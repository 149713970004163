import {
    getInitialState as sourceGetInitialState,
    MyAccountReducer as sourceMyAccountReducer
} from 'SourceStore/MyAccount/MyAccount.reducer';

import { UPDATE_CUSTOMER_EXISTS } from './MyAccount.action';

/** @namespace Scandipwa/Store/MyAccount/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    isExistingCustomer: false
});

/** @namespace Scandipwa/Store/MyAccount/Reducer/MyAccountReducer */
export const MyAccountReducer = (
    state = getInitialState(),
    action
) => {
    const { isExistingCustomer } = action;

    switch (action.type) {
    case UPDATE_CUSTOMER_EXISTS:
        return {
            ...state,
            isExistingCustomer
        };

    default:
        return sourceMyAccountReducer(state, action);
    }
};

export default MyAccountReducer;
