/* eslint-disable max-len */
import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { MY_VEHICLES_URL } from 'Component/Header/Header.config';
import MyAccountForgotPasswordPopup from 'Component/MyAccountForgotPasswordPopup';
import MyAccountLoginPopup from 'Component/MyAccountLoginPopup';
import {
    BEFORE_ITEMS_TYPE,
    MENU,
    MY_ACCOUNT_FORGOT_PW_POPUP,
    MY_ACCOUNT_LOGIN_POPUP,
    MY_ACCOUNT_WISHLIST,
    MY_VEHICLES,
    SEARCH,
    SERVICE_BOOKING,
    SHARED_WISHLIST,
    SHOWROOM_BOOKING,
    SMS_SENDING_POPUP,
    SWITCH_ITEMS_TYPE,
    TESTDRIVE_BOOKING
} from 'Component/Router/Router.config';
import { ORDER_RESERVATION_URL } from 'Route/OrderReservation';
import { SHOWROOM_BOOKING_URL } from 'Route/ShowroomBooking/ShowroomBooking.config';
import {
    Breadcrumbs,
    CartPage,
    Checkout,
    CmsPage,
    ConfirmAccountPage,
    ContactPage,
    CookiePopup,
    CreateAccountPage,
    DemoNotice,
    Footer,
    ForgotPasswordPage,
    Header,
    HomePage,
    LoginAccountPage,
    MenuPage,
    MyAccount,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    PasswordChangePage,
    ProductComparePage,
    Router as SourceRouter,
    SearchPage,
    SomethingWentWrong,
    StyleGuidePage,
    withStoreRegex
} from 'SourceComponent/Router/Router.component';

export const SmsCodePopup = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/SmsCodePopup'));
export const MyVehicles = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "my-vehicles" */ 'Route/MyVehicles'));
export const OrderReservation = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "order-reservation" */ 'Route/OrderReservation'));
export const ServiceBooking = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "service-booking" */ 'Route/ServiceBooking'));
export const ShowroomBooking = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "showroom-test-drive-booking" */ 'Route/ShowroomBooking'));
export const TestdriveBooking = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "showroom-test-drive-booking" */ 'Route/TestdriveBooking'));

export {
    Breadcrumbs,
    CartPage,
    Checkout,
    CmsPage,
    CookiePopup,
    DemoNotice,
    Header,
    HomePage,
    MyAccount,
    PasswordChangePage,
    SearchPage,
    ConfirmAccountPage,
    MenuPage,
    Footer,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    ContactPage,
    ProductComparePage,
    CreateAccountPage,
    LoginAccountPage,
    ForgotPasswordPage,
    SomethingWentWrong,
    StyleGuidePage,
    withStoreRegex
};

/** @namespace Scandipwa/Component/Router/Component */
export class RouterComponent extends SourceRouter {
    removedRouters = [
        MY_ACCOUNT_WISHLIST,
        SHARED_WISHLIST,
        SEARCH,
        MENU
    ];

    /**
    * Overridden to remove wishlist, shared wishlist and search
    * Added showroom booking page
    */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    [SWITCH_ITEMS_TYPE] = [
        ...this[SWITCH_ITEMS_TYPE].filter(
            ({ name }) => !this.removedRouters.includes(name)
        ),
        {
            component: <Route
              path={ withStoreRegex(SHOWROOM_BOOKING_URL) }
              // eslint-disable-next-line react/jsx-no-bind, @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              render={ (props) => <ShowroomBooking { ...props } /> }
            />,
            position: 90,
            name: SHOWROOM_BOOKING
        },
        {
            component: <Route
              path={ withStoreRegex('/testdrive') }
              // eslint-disable-next-line react/jsx-no-bind, @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              render={ (props) => <TestdriveBooking { ...props } /> }
            />,
            position: 91,
            name: TESTDRIVE_BOOKING
        },
        {
            component: <Route
              path={ withStoreRegex(MY_VEHICLES_URL) }
              // eslint-disable-next-line react/jsx-no-bind, @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              render={ (props) => <MyVehicles { ...props } /> }
            />,
            position: 92,
            name: MY_VEHICLES
        },
        {
            component: <Route
              path={ withStoreRegex('/service') }
            // eslint-disable-next-line react/jsx-no-bind, @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              render={ (props) => <ServiceBooking { ...props } /> }
            />,
            position: 93,
            name: SERVICE_BOOKING
        },
        {
            component: <Route
              path={ withStoreRegex(`${ORDER_RESERVATION_URL}/:orderId/:offerStep?`) }
                // eslint-disable-next-line react/jsx-no-bind, @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              render={ (props) => <OrderReservation { ...props } /> }
            />,
            position: 100,
            name: MY_VEHICLES
        }
    ];

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    [BEFORE_ITEMS_TYPE] = [
        ...this[BEFORE_ITEMS_TYPE],
        {
            component: <SmsCodePopup />,
            position: 40,
            name: SMS_SENDING_POPUP
        },
        {
            component: <MyAccountLoginPopup />,
            position: 41,
            name: MY_ACCOUNT_LOGIN_POPUP
        },
        {
            component: <MyAccountForgotPasswordPopup />,
            position: 42,
            name: MY_ACCOUNT_FORGOT_PW_POPUP
        }
    ];
}

export default RouterComponent;
