import { ORDER_RESERVATION_STEP_NUMBER_MAP, PAYMENT_STEP } from 'Route/OrderReservation';

import { EVENT_GTM_CHECKOUT_OPTION } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';
import { asyncEventDispatch } from '../util/Event';

const componentDidMount = (args, callback, instance) => {
    const { selectedOption } = instance.state;
    const { event } = instance.props;

    asyncEventDispatch(event, EVENT_GTM_CHECKOUT_OPTION, {
        option: selectedOption,
        action: PAYMENT_STEP,
        step: ORDER_RESERVATION_STEP_NUMBER_MAP[PAYMENT_STEP]
    });

    callback(...args);
};

const componentDidUpdate = (args, callback, instance) => {
    const [_, prevState] = args;
    const { selectedOption: prevSelectedOption = '' } = prevState;
    const { selectedOption = '' } = instance.state;
    const { event } = instance.props;

    if (selectedOption !== prevSelectedOption) {
        event(EVENT_GTM_CHECKOUT_OPTION, {
            option: selectedOption,
            action: PAYMENT_STEP,
            step: ORDER_RESERVATION_STEP_NUMBER_MAP[PAYMENT_STEP]
        });
    }

    callback(...args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export default {
    'Scandipwa/Component/PaymentStep/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Scandipwa/Component/PaymentStep/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate
        }
    }
};
