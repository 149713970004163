import {
    FIVE_MINUTES_IN_SECONDS,
    ONE_MONTH_IN_SECONDS,
    QueryDispatcher as SourceQueryDispatcher
} from 'SourceUtil/Request/QueryDispatcher';
import { makeCancelable } from 'Util/Promise';
import { Field, prepareQuery } from 'Util/Query';
import { executeGet, listenForBroadCast } from 'Util/Request';

export {
    ONE_MONTH_IN_SECONDS,
    FIVE_MINUTES_IN_SECONDS
};

/** @namespace Scandipwa/Util/Request/QueryDispatcher */
export class QueryDispatcher extends SourceQueryDispatcher {
    __construct(name, cacheTTL = ONE_MONTH_IN_SECONDS) {
        this.name = name;
        this.cacheTTL = cacheTTL;
        this.promise = null;
        this.promises = null;
    }

    handleData(dispatch, options) {
        const { name, cacheTTL, isSingleRequest = true } = this;

        // * The `isSingleRequest` flag determines whether requests are sent as different GraphQL requests or not
        // TODO: consider refactoring since there may be issue with broadcast listening. I.e. `name` is an array instead of a string
        if (isSingleRequest) {
            super.handleData(dispatch, options);
            return;
        }

        const rawQueries = this.prepareRequest(options, dispatch);

        if (rawQueries.length === 0) {
            return;
        }

        const queries = rawQueries.map(
            (rawQuery) => (rawQuery instanceof Field ? [rawQuery] : rawQuery)
        );

        if (this.promises && this.promises.length > 0) {
            this.promises.forEach((promise) => {
                promise.cancel();
            });
        }

        this.promises = queries.map((query, idx) => makeCancelable(
            new Promise((resolve, reject) => {
                executeGet(prepareQuery(query), `${name}-${idx}`, cacheTTL)
                    .then(
                        /** @namespace Scandipwa/Util/Request/QueryDispatcher/QueryDispatcher/handleData/queries/map/makeCancelable/makeCancelable/executeGet/then/resolve */
                        (data) => resolve(data),
                        /** @namespace Scandipwa/Util/Request/QueryDispatcher/QueryDispatcher/handleData/queries/map/makeCancelable/makeCancelable/executeGet/then/reject/catch */
                        (error) => reject(error)
                    );
            })
        ));

        Promise.all(this.promises.map(({ promise }) => promise)).then(
            /** @namespace Scandipwa/Util/Request/QueryDispatcher/QueryDispatcher/handleData/all/then */
            (data) => this.onSuccess(data, dispatch, options),
            /** @namespace Scandipwa/Util/Request/QueryDispatcher/QueryDispatcher/handleData/all/then/catch */
            (error) => this.onError(error, dispatch, options),
        );

        Promise.all(queries.map((_, idx) => listenForBroadCast(`${name}-${idx}`))).then(
            /** @namespace Scandipwa/Util/Request/QueryDispatcher/QueryDispatcher/handleData/all/then */
            (data) => this.onUpdate(data, dispatch, options),
        );
    }
}

export default QueryDispatcher;
