import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import {
    LinkContainer as SourceLinkContainer,
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/Link/Link.container';

export { mapDispatchToProps };

/** @namespace Scandipwa/Component/Link/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    baseUrl: state.ConfigReducer.base_url || ''
});

/** @namespace Scandipwa/Component/Link/Container */
export class LinkContainer extends SourceLinkContainer {
    static propTypes = {
        ...SourceLinkContainer.propTypes,
        baseUrl: PropTypes.string.isRequired
    };

    containerProps() {
        return {
            isOpenInNewTab: this.getIsFile(), // on the top to be rewritable by custom props
            ...super.containerProps()
        };
    }

    getIsFile() {
        const { to } = this.props;

        return /.+\.pdf$/i.test(to);
    }

    getTo() {
        const { to, baseUrl } = this.props;

        // `to` is a string, but not an absolute link, but a file (i.e. from /media)
        if (typeof to === 'string'
            && !/^https?:\/\//.test(to)
            && this.getIsFile()) {
            return baseUrl + to;
        }

        return super.getTo();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkContainer);
