/** @namespace Scandipwa/Util/MyAccount/getSelectOptions */
export const getSelectOptions = (labelList, valueList = []) => (
    labelList.map((label, index) => {
        const translatedLabel = typeof label === 'function' ? label() : label;
        return {
            id: valueList.length > index ? valueList[index] : translatedLabel,
            value: valueList.length > index ? valueList[index] : translatedLabel,
            label: translatedLabel
        };
    })
);
