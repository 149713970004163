import {
    LEFT,
    RIGHT
} from 'Component/ChevronIcon/ChevronIcon.config';
import isMobile from 'Util/Mobile';

export const ATTRIBUTES_TO_COMPARE = ['displacement', 'max_power', 'max_torque', 'wheel'];

export const NAV_LEFT = LEFT;
export const NAV_RIGHT = RIGHT;

export const COLUMN_MOBILE_SCROLL = 85;
export const COLUMN_DESKTOP_SCROLL = 130;
export const COLUMN_SCROLL = isMobile.any()
    ? COLUMN_MOBILE_SCROLL
    : COLUMN_DESKTOP_SCROLL;

export const SCROLL_EVENT_DELAY = 5;

export const INVALID_ATTRIBUTE_THRESHOLD = 1;
