/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */

import { Overlay as SourceOverlay } from 'SourceComponent/Overlay/Overlay.component';
import { toggleScroll } from 'Util/Browser';

/** @namespace Scandipwa/Component/Overlay/Component */
export class Overlay extends SourceOverlay {
    freezeScroll() {
        const { isMobile } = this.props;

        if (!isMobile) {
            this.YoffsetWhenScrollDisabled = window.pageYOffset || document.body.scrollTop;
            document.body.style.marginTop = `${-this.YoffsetWhenScrollDisabled}px`;
        }

        toggleScroll(false);
    }

    unfreezeScroll() {
        const { isMobile } = this.props;

        if (!isMobile) {
            document.body.style.marginTop = 0;
            window.scrollTo(0, this.YoffsetWhenScrollDisabled);
        }

        toggleScroll(true);
    }
}

export default Overlay;
