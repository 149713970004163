/* eslint-disable react/require-default-props */
import { PureComponent } from 'react';

import Image from 'Component/Image';
import { GalleryIconType } from 'Type/GalleryIcon.type';

import {
    ENGINE_ICON, FUEL_ICON, TRACTION_ICON, TRACTION_IMAGE_PATH
} from './ProductGalleryIcons.config';

import './ProductGalleryIcons.style';

/** @namespace Scandipwa/Component/ProductGalleryIcons/Component */
export class ProductGalleryIconsComponent extends PureComponent {
    static propTypes = {
        iconsToRender: GalleryIconType
    };

    renderMap = {
        [ENGINE_ICON]: this.renderEngineIcon,
        [FUEL_ICON]: this.renderFuelIcon,
        [TRACTION_ICON]: this.renderTractionIcon
    };

    static defaultProps = {
        iconsToRender: [{ type: null, text: '' }]
    };

    /*
    * JAID-107 - Renders engine icon
     */
    renderEngineIcon() {
        return (
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_5750_46250)">
                    <path
                      d="M35.566 9.96007H33.1661C32.9261 9.96007 32.686 10.1401 32.6261 10.38L30.9461
                     15.3601H29.3261V12.0401C29.3261 11.8798 29.3261 11.6666 29.3261 10.9835C29.3261 10.4379
                      28.8888 9.99249 28.3433 9.98313L27 9.96007H26.75H26.4608C25.9608 9.96007 24.5261 9.96007
                       24.1661 9.96007H19.966V7.56009H21.766C22.126 7.56009 22.366 7.32011
                        22.366 6.96011V3.36011C22.366 3.00011 22.126 2.76013
                         21.766 2.76013H10.966C10.606 2.76013 10.366 3.00011 10.366 3.36011V6.96011C10.366 7.32011
                          10.606 7.56009 10.966 7.56009H12.766V9.96007H7.96599C7.78599 9.96007 7.66597
                           10.02 7.54602 10.1401C7.42606 10.2601 7.36602
                            10.38 7.36602 10.56V12.36H3.16604C2.80604 12.36 2.56606 12.6
                             2.56606 12.96V16.56H1.36604V14.7601C1.36604 14.4001 1.12606 14.1601
                              0.766062 14.1601C0.406063 14.1601 0.166016 14.4001 0.166016
                               14.7601V19.5601C0.166016 19.9201 0.405992 20.1601 0.765992 20.1601C1.12599 20.1601
                                1.36597 19.9201 1.36597 19.5601V17.7601H2.56599V22.5601C2.56599 22.9201
                                 2.80597 23.1601 3.16597 23.1601H7.66597L9.88595 26.5201C10.006 26.6402
                                  10.186 26.7601 10.366 26.7601H27.166C27.406 26.7601 27.586 26.6401
                                   27.706 26.4001L29.326 23.1601H31.006L32.626 26.4001C32.746 26.6401
                                    32.926 26.7601 33.166 26.7601H35.566C35.926 26.7601 36.1659 26.5201
                                     36.1659 26.1601V10.5601C36.166 10.2001 35.926 9.96007 35.566 9.96007ZM11.566
                                      3.96009H21.166V6.36006H19.366H13.366H11.566V3.96009ZM14.966
                                       7.56009H17.7661V9.96007H14.966V7.56009ZM3.76602
                                        21.9601V13.5601H7.36602V21.9601H3.76602ZM26.746
                                         24.5601H19.206H11.666L9.56597 22.3817V12.9601V12.1601H13.366L19.366
                                          12.1601L23.566 12.1601H25.2179C25.9481 12.1601 27.006 12.1601
                                           27.366 12.1601V13.6178V16.1601V22.3201L26.746 24.5601ZM29.566
                                            21.9601V16.5601H30.766V21.9601H29.566ZM34.966
                                             25.5601H33.526L31.966 22.5515V19.2569V15.9622L33.586
                                              11.1601H34.966V25.5601Z"
                      fill="black"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_5750_46250">
                        <rect width="36" height="36" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        );
    }

    /*
    * JAID-107 - Renders fuel icon
     */
    renderFuelIcon() {
        return (
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.963 5L7 13.937V31H29V5H15.963Z" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
                <path
                  d="M9.40987 11.4158L7.10986 8.98482L11.0499 5.11182L13.6849 7.51181L12.5509 8.52982L11.0509
                 10.0228H22.5459L26.6139 5.11182"
                  stroke="black"
                  strokeWidth="1.3"
                  strokeMiterlimit="10"
                />
                <path
                  d="M21.7793 19.8699C21.0513 18.4039 18.1413 14.0039 18.1413 14.0039H17.8593C17.8593
                 14.0039 14.9493 18.4039 14.2213 19.8699C14.0314 20.4154 13.9664 20.9965 14.0311 21.5704C14.0958
                  22.1443 14.2886 22.6964 14.5951 23.1859C14.9017 23.6754 15.3142 24.0898 15.8023 24.3986C16.2904
                   24.7074 16.8416 24.9026 17.4153 24.9699L17.8583 24.9989H18.1403L18.5833 24.9699C19.1571 24.9029
                    19.7085 24.7078 20.1968 24.3992C20.6852 24.0905 21.098 23.6761 21.4047 23.1866C21.7115 22.6971
                     21.9044 22.1449 21.9693 21.5709C22.0341 20.9968 21.9691 20.4155 21.7793 19.8699V19.8699Z"
                  stroke="black"
                  strokeWidth="1.3"
                  strokeMiterlimit="10"
                />
            </svg>
        );
    }

    /*
    * JAID-107 - Renders traction image
     */
    renderTractionIcon() {
        return (
            <Image
              src={ TRACTION_IMAGE_PATH }
            />
        );
    }

    renderElement(type, text) {
        if (type && text) {
            return (
                <div key={ type } block="ProductGalleryIcons" elem="Item">
                    <div block="Icon">
                        { this.renderMap?.[type]?.() || '' }
                    </div>
                    <div block="Description">
                        { text }
                    </div>
                </div>
            );
        }

        return null;
    }

    renderItems() {
        const { iconsToRender } = this.props;

        return iconsToRender.map((({ type, text }) => this.renderElement(type, text)));
    }

    render() {
        return (
            <div block="ProductGalleryIcons">
                { this.renderItems() }
            </div>
        );
    }
}

export default ProductGalleryIconsComponent;
