import AddToCart from 'Component/AddToCart';
import Html from 'Component/Html';
import Image from 'Component/Image';
import TextPlaceholder from 'Component/TextPlaceholder';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import ChevronIcon from 'SourceComponent/ChevronIcon';
import {
    ProductCard as SourceProductCard
} from 'SourceComponent/ProductCard/ProductCard.component';

import './ProductCard.override.style';

/** @namespace Scandipwa/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCard {
    /**
     * Overridden to pass the button mix
     */
    renderAddToCartButton(layout = GRID_LAYOUT) {
        const {
            addToCart,
            inStock,
            quantity,
            getActiveProduct,
            device
        } = this.props;

        return (
            <AddToCart
              mix={ [
                  { block: this.className, elem: 'AddToCart' },
                  { block: 'Button', mods: { isHollow: !device.isMobile } }
              ] }
              addToCart={ addToCart }
              isDisabled={ !inStock }
              isIconEnabled={ false }
              layout={ layout }
              quantity={ quantity }
              getActiveProduct={ getActiveProduct }
            />
        );
    }

    renderCardLinkWrapper(children, mix = {}) {
        return super.renderCardLinkWrapper(children, mix);
    }

    /**
     * Overridden to delete reviews
     */
    renderReviews() {
        return null;
    }

    /**
     * Overridden to change text in the button and add isHollow property
     * JAID-201 - Removed notification
     */
    renderAddToCart() {
        const {
            layout,
            inStock,
            device,
            product
        } = this.props;
        const isPlaceholder = Object.keys(product).length === 0;

        if (inStock && this.requiresConfiguration()) {
            return (
                <button
                  block="AddToCart"
                  mods={ { layout } }
                  mix={ { block: 'Button', mods: { isHollow: !device.isMobile } } }
                  disabled={ isPlaceholder }
                >
                    { __('Select') }
                    <ChevronIcon />
                </button>
            );
        }

        if (inStock && !this.requiresConfiguration()) {
            return (
                <button
                  block="AddToCart"
                  mods={ { layout } }
                  mix={ { block: 'Button' } }
                  disabled={ isPlaceholder }
                >
                    { __('Select') }
                    <ChevronIcon />
                </button>
            );
        }

        if (!inStock) {
            return (
                <button
                  block="AddToCart"
                  mods={ { layout } }
                  mix={ { block: 'Button', mods: { isHollow: !device.isMobile } } }
                  disabled={ isPlaceholder }
                >
                    { __('View') }
                    <ChevronIcon />
                </button>
            );
        }

        return this.renderAddToCartButton(layout);
    }

    /**
     * Overridden to render short description
     */

    renderShortDescription() {
        const { product: { short_description: { html } = {} }, product } = this.props;
        const isPlaceholder = Object.keys(product).length === 0;

        if (isPlaceholder) {
            return (
                <div block="ProductCard" elem="ShortDescription">
                    <TextPlaceholder content="" length="long" />
                </div>
            );
        }

        if (!html) {
            return null;
        }

        return (
            <div block="ProductCard" elem="ShortDescription">
                <Html content={ html } />
            </div>
        );
    }

    /**
     * Overridden to add hasAdditionalLogoOnLoader prop
     */
    renderPicture(mix = {}) {
        const { product: { id, name }, thumbnail } = this.props;

        this.sharedComponent = (
            <Image
              imageRef={ this.imageRef }
              hasAdditionalLogoOnLoader
              src={ thumbnail }
              alt={ name }
              ratio="custom"
              mix={ { block: 'ProductCard', elem: 'Picture', mix } }
              isPlaceholder={ !id }
            />
        );

        return (
            <>
                { this.sharedComponent }
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ thumbnail }
                />
            </>
        );
    }

    /**
     * Overridden render add "add to cart button", change order for rendering brand&name and delete visibleOnHover
     */
    renderCardContent() {
        const { renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        { this.renderReviews() }
                        <div block="ProductCard" elem="Info">
                            { this.renderCustomName() }
                            { this.renderShortDescription() }
                        </div>
                        { this.renderPrice() }
                        { this.renderAddToCart() }
                    </div>
                </>
            ))
        );
    }

    /**
     * Added to render h3 instead of div
     */
    renderCustomName() {
        const { product: { name } } = this.props;

        return (
            <h3 block={ this.className } elem="Name">
                <TextPlaceholder content={ name } length="medium" />
            </h3>
        );
    }

    /**
    * Overridden to add wrapper to the placeholder for proper styling
     */
    renderPrice() {
        const { product } = this.props;
        const isPlaceholder = Object.keys(product).length === 0;

        if (isPlaceholder) {
            return (
                <div block="ProductCard" elem="PriceWrapper">
                    { this.renderTextPlaceholder() }
                </div>
            );
        }

        return super.renderPrice();
    }
}

export default ProductCardComponent;
