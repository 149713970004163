import {
    CartReducer as sourceCartReducer,
    getInitialState as sourceGetInitialState
} from 'SourceStore/Cart/Cart.reducer';

import { UPDATE_IS_ADDING } from './Cart.action';

export * from 'SourceStore/Cart/Cart.reducer';

/** @namespace Scandipwa/Store/Cart/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    isAdding: false
});

/** @namespace Scandipwa/Store/Cart/Reducer/CartReducer */
export const CartReducer = (state = getInitialState(), actions) => {
    const { type, isAdding } = actions;
    switch (type) {
    case UPDATE_IS_ADDING:
        return {
            ...state,
            isAdding
        };
    default:
        return {
            ...state,
            ...sourceCartReducer(state, actions)
        };
    }
};

export default CartReducer;
