import {
    ProductBundleOptions as SourceProductBundleOptions
} from 'SourceComponent/ProductBundleOptions/ProductBundleOptions.component';
import { ItemOptionsType } from 'Type/ProductList.type';

/** @namespace Scandipwa/Component/ProductBundleOptions/Component */
export class ProductBundleOptionsComponent extends SourceProductBundleOptions {
    static propTypes = {
        ...this.propTypes,
        options: ItemOptionsType
    };
}

export default ProductBundleOptionsComponent;
