/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { VIDEO_TYPE } from '@scandipwa/scandipwa/src/component/ProductGallery/ProductGallery.config';
import { withRouter } from 'react-router';

import Image from 'Component/Image';
import Slider from 'Component/Slider';
import VideoPopup from 'Component/VideoPopup';
import {
    ProductGallery as SourceProductGallery
} from 'SourceComponent/ProductGallery/ProductGallery.component';

import './ProductGallery.override.style';

/** @namespace Scandipwa/Component/ProductGallery/Component */
export class ProductGalleryComponent extends SourceProductGallery {
    /**
     * Overridden to remove additional pictures carousel
     */
    renderAdditionalPictures() {
        return null;
    }

    /**
     * Overridden to use large image for interior and base images
     */
    renderImage(mediaData, index) {
        const {
            isMobile,
            isImageZoomPopupActive,
            showLoader,
            productName,
            isOnlyHQSource
        } = this.props;

        const { label } = mediaData;
        const modifiedMediaData = label ? mediaData : { ...mediaData, label: productName };

        if (!isMobile) {
            const {
                base: { url: baseSrc } = {},
                large: { url: largeSrc } = {}
            } = mediaData;

            const style = isImageZoomPopupActive ? { height: 'auto' } : {};
            const src = isImageZoomPopupActive || isOnlyHQSource ? largeSrc : baseSrc;

            return (
                <Image
                  key={ index }
                  src={ src }
                  ratio="custom"
                  mix={ {
                      block: 'ProductGallery',
                      elem: 'SliderImage',
                      mods: { isPlaceholder: !src }
                  } }
                  isPlaceholder={ !src }
                  style={ style }
                  showIsLoading={ showLoader }
                  alt={ productName }
                />
            );
        }

        return super.renderImage(modifiedMediaData, index);
    }

    /**
     * Overridden to remove zoom on interior view
     */
    handleSliderClick() {
        const {
            handleImageZoomPopupActiveChange,
            gallery,
            activeImage,
            isZoomDisabled
        } = this.props;

        const { media_type } = gallery[activeImage];

        if (media_type === VIDEO_TYPE || isZoomDisabled) {
            return;
        }

        handleImageZoomPopupActiveChange(true);
    }

    /**
     * Overridden to add isInterior prop to Slider
     * Renamed method because some plugin uses native one
     */
    renderNewSlider() {
        const {
            gallery,
            activeImage,
            isZoomEnabled,
            onActiveImageChange,
            isImageZoomPopupActive,
            sliderRef,
            isMobile,
            isInterior,
            isZoomDisabled
        } = this.props;

        const mods = {
            isImageZoomPopupActive,
            isZoomInCursor: !isImageZoomPopupActive && !isZoomDisabled
        };

        const isMoreThanOnePhoto = gallery.length > 1;
        const showCrumbs = isMobile && isMoreThanOnePhoto && !isInterior;

        const sliderKey = isInterior ? 'slider-interior' : 'slider-exterior';

        return (
            <div
              ref={ this.imageRef }
              block="ProductGallery"
              elem="SliderWrapper"
            >
                <meta itemProp="image" content={ this.getImageUrl() } />
                <Slider
                  key={ sliderKey }
                  sliderRef={ sliderRef }
                  mix={ { block: 'ProductGallery', elem: 'Slider', mods } }
                  showCrumbs={ showCrumbs }
                  showArrows={ isMoreThanOnePhoto }
                  activeImage={ activeImage }
                  onActiveImageChange={ onActiveImageChange }
                  isInteractionDisabled={ isZoomEnabled }
                  onClick={ this.handleSliderClick }
                  sliderHeight={ isImageZoomPopupActive ? '100%' : 0 }
                  isHeightTransitionDisabledOnMount
                  isInterior={ isInterior }
                  disableSingleClick
                >
                    { gallery.map(this.renderSlide) }
                </Slider>
            </div>
        );
    }

    /**
     * Overridden to add isInterior class
     */
    render() {
        const { isInterior, isTransitionOff } = this.props;

        return (
            <div block="ProductGallery" ref={ this.galleryRef } mods={ { isInterior, isTransitionOff } }>
                { this.renderNewSlider() }
                { this.renderAdditionalPictures() }
                <VideoPopup />
            </div>
        );
    }
}

export default withRouter(ProductGalleryComponent);
