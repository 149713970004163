import PropTypes from 'prop-types';

export const GalleryIconType = PropTypes.arrayOf(
    PropTypes.shape({
        text: PropTypes.string,
        type: PropTypes.string
    }).isRequired
);

export default GalleryIconType;
