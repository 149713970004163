import {
    EVENT_GTM_PRODUCT_CLICK
} from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

/** RelatedProductCard */
const toggleRelatedProduct = (args, callback, instance) => {
    const {
        event,
        product,
        position
    } = instance.props;

    const productToPass = { ...product, position };

    event(EVENT_GTM_PRODUCT_CLICK, productToPass);

    callback(...args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export const containerProps = (args, callback, instance) => {
    const {
        event,
        position = 0
    } = instance.props;

    return {
        ...callback(...args),
        position,
        event,
        test: 'test'
    };
};

export default {
    'Scandipwa/Component/RelatedProductCard/Container': {
        'member-function': {
            containerProps,
            toggleRelatedProduct
        }
    },
    'Scandipwa/Component/RelatedProductCard/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
