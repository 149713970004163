import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './Swatch.style';

/*
*   This component is made to render swatch block with Header and Attribute Value
*/

/** @namespace Scandipwa/Component/Swatch/Component */
export class SwatchComponent extends PureComponent {
    static propTypes = {
        label: PropTypes.string.isRequired,
        selectedValue: PropTypes.string.isRequired,
        children: PropTypes.element.isRequired
    };

    render() {
        const {
            label,
            selectedValue,
            children
        } = this.props;

        return (
            <div block="Swatch">
                <div block="Swatch" elem="Header">
                    { label && <span block="Swatch" elem="Label">{ label }</span> }
                    <span block="Swatch" elem="Value">
                        { selectedValue }
                    </span>
                </div>
                { children }
            </div>
        );
    }
}

export default SwatchComponent;
