/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction, react/prop-types */

import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { MY_VEHICLES_URL } from 'Component/Header/Header.config';
import { SF_ORDER_STATUS_MAP } from 'Component/MyVehiclesQuotationPod/MyVehiclesQuotationPod.config';
import {
    PAYMENT_OPTION_BANK,
    PAYMENT_OPTION_CASH,
    PAYMENT_OPTION_CASH_ONLINE_PAYMENT,
    PAYMENT_OPTION_IHF
} from 'Component/PaymentStep/PaymentStep.config';
import OrderQuery from 'Query/Order.query';
import { toggleBreadcrumbs, updateBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { setFooterVisible } from 'Store/Footer/Footer.action';
import { updateMeta } from 'Store/Meta/Meta.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { updateStatus } from 'Store/Reservation/Reservation.action';
import ReservationReducer from 'Store/Reservation/Reservation.reducer';
import { withReducers } from 'Util/DynamicReducer';
import { uploadFilesUtil } from 'Util/File';
import { fetchMutation } from 'Util/Request';
import { appendWithStoreCode, getQueryParam } from 'Util/Url';

import OrderReservationComponent from './OrderReservation.component';
import {
    BANK_STAT,
    CAN_ACCESS_DIRECTLY_STEPS,
    CREDIT_DOC,
    DEALERS_OFFER_STEP,
    DELIVERY_STEP,
    ID_COPY,
    ORDER_RESERVATION_STEP_NUMBER_MAP,
    ORDER_RESERVATION_URL,
    PAYMENT_STEP,
    SALARY_DOC,
    SUCCESS_STEP,
    SUMMARY_STEP
} from './OrderReservation.config';

export const ReservationDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Reservation/Reservation.dispatcher'
);

export const DukhanBankDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/DukhanBank/DukhanBank.dispatcher'
);

/** @namespace Scandipwa/Route/OrderReservation/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    reservationData: state.ReservationReducer,
    areBreadcrumbsVisible: state.BreadcrumbsReducer.areBreadcrumbsVisible,
    isMobile: state.ConfigReducer.device.isMobile,
    isIos: state.ConfigReducer.device.ios,
    baseLinkUrl: state.ConfigReducer.base_link_url
});

/** @namespace Scandipwa/Route/OrderReservation/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestOrderById: (options) => ReservationDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getOrderById(dispatch, options)
    ),
    getIHFAvailability: () => ReservationDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getIhfAvailability(dispatch)
    ),
    updateBreadcrumbs: (payload) => dispatch(updateBreadcrumbs(payload)),
    toggleBreadcrumbs: (payload) => dispatch(toggleBreadcrumbs(payload)),
    updateFooterVisibility: (status) => dispatch(setFooterVisible(status)),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    executeDukhanPayment: (options) => DukhanBankDispatcher.then(
        ({ default: dispatcher }) => dispatcher.execute(dispatch, options)
    ),
    updateDeliveryData: (options) => ReservationDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateDeliveryData(dispatch, options)
    ),
    updatePaymentData: (options) => ReservationDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updatePaymentData(dispatch, options)
    ),
    updateStatus: (status) => dispatch(updateStatus(status)),
    showError: (message) => dispatch(showNotification('error', message))
});

/** @namespace Scandipwa/Route/OrderReservation/Container */
export class OrderReservationContainer extends PureComponent {
    handleFail = this.handleFail.bind(this);

    containerFunction = {
        saveSummary: this.saveSummary.bind(this),
        saveDeliveryOptions: this.saveDeliveryOptions.bind(this),
        savePayment: this.savePayment.bind(this),
        saveTradeIn: this.saveTradeIn.bind(this),
        handleDealersOfferStep: this.handleDealersOfferStep.bind(this),
        handleStepBack: this.handleStepBack.bind(this),
        updateMetaTitle: this.updateMetaTitle.bind(this),
        changeStep: this.changeStep.bind(this)
    };

    componentDidMount() {
        this.fetchReservationData();
        this.updateOrderBreadcrumbs();
        this.updateFooter(false);
    }

    componentDidUpdate(prevProps) {
        const {
            areBreadcrumbsVisible,
            toggleBreadcrumbs,
            baseLinkUrl
        } = this.props;
        const { step } = this.state;
        const { baseLinkUrl: prevBaseLinkUrl } = prevProps;

        if (!areBreadcrumbsVisible && step !== SUCCESS_STEP) {
            this.updateOrderBreadcrumbs();
        }

        if (step === SUCCESS_STEP) {
            toggleBreadcrumbs(false);
            this.updateFooter(true);
        }

        if (baseLinkUrl !== prevBaseLinkUrl) {
            this.changeStep(DEALERS_OFFER_STEP); // Change url after switching store code
        }
    }

    componentWillUnmount() {
        this.updateFooter(true);
    }

    __construct(props) {
        const {
            match: {
                params: {
                    offerStep = DEALERS_OFFER_STEP
                } = {}
            } = {}
        } = props;

        // Init state according to props in order to get rid of contents jumps.
        // E.g. in case the success step handling is correct
        this.state = {
            step: CAN_ACCESS_DIRECTLY_STEPS.includes(offerStep) ? offerStep : DEALERS_OFFER_STEP
        };
    }

    updateMetaTitle(title) {
        const { updateMeta } = this.props;

        updateMeta({
            title
        });
    }

    fetchReservationData() {
        const {
            match: {
                params: {
                    orderId,
                    offerStep
                } = {}
            } = {},
            location,
            requestOrderById,
            getIHFAvailability
        } = this.props;
        const paymentId = getQueryParam('paymentId', location);
        const options = {
            orderId,
            onFail: this.handleFail,
            onIdFail: this.changeStep.bind(this, DEALERS_OFFER_STEP),
            onSuccess: this.changeStep.bind(this, SUCCESS_STEP, false)
        };

        if (offerStep === SUCCESS_STEP && paymentId) {
            options.paymentId = Number(paymentId);
        } else {
            this.syncUrlWithStep();
        }

        requestOrderById(options);
        getIHFAvailability();
    }

    /**
     * Sync URL param with step, if step is invalid defaults to dealers offer step
     */
    syncUrlWithStep() {
        const {
            match: {
                params: {
                    offerStep = ''
                } = {}
            } = {}
        } = this.props;

        this.changeStep(
            Object.keys(ORDER_RESERVATION_STEP_NUMBER_MAP).includes(offerStep)
                ? offerStep
                : DEALERS_OFFER_STEP
        );
    }

    handleFail() {
        const { history } = this.props;

        history.push(appendWithStoreCode(MY_VEHICLES_URL));
    }

    updateFooter(status) {
        const { updateFooterVisibility } = this.props;

        updateFooterVisibility(status);
    }

    containerProps() {
        const { step } = this.state;
        const {
            match: {
                params: {
                    orderId
                } = {}
            } = {},
            isMobile,
            isIos,
            reservationData: {
                isOrderLoading, order, baseOrderInfo, isIhfAvailable: isIhfAvailableSAP
            }
        } = this.props;

        return {
            orderId,
            isOrderLoading,
            step,
            isMobile,
            order,
            baseOrderInfo,
            isIos,
            isIhfAvailableSAP
        };
    }

    updateOrderBreadcrumbs() {
        const { updateBreadcrumbs, toggleBreadcrumbs } = this.props;

        updateBreadcrumbs([{ name: '', url: '' }, {
            name: __('Back to My Vehicles'),
            url: appendWithStoreCode(MY_VEHICLES_URL)
        }]);
        toggleBreadcrumbs(true);
    }

    async saveSummary() {
        const {
            reservationData: {
                baseOrderInfo: {
                    id,
                    status
                },
                reservationPaymentData: {
                    processed
                },
                paymentData: {
                    cash_type = ''
                }
            },
            executeDukhanPayment
        } = this.props;

        // Skipping payment step in case the order is
        // already reserved, and we don't need to pay anymore
        if (
            status === SF_ORDER_STATUS_MAP.reserved
            && cash_type !== PAYMENT_OPTION_CASH_ONLINE_PAYMENT
        ) {
            this.changeStep(SUCCESS_STEP);

            return;
        }

        const modifiedOrderId = cash_type === PAYMENT_OPTION_CASH_ONLINE_PAYMENT ? `${id}-1` : id;

        executeDukhanPayment({
            orderID: modifiedOrderId,
            isPaymentProcessed: processed,
            status
        });
    }

    saveDeliveryOptions(deliveryData) {
        const { updateDeliveryData, reservationData: { baseOrderInfo: { id } } } = this.props;
        const options = {
            order_id: id,
            ...deliveryData
        };

        updateDeliveryData(options);
        this.changeStep(SUMMARY_STEP);
    }

    async savePayment(_formRef, fields = []) {
        const {
            reservationData: {
                baseOrderInfo: { id: orderId },
                paymentData: {
                    additional_data: additionalPaymentData
                } = {}
            },
            updatePaymentData,
            updateStatus,
            showError
        } = this.props;

        const cashPaymentOption = fields.find(
            (field) => field.name === 'cashPaymentOption' && field.value
        )?.value;
        const bankPaymentOption = fields.find((field) => field.name === 'bankPaymentOption')?.value;

        if (cashPaymentOption) {
            const data = {
                order_id: orderId,
                method: PAYMENT_OPTION_CASH,
                cash_type: cashPaymentOption
            };

            updatePaymentData(data);
            fetchMutation(OrderQuery.getUpdatePaymentMutation(data));
        } else if (bankPaymentOption) {
            const downPaymentField = fields.find((field) => field.name === 'down_payment_value');
            const downPaymentValue = downPaymentField?.value.match(/\d+(\.\d+$)?/g)?.join('') || 0;

            const data = {
                order_id: orderId,
                method: PAYMENT_OPTION_BANK,
                bank_code: bankPaymentOption
            };

            updatePaymentData({
                ...data,
                down_payment: downPaymentField?.value,
                down_payment_percent: fields.find((field) => field.name === 'down_payment_percent')?.value
            });
            fetchMutation(OrderQuery.getUpdatePaymentMutation({
                ...data,
                down_payment: downPaymentValue
            }));
        } else {
            const otherFields = fields.reduce((acc, { type, name, value }) => {
                if (type !== 'file') {
                    acc[name] = value;
                }

                return acc;
            }, {});

            updateStatus(true);

            const newFileFields = await uploadFilesUtil({
                fields,
                onComplete: updateStatus.bind(null, false),
                onError: showError.bind(null, __('Files upload has been failed. Trying again in 2 seconds...'))
            });

            const fileFields = {
                [ID_COPY]: additionalPaymentData?.[ID_COPY],
                [SALARY_DOC]: additionalPaymentData?.[SALARY_DOC],
                [BANK_STAT]: additionalPaymentData?.[BANK_STAT],
                [CREDIT_DOC]: additionalPaymentData?.[CREDIT_DOC],
                ...newFileFields
            };

            const data = {
                order_id: orderId,
                method: PAYMENT_OPTION_IHF
            };
            const additional_data = {
                ...otherFields,
                ...fileFields
            };

            updatePaymentData({
                ...data,
                additional_data
            });
            fetchMutation(OrderQuery.getUpdatePaymentMutation({
                ...data,
                additional_data: JSON.stringify(additional_data)
            }));
        }

        this.changeStep(SUMMARY_STEP);
    }

    async saveTradeIn() {
        this.changeStep(DELIVERY_STEP);
    }

    async handleDealersOfferStep() {
        this.changeStep(PAYMENT_STEP);
    }

    handleStepBack(prevStep) {
        this.changeStep(prevStep);
    }

    changeStep(newStep, isWithRedirect = true) {
        const { step } = this.state;
        const {
            history,
            match: {
                params: {
                    orderId
                } = {}
            } = {}
        } = this.props;

        if (step !== newStep) {
            this.setState({ step: newStep });
        }

        if (isWithRedirect) {
            history.push(appendWithStoreCode(`${ORDER_RESERVATION_URL}/${orderId}/${newStep}`));
        }
    }

    render() {
        return <OrderReservationComponent { ...this.containerFunction } { ...this.containerProps() } />;
    }
}

export default withReducers({
    ReservationReducer
})(connect(mapStateToProps, mapDispatchToProps)(OrderReservationContainer));
