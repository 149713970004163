import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import {
    MyAccountSignIn as SourceMyAccountSignIn
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.component';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

/** @namespace Scandipwa/Component/MyAccountSignIn/Component */
export class MyAccountSignInComponent extends SourceMyAccountSignIn {
    /**
     * Overridden to change labels and placeholder texts
     */
    renderSignInForm() {
        const {
            isOTPSignIn,
            isCheckout,
            is_sms_enabled
        } = this.props;

        if (isOTPSignIn && !isCheckout && is_sms_enabled) {
            return this.renderOTPSignInForm();
        }

        return this.renderRegularSignInForm();
    }

    // Renders sign-in form with email and passowrd
    renderRegularSignInForm() {
        const {
            onSignInSuccess,
            onFormError,
            emailValue,
            isCheckout
        } = this.props;

        return (
            <Form
              key="sign-in"
              onSubmit={ onSignInSuccess }
              onError={ onFormError }
            >
                <Field
                  label={ __('ID (Email)') }
                  type={ FIELD_TYPE.email }
                  attr={ {
                      id: 'email',
                      name: 'email',
                      placeholder: __('e.g. abc@hyundai.com'),
                      defaultValue: emailValue,
                      autocomplete: isCheckout ? 'off' : 'email'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email
                  } }
                  addRequiredTag
                />
                <Field
                  label={ __('Password') }
                  type={ FIELD_TYPE.password }
                  attr={ {
                      id: 'password',
                      name: 'password',
                      placeholder: __('Enter password'),
                      autocomplete: 'current-password'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true
                  } }
                  addRequiredTag
                  isPasswordToggle
                />
                <div block="MyAccountOverlay" elem="SignInButton">
                    <button block="Button">{ __('Log In') }</button>
                </div>
                <div block="LoginAccount" elem="SaveForgotWrapper">
                    { this.renderSaveId() }
                    { this.renderForgotPassword() }
                </div>
            </Form>
        );
    }

    renderOTPSignInForm() {
        const {
            onOTPSignInSuccess,
            onFormError
        } = this.props;

        return (
            <Form
              key="sign-in"
              onSubmit={ onOTPSignInSuccess }
              onError={ onFormError }
            >
                <Field
                  label={ __('QID (Qatar ID number)') }
                  type={ FIELD_TYPE.text }
                  attr={ {
                      id: 'qid',
                      name: 'qid',
                      placeholder: __('Enter your QID'),
                      inputMode: 'numeric'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE.QID,
                      isRequired: true
                  } }
                  addRequiredTag
                />
                <Field
                  label={ __('Phone number') }
                  type={ FIELD_TYPE.text }
                  attr={ {
                      id: 'phone',
                      name: 'phone',
                      placeholder: __('Enter your phone number'),
                      inputMode: 'numeric'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE.phone,
                      isRequired: true
                  } }
                  addRequiredTag
                />
                <div block="MyAccountOverlay" elem="SignInButton">
                    <button block="Button">{ __('Log In') }</button>
                </div>
                <div block="LoginAccount" elem="SaveForgotWrapper">
                    { this.renderSaveId() }
                </div>
            </Form>
        );
    }

    renderSaveId() {
        return (
            <Field
              label={ __('Save ID') }
              type={ FIELD_TYPE.checkbox }
              attr={ {
                  id: 'save_id',
                  name: 'save_id',
                  defaultValue: 0
              } }
            />
        );
    }

    renderForgotPassword() {
        const { handleForgotPassword } = this.props;

        return (
            <button
              type="button"
              block="Button"
              mods={ { likeLink: true } }
              mix={ { block: 'MyAccountOverlay', elem: 'ForgotPassword' } }
              onClick={ handleForgotPassword }
            >
                    { __('Reset Password') }
            </button>
        );
    }
}

export default MyAccountSignInComponent;
