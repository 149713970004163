export * from 'SourceUtil/Browser/Browser';

/** @namespace Scandipwa/Util/Browser/scrollToElementTop */
export const scrollToElementTop = (elementRef, offset = 0, options) => {
    const bodyTop = document.body.getBoundingClientRect().top;
    const elementTop = elementRef.current.getBoundingClientRect().top;

    // eslint-disable-next-line no-magic-numbers
    const scrollToPosition = ((bodyTop - elementTop) * -1) - offset;
    window.scrollTo({ ...options, top: scrollToPosition });
};

/** @namespace Scandipwa/Util/Browser/toggleFilterOpened */
export const toggleFilterOpened = (state) => document.documentElement.classList.toggle('filterOpened', state);
