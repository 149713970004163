import { addOptionsToAttributes } from 'Util/Product/Product';

import { EVENT_GTM_PRODUCT_DETAIL } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

export const EVENT_EXECUTION_DELAY = 500;

const render = (args, callback, instance) => {
    const {
        event,
        product: {
            attributes,
            categories,
            name,
            sku: parentSku
        }
    } = instance.props;
    const { fired = false, activeProduct } = instance.state;
    const product = activeProduct ?? {};

    if (!(Object.keys(product).length === 0) && !fired) {
        instance.setState({ fired: true });
        const { attributes: variantAttributes } = product;
        const attributesWithOptions = addOptionsToAttributes(attributes, variantAttributes);

        // added timeout to assure that the productDetail event doesn't come before the general one
        setTimeout(() => {
            event(EVENT_GTM_PRODUCT_DETAIL, {
                ...product,
                attributes: attributesWithOptions,
                categories,
                name,
                parentSku
            });
        }, EVENT_EXECUTION_DELAY);
    }

    return callback.apply(instance, args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            render
        }
    },
    'Route/ProductPage/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
