/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { KEY_PRICE } from 'Component/CategoryFilterOverlay/CategoryFilterOverlay.config';

export const _getNewFilterArray = (args, callback, instance) => {
    const { customFiltersValues: { price } } = instance.props;
    const [filterKey, value] = args;

    if (filterKey === KEY_PRICE) {
        return price && value === undefined
            ? []
            : [value];
    }

    return callback.apply(instance, args);
};

export default {
    'Component/CategoryFilterOverlay/Container': {
        'member-function': {
            _getNewFilterArray
        }
    }
};
