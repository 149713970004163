import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/SmsVerification/Query */
export class SmsVerificationQuery {
    getCreationMutation(options) {
        const mutation = new Field('generateSmsOTPCode');
        this._addMutationArguments(mutation, options);

        return mutation;
    }

    _addMutationArguments(mutation, options) {
        return mutation.addArgument('creationInput', 'OTPCreationInput!', options);
    }

    getValidateMutation(options) {
        const mutation = new Field('validateSmsOTPCode');
        this._addValidateMutationArguments(mutation, options);

        return mutation;
    }

    _addValidateMutationArguments(mutation, options) {
        return mutation.addArgument('validationInput', 'OTPValidationInput!', options);
    }
}

export default new SmsVerificationQuery();
