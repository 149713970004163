import { cloneElement } from 'react';

import CheckoutSuccess from 'Component/CheckoutSuccess';
import {
    fetchQuery
} from 'Util/Request';

import { EVENT_GTM_PURCHASE } from '../component/GoogleTagManager/GoogleTagManager.events';
import GtmPurchaseQuery from '../query/GtmPurchase.query';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

const renderDetailsStep = (args, callback, instance) => {
    const {
        event
    } = instance.props;

    const originalElement = callback(...args);
    return cloneElement(
        originalElement,
        { event }
    );
};

const setDetailsStep = (args, callback, instance) => {
    const orderID = args[0];
    const { event } = instance.props;

    const totals = instance._getCheckoutTotals();
    const { items } = totals;


    const query = GtmPurchaseQuery.getGTMPurchase(orderID);
    fetchQuery(query).then(({ purchase }) => {
        event(EVENT_GTM_PURCHASE, { items, ...purchase, orderID });
    });

    return callback(...args);
};

const containerProps = (args, callback, instance) => {
    const {
        event
    } = instance.props;

    return {
        ...callback(...args),
        event
    };
};

export default {
    'Route/Checkout/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Route/Checkout/Container': {
        'member-function': {
            containerProps,
            setDetailsStep
        }
    },
    'Route/Checkout/Component': {
        'member-function': {
            renderDetailsStep
        }
    }
};
