/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    ProductAttributesContainer as SourceProductAttributesContainer
} from 'SourceComponent/ProductAttributes/ProductAttributes.container';
import { getCarAttributesWithValues } from 'Util/Product';

import { COLOR_ATTR, VEHICLE_ATTR } from './ProductAttributes.config';

/** @namespace Scandipwa/Component/ProductAttributes/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    optionsData: state.ProductOptionsReducer
});

/** @namespace Scandipwa/Component/ProductAttributes/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({

});

/** @namespace Scandipwa/Component/ProductAttributes/Container */
export class ProductAttributesContainer extends SourceProductAttributesContainer {
    static propTypes = {
        ...this.propTypes,
        isContentExpandable: PropTypes.bool,
        isSummaryStep: PropTypes.bool,
        isDealersOfferStep: PropTypes.bool,
        optionsData: PropTypes.shape({
            items: PropTypes.object.isRequired,
            categorizedItems: PropTypes.object.isRequired,
            total: PropTypes.number.isRequired
        }).isRequired
    };

    static defaultProps = {
        isContentExpandable: true,
        isSummaryStep: false
    };

    /**
     * Overridden to pass isContentExpandable prop and product price
     */
    containerProps() {
        const {
            isContentExpandable,
            product,
            price,
            isSummaryStep,
            isDealersOfferStep,
            optionsData: {
                items: optionItems, total: optionTotal, currency: optionTotalCurrency, insurance
            },
            optionsDataForOrder,
            insuranceDataForOrder
        } = this.props;

        const containerProps = super.containerProps();
        const { areDetailsLoaded } = containerProps;

        /*
        * Added to use optionsData from another source.
        */
        if (isDealersOfferStep) {
            const { items: optionItems, total: optionTotal } = optionsDataForOrder;

            const { insurance, insurancePrice } = insuranceDataForOrder;
            return {
                ...containerProps,
                isContentExpandable,
                attributesWithValues: areDetailsLoaded ? getCarAttributesWithValues(VEHICLE_ATTR, product) : {},
                colorsWithValues: areDetailsLoaded ? getCarAttributesWithValues(COLOR_ATTR, product) : {},
                price,
                isSummaryStep,
                isDealersOfferStep,
                product,
                optionItems,
                optionTotal,
                optionsDataForOrder,
                insurance: {
                    product: insurance,
                    value: insurancePrice
                }
            };
        }

        return {
            ...containerProps,
            isContentExpandable,
            attributesWithValues: areDetailsLoaded ? getCarAttributesWithValues(VEHICLE_ATTR, product) : {},
            colorsWithValues: areDetailsLoaded ? getCarAttributesWithValues(COLOR_ATTR, product) : {},
            price,
            isSummaryStep,
            isDealersOfferStep,
            product,
            optionItems,
            optionTotal,
            optionTotalCurrency,
            insurance,
            optionsDataForOrder
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductAttributesContainer);
