import { lazy } from 'react';

import RenderWhenVisible from 'Component/RenderWhenVisible';
import {
    HomeSlider,
    NewProducts,
    ProductListWidget,
    RecentlyViewedWidget,
    WidgetFactory as SourceWidgetFactory
} from 'SourceComponent/WidgetFactory/WidgetFactory.component';

import {
    FLEXIBLE_FORMS
} from './WidgetFactory.config';

export const FlexibleFormsWidget = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-recently-viewed" */
    '../../../packages/@scandiweb/flexibleforms/src/component/FlexibleFormsWidget'
));

export {
    ProductListWidget,
    NewProducts,
    HomeSlider,
    RecentlyViewedWidget
};

/** @namespace Scandipwa/Component/WidgetFactory/Component */
export class WidgetFactoryComponent extends SourceWidgetFactory {
    renderMap = {
        ...this.renderMap,
        [FLEXIBLE_FORMS]: {
            component: FlexibleFormsWidget
        }
    };

    renderContent() {
        const {
            type,
            sliderId = null,
            displayType,
            productsCount,
            showPager,
            storeId,
            title,
            formId,
            conditionsEncoded
        } = this.props;
        const {
            component: Widget,
            fallback
        } = this.renderMap[type] || {};

        if (Widget !== undefined) {
            return (
                <RenderWhenVisible fallback={ fallback }>
                    <Widget
                      form_id={ formId }
                      sliderId={ sliderId }
                      displayType={ displayType }
                      productsCount={ productsCount }
                      showPager={ showPager }
                      storeId={ storeId }
                      title={ title }
                      conditionsEncoded={ conditionsEncoded }
                    />
                </RenderWhenVisible>
            );
        }

        return null;
    }
}

export default WidgetFactoryComponent;
