/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PRODUCT_TYPE from 'Component/Product/Product.config';
import {
    ProductPrice as SourceProductPrice
} from 'SourceComponent/ProductPrice/ProductPrice.component';

import './ProductPrice.override.style';

/** @namespace Scandipwa/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPrice {
    // Overridden to get correct minimum price from schema
    getCurrentPriceSchema() {
        const {
            isSchemaRequired,
            priceRange = {}
        } = this.props;

        const {
            minimum_price: {
                final_price: {
                    value: contentPrice = 0
                } = {}
            } = {}
        } = priceRange || {};

        return isSchemaRequired ? { itemProp: 'price', content: contentPrice } : {};
    }

    /**
     * Overridden to returncorrect highPrice
     */
    renderRegularPrice(price) {
        const {
            value,
            valueFormatted
        } = price;

        if (!value || value <= 0 || !valueFormatted) {
            return null;
        }

        return (
            <del block="ProductPrice" elem="HighPrice">{ valueFormatted }</del>
        );
    }

    /**
     * Overridden to change configurable product label
     */
    priceLabelTypeMap = {
        ...this.priceLabelTypeMap,
        [PRODUCT_TYPE.configurable]: __('Starting at')
    };
}

export default ProductPriceComponent;
