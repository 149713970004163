import { Field } from 'Util/Query';

import ProductLabelProviderQuery from '../query/ProductLabelProvider.query';

export const _getSupportiveProductFieldList = (args, method, instance) => {
    const fields = method.apply(instance, args);

    fields.push(new Field('am_label').addFieldList(ProductLabelProviderQuery.getItemFieldList()));

    return fields;
};

export default {
    'Scandipwa/Query/ProductList/Query': {
        'member-function': {
            _getSupportiveProductFieldList
        }
    }
};
