import { connect } from 'react-redux';

import { MyAccountForgotPasswordPopupComponent } from
'Component/MyAccountForgotPasswordPopup/MyAccountForgotPasswordPopup.component';
import {
    ForgotPasswordContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'Route/ForgotPassword/ForgotPassword.container';

export {
    mapDispatchToProps,
    mapStateToProps
};

/** @namespace Scandipwa/Component/MyAccountForgotPasswordPopup/Container */
export class MyAccountForgotPasswordPopupContainer extends ForgotPasswordContainer {
// Overridden to remove Redirect component
    render() {
        return (
        <MyAccountForgotPasswordPopupComponent
          { ...this.containerProps() }
          { ...this.containerFunctions }
        />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountForgotPasswordPopupContainer);
