/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace, @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */

import AccordionTabs from 'Component/AccordionTabs';
import NewsletterSubscription from 'Component/NewsletterSubscription';
import SourceHtml from 'SourceComponent/Html/Html.component';
import { isMobile } from 'Util/Mobile';

/**
 * Html content parser
 * Component converts HTML strings to React components
 * @class Html
 * @namespace Component/Html/Component
 */
export class HtmlComponent extends SourceHtml {
    rules = [
        {
            query: { attribs: [{ class: 'NextStep-Desktop' }] },
            replace: this.replaceDesktopImagesOnMobile
        },
        {
            query: { attribs: [{ class: 'OnlyDesktopImage' }] },
            replace: this.replaceDesktopImagesOnMobile
        },
        ...this.rules,
        {
            query: { attribs: [{ class: 'AccordionWrapper' }] },
            replace: this.replaceAccordion
        },
        {
            query: { attribs: [{ class: 'Footer-NewsletterReplace' }] },
            replace: this.replaceNewsletter
        }
    ];

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    replaceAccordion(data) {
        return <AccordionTabs data={ data } />;
    }

    // using is to not load desktop images if user is in mobile view to save loading times
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    replaceDesktopImagesOnMobile(data) {
        if (isMobile.any()) {
            // using fragment, because null returns the actual element otherwise, html expects something other than null
            // eslint-disable-next-line react/jsx-no-useless-fragment
            return <></>;
        }

        return this.replaceImages(data);
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    replaceNewsletter() {
        return <NewsletterSubscription key="NewsletterSubscription" />;
    }
}

export default HtmlComponent;
