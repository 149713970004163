export * from 'SourceComponent/ProductActions/ProductActions.config';

// Attribute names
export const TRIM = 'trim';
export const MODEL_YEAR = 'model_year';
export const IMAGE = 'image';
export const ENGINE = 'engine';
export const COLOR_INTERIOR = 'color_interior';
export const COLOR_EXTERIOR = 'color_exterior';
export const CAR_MODEL = 'car_model';
export const BROCHURE_PATH = '/media/catalog/product/file/';

// Types of media files to filter
export const EXTERIOR_MEDIA_TYPE = 'exterior';
export const INTERIOR_MEDIA_TYPE = 'interior';
export const BASE_MEDIA_TYPE = 'image';

// This array is needed for getMinimumConfigurableAttrPrice function, need to add all attribute codes here
export const ATTRIBUTE_CODES_ARRAY = [TRIM, ENGINE, COLOR_INTERIOR, COLOR_EXTERIOR];

// Step names
export const TRIM_STEP = 'TRIM_STEP';
export const ENGINE_STEP = 'ENGINE_STEP';
export const COLOR_STEP = 'COLOR_STEP';
export const OPTIONS_STEP = 'OPTIONS_STEP';
export const INSURANCE_STEP = 'INSURANCE_STEP';
export const SUMMARY_STEP = 'SUMMARY_STEP';
export const ADD_TO_CART_STEP = 'ADD_TO_CART_STEP';

export const ATTRIBUTES_CODE_MAP = {
    [TRIM]: { code: TRIM, label: () => __('Trim') },
    [ENGINE]: { code: ENGINE, label: () => __('Engine') },
    [COLOR_EXTERIOR]: {
        code: COLOR_EXTERIOR,
        label: () => __('Exterior'),
        mediaType: EXTERIOR_MEDIA_TYPE
    },
    [COLOR_INTERIOR]: {
        code: COLOR_INTERIOR,
        label: () => __('Interior'),
        mediaType: INTERIOR_MEDIA_TYPE
    }
};

export const STEPS_MAP = {
    [TRIM_STEP]: {
        number: 1,
        attribute_codes: [ATTRIBUTES_CODE_MAP[TRIM]],
        label: () => __('Trim'),
        advance_button_text: () => __('Select Engine'),
        isLoadedInLeftMenu: true
    },
    [ENGINE_STEP]: {
        number: 2,
        attribute_codes: [ATTRIBUTES_CODE_MAP[ENGINE]],
        label: () => __('Engine'),
        advance_button_text: () => __('Select Colors'),
        isLoadedInLeftMenu: true
    },
    [COLOR_STEP]: {
        number: 3,
        attribute_codes: [
            ATTRIBUTES_CODE_MAP[COLOR_EXTERIOR],
            ATTRIBUTES_CODE_MAP[COLOR_INTERIOR]
        ],
        advance_button_text: () => __('Select Options'),
        label: () => __('Colors'),
        isLoadedInLeftMenu: true
    },
    [OPTIONS_STEP]: {
        number: 4,
        attribute_codes: [{ code: '', label: '' }],
        advance_button_text: () => __('Select Insurance'),
        label: () => __('Options'),
        popUp: {
            enabled: true,
            popup_button_text: () => __('View Selection'),
            title: __('Option totals')
        },
        last: false,
        isLoadedInLeftMenu: true
    },
    [INSURANCE_STEP]: {
        number: 5,
        attribute_codes: [{ code: '', label: '' }],
        advance_button_text: () => __('Continue without insurance'),
        advance_button_text_alt: () => __('Continue'),
        label: () => __('Insurance'),
        popUp: {
            enabled: true,
            popup_button_text: () => __('View Selection'),
            title: __('Insurance totals')
        },
        last: false,
        isLoadedInLeftMenu: true
    },
    [SUMMARY_STEP]: {
        number: 6,
        attribute_codes: [{ code: '', label: '' }],
        label: '',
        advance_button_text: () => __('Save'),
        isLoadedInLeftMenu: false,
        last: true
    },
    [ADD_TO_CART_STEP]: {
        number: 7,
        attribute_codes: [{ code: '', label: '' }],
        label: '',
        buttonText: () => __('Add to cart'),
        loadingText: __('Adding...'),
        isLoadedInLeftMenu: false
    }
};

// Order of steps to render accordingly
export const STEPS_ORDER = [TRIM_STEP, ENGINE_STEP, COLOR_STEP, OPTIONS_STEP, INSURANCE_STEP, SUMMARY_STEP];
