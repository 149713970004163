import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    UrlRewritesContainer as SourceUrlRewritesContainer
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';

import UrlRewrites from './UrlRewrites.component';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandipwa/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    // JAID-550 - the `this.requestUrlRewrite()` call was removed from the `__construct()` method
    // * see the src/plugin/route/UrlRewritesContainer.plugin.js

    // * JAID-550 overridden to add the `this.requestUrlRewrite()` call (https://github.com/scandipwa/scandipwa/pull/4892)
    componentDidMount() {
        super.componentDidMount();

        this.requestUrlRewrite();
    }

    render() {
        return (
            <UrlRewrites
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer);
