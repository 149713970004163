import { TYPE_PRODUCT } from 'Route/UrlRewrites/UrlRewrites.config';
import { UrlRewrites as SourceUrlRewritesComponent } from 'SourceRoute/UrlRewrites/UrlRewrites.component';

/** @namespace Scandipwa/Route/UrlRewrites/Component */
export class UrlRewritesComponent extends SourceUrlRewritesComponent {
    renderContent() {
        const { type, props: { productSKU } } = this.props;

        if (type === TYPE_PRODUCT && !productSKU) {
            return this.renderDefaultPage();
        }

        return super.renderContent();
    }
}

export default UrlRewritesComponent;
