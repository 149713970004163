/* eslint-disable max-len */
export const PAYMENT_OPTION_IHF = 'inhousefinancing';
export const PAYMENT_OPTION_BANK = 'bankloan';
export const PAYMENT_OPTION_CASH = 'checkmo';
export const PAYMENT_OPTION_CASH_FULL_CASH = 'full_cash';
export const PAYMENT_OPTION_CASH_ONLINE_PAYMENT = 'online_payment';
export const PAYMENT_OPTION_CASH_BANK_TRANSFER = 'bank_transfer';
export const PAYMENT_OPTION_CASH_CHEQUES = 'cheques';

export const PAYMENT_OPTIONS_MAP = {
    [PAYMENT_OPTION_IHF]: {
        id: PAYMENT_OPTION_IHF,
        label: () => __('Jaidah In-House Financing')
    },
    [PAYMENT_OPTION_BANK]: {
        id: PAYMENT_OPTION_BANK,
        label: () => __('Bank loan')
    },
    [PAYMENT_OPTION_CASH]: {
        id: PAYMENT_OPTION_CASH,
        label: () => __('Cash')
    }
};

// eslint-disable-next-line no-magic-numbers
export const INSTALLMENT_NUMBER_LIST = [6, 12, 18, 24, 30, 36, 42, 48];
export const INSTALLMENT_NUMBER_LIMIT = 36;

export const QATARI_NATIONALITY_CODE = 'QA';

export const ORDER_TOTAL_THRESHOLD = 120000;
export const PAYMENT_PERCENT_THRESHOLD = 20;
export const MAX_PAYMENT_PERCENT = 100;

export const DEFAULT_INTEREST_RATE = 9.15;

export const JOB_MIN_SALARY = 6000;
export const JOB_START_OFFSET_QATARI = 6;
export const JOB_START_OFFSET = 12;

export const FINANCING_TERMS_FORM_ID = 'financing_terms';
export const FINANCING_CUSTOMER_FORM_ID = 'financing_customer';
export const FINANCING_DOCUMENTS_FORM_ID = 'financing_documents';

export const DECLARATION_FEE_VALUE = 'b';

// sum of header, breadcrumbs, and expandable tab's heading heights
export const SCROLL_TO_FORM_TOP_OFFSET = 197;

export const ALL_FORM_IDS = [
    FINANCING_TERMS_FORM_ID,
    FINANCING_CUSTOMER_FORM_ID,
    FINANCING_DOCUMENTS_FORM_ID
];

export const IHF_AVAILABLE_MESSAGE = __('Apply online for Jaidah’s In-House Financing from the comfort of your home. Choose your down payment and your preferred loan terms. Once all documents are uploaded, your application will be submitted for credit pre-approval. Your dedicated Sales Consultant will contact you to discuss the next steps within 1 to 2 working days.');
export const IHF_NOT_AVAILABLE_MESSAGE = __('There seems to have been a technical problem. Please contact us at 800 0231 and we will assist you with enabling Jaidah In House Financing.');
