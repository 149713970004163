import {
    ProductCustomizableOptions as SourceProductCustomizableOptions
} from 'SourceComponent/ProductCustomizableOptions/ProductCustomizableOptions.component';

/** @namespace Scandipwa/Component/ProductCustomizableOptions/Component */
export class ProductCustomizableOptionsComponent extends SourceProductCustomizableOptions {
    static defaultProps = {
        options: []
    };
}

export default ProductCustomizableOptionsComponent;
