import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
    mapDispatchToProps, mapStateToProps,
    NewsletterSubscriptionContainer as SourceNewsletterSubscriptionContainer
} from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.container';

/** @namespace Scandipwa/Component/NewsletterSubscription/Container */
export class NewsletterSubscriptionContainer extends SourceNewsletterSubscriptionContainer {
    state = {
        ...this.state,
        isTOSAccepted: false
    };

    containerProps() {
        const { isTOSAccepted } = this.state;

        return {
            ...super.containerProps(),
            isTOSAccepted
        };
    }

    /*
    ** JAID 502 Overridden to reset form after submit
     */
    onFormSubmitDone(form) {
        super.onFormSubmitDone();

        this.setState({ isTOSAccepted: false });
        const emailInput = Array.from(form.querySelectorAll('input')).find(({ name }) => name === 'newsletterEmail');
        emailInput.value = '';
    }

    setTOS() {
        this.setState(({ isTOSAccepted: oldIsTACAccepted }) => ({
            isTOSAccepted: !oldIsTACAccepted
        }));
    }

    containerFunctions = {
        ...this.containerFunctions,
        setTOS: this.setTOS.bind(this)
    };

    onFormSubmit(form, fields) {
        const {
            subscribeToNewsletter,
            allowGuestSubscribe,
            isSignedIn,
            showErrorNotification
        } = this.props;
        const { isTOSAccepted } = this.state;

        const {
            value: email
        } = fields.find(({ name }) => name === 'newsletterEmail') || {};

        if (!allowGuestSubscribe && !isSignedIn) {
            showErrorNotification(
                __('Guests can not subscribe to the newsletter. You must create an account or login to subscribe.')
            );

            return;
        }

        if (!isTOSAccepted) {
            showErrorNotification(
                __('You must accept the terms and conditions.')
            );

            return;
        }

        this.setState({ isLoading: true });

        subscribeToNewsletter(email)
            .then(this.onFormSubmitDone(form))
            .catch(this.onFormSubmitDone(form));
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsletterSubscriptionContainer));
