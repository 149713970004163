import PropTypes from 'prop-types';

import {
    ImageContainer as SourceImageContainer
} from 'SourceComponent/Image/Image.container';

/** @namespace Scandipwa/Component/Image/Container */
export class ImageContainer extends SourceImageContainer {
    static propTypes={
        ...SourceImageContainer.propTypes,
        isLazy: PropTypes.bool,
        hasAdditionalLogoOnLoader: PropTypes.bool
    };

    static defaultProps = {
        ...SourceImageContainer.defaultProps,
        isLazy: true,
        hasAdditionalLogoOnLoader: false
    };

    containerProps() {
        const { isLazy, hasAdditionalLogoOnLoader } = this.props;

        return {
            ...super.containerProps(),
            isLazy,
            hasAdditionalLogoOnLoader
        };
    }
}

export default ImageContainer;
