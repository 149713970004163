export const PAYMENT_STEP = 'financing';
export const DEALERS_OFFER_STEP = 'dealeroffer';
export const TRADE_IN_STEP = 'tradein';
export const DELIVERY_STEP = 'delivery';
export const SUMMARY_STEP = 'summary';
export const SUCCESS_STEP = 'success';
export const COMPLETED_STEP = 'COMPLETED_STEP';
export const ID_COPY = 'idcopy';
export const SALARY_DOC = 'salarydoc';
export const BANK_STAT = 'bankstat';
export const CREDIT_DOC = 'creditdoc';

export const ORDER_RESERVATION_URL = '/reservation';

export const CUSTOM_STYLING_STEPS = [DEALERS_OFFER_STEP, SUCCESS_STEP];
export const CAN_ACCESS_DIRECTLY_STEPS = [DEALERS_OFFER_STEP, SUCCESS_STEP];

export const ORDER_RESERVATION_STEP_NUMBER_MAP = {
    [DEALERS_OFFER_STEP]: 0,
    [PAYMENT_STEP]: 1,
    [SUMMARY_STEP]: 2,
    [SUCCESS_STEP]: 3
};
