/* eslint-disable react/jsx-no-bind */
import { lazy } from 'react';

import TextPlaceholder from 'Component/TextPlaceholder';
import fromCache from 'Util/Cache/Cache';
import { getProductInStock } from 'Util/Product/Extract';

import { CATEGORY_PAGE } from '../../component/ProductLabel/ProductLabel.config';

export const ProductLabel = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-label" */
    '../../component/ProductLabel'
));

export const OUT_OF_STOCK = 'OUT_OF_STOCK';

const renderSelectButtonPlugin = (option = {}, attribute_code, hasPrice = false, isStarting = false, showOutOfStock = false, instance) => {
    const {
        label = '',
        value = ''
    } = option;

    const {
        updateConfigurableVariant,
        parameters = {},
        variants,
        isMobile,
        areDetailsLoaded,
        activeProduct: { stock_status }
    } = instance.props;

    if (!areDetailsLoaded) {
        return <div block="ProductStepPlaceholder"><TextPlaceholder length="medium" /></div>;
    }

    // eslint-disable-next-line fp/no-let, prefer-const
    let memorizedOutOfStockVariant = {};

    const variant = variants.find((variant) => {
        const isInStock = fromCache(getProductInStock, [variant]);

        if (!isInStock) {
            memorizedOutOfStockVariant = (variant.am_label || [])?.length ? variant : {};
            return false;
        }

        return variant.attributes?.trim?.attribute_value === value;
    }) ?? memorizedOutOfStockVariant;

    const attributeValuePrice = hasPrice ? instance.renderAttributeValuePrice(attribute_code, value, isStarting) : true;

    const isLabelRender = (variant?.stock_item?.in_stock === false || stock_status === OUT_OF_STOCK);

    return (
        <button
          key={ value || attribute_code }
          onClick={ () => updateConfigurableVariant(attribute_code, value) }
          block="ProductStepsTab"
          elem="SelectItem"
          mods={ {
              active: parameters?.[attribute_code] === value
          } }
          mix={ { block: 'ProductLabelPlugin' } }
        >
            { label }
            { hasPrice && attributeValuePrice }
            { isMobile && showOutOfStock && isLabelRender && (
                <div block="GalleryTrim" elem="ProductLabel">
                    <ProductLabel
                      amLabel={ memorizedOutOfStockVariant?.am_label }
                      productId={ memorizedOutOfStockVariant?.id }
                      amLabelMode={ CATEGORY_PAGE }
                      isMobile={ isMobile }
                    />
                </div>

            ) }
        </button>
    );
};

const renderSelectType = (args, callback, instance) => {
    const [attribute, hasPrice, isStarting, showOutOfStock] = args;

    if (showOutOfStock === true) {
        const {
            attribute_options = {},
            attribute_code = '',
            values = []
        } = attribute || {};

        return (
            <div block="ProductStepsTab" elem="SelectWrapper">
                { values.map(
                    ({ value_index }) => renderSelectButtonPlugin(
                        attribute_options[value_index],
                        attribute_code,
                        hasPrice,
                        isStarting,
                        showOutOfStock,
                        instance
                    )
                ) }
            </div>
        );
    }

    return callback(...args);
};

export const config = {
    'Scandipwa/Component/ProductSteps/Component': {
        'member-function': {
            renderSelectType
        }
    }
};

export default config;
