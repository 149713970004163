import { withStoreRegex } from 'Component/Router/Router.component';

import {
    EVENT_GTM_SUBMIT_SHOW_ROOM,
    EVENT_GTM_SUBMIT_TEST_DRIVE
} from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

const successSubmitForm = (args, callback, instance) => {
    const { match: { path } = {}, event } = instance.props;

    if (path === withStoreRegex('/showroom')) {
        event(EVENT_GTM_SUBMIT_SHOW_ROOM);
    }

    if (path === withStoreRegex('/testdrive')) {
        event(EVENT_GTM_SUBMIT_TEST_DRIVE);
    }

    callback(...args);
};

export default {
    'Scandipwa/Route/ShowroomBooking/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Scandipwa/Route/ShowroomBooking/Container': {
        'member-function': {
            successSubmitForm
        }
    }
};
