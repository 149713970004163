import { connect } from 'react-redux';

import ProductAttributeValue from './ProductAttributeValue.component';

/** @namespace Scandipwa/Component/ProductAttributeValue/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    showUnavailableOptions: state.ConfigReducer.show_unavailable_options
});

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/always-both-mappings
export default connect(mapStateToProps)(ProductAttributeValue);
