/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import { createElement, Suspense } from 'react';

import Loader from 'Component/Loader/Loader.component';
import WidgetFactory from 'Component/WidgetFactory';

import HtmlCode from '../../component/HtmlCode';
import { HTML_CODE_CONTENT_TYPE, HTML_CODE_SKELETON } from '../../component/HtmlCode/HtmlCode.config';

const convertAttributesToProps = (attribs) => {
    const toCamelCase = (string) => string.replace(/_[a-z]/g, (match) => match.substr(1).toUpperCase());

    const convertPropertiesToValidFormat = (properties) => Object.entries(properties)
        .reduce((validProps, [key, value]) => {
            // eslint-disable-next-line no-restricted-globals
            if (!isNaN(value)) {
                return { ...validProps, [toCamelCase(key)]: +value };
            }

            return { ...validProps, [toCamelCase(key)]: value };
        }, {});

    const properties = convertPropertiesToValidFormat(attribs);

    return attributesToProps(properties);
};

const addReplacementRule = (originalMember, instance) => ([
    {
        query: { dataContentType: HTML_CODE_CONTENT_TYPE },
        replace: (domNode) => {
            if (domNode.children[0].name === 'widget') {
                return (
                    <Suspense fallback={ <Loader isLoading /> }>
                        <WidgetFactory { ...convertAttributesToProps(domNode?.children?.[0]?.attribs || {}) } />
                    </Suspense>
                );
            }

            return (
                createElement(HtmlCode, {
                    elements: instance.toReactElements(
                        [domNode],
                        HTML_CODE_SKELETON,
                        { allowedTypes: ['tag', 'script'] }
                    )
                })
            );
        }

    },
    ...originalMember
]);

export default {
    'Component/Html/Component': {
        'member-property': {
            rules: addReplacementRule
        }
    }
};
