/* eslint-disable no-magic-numbers */
import { DEFAULT_INTEREST_RATE } from 'Component/PaymentStep/PaymentStep.config.js';
import getStore from 'Util/Store';

export {
    calculateFinalPrice, formatCurrency, roundPrice, getLowestPriceTiersPrice
} from 'SourceUtil/Price/Price';

export const QAR_AR = 'ريال قطري';

export const AR_LANGUAGE = 'ar';

/**
 * Overriden to include currencyDisplay option
 */
/** @namespace Scandipwa/Util/Price/formatPrice */
export const formatPrice = (price, currency = 'USD') => {
    const language = navigator.languages ? navigator.languages[0] : navigator.language;

    const priceFormatted = new Intl.NumberFormat(language, {
        style: 'currency', currency, minimumFractionDigits: 0
    }).format(price);

    return document.documentElement.lang === AR_LANGUAGE && currency === 'QAR'
        ? `${priceFormatted.replace('QAR', '')} ${QAR_AR } ` : priceFormatted;
};

/**
 * Added calculation for price per month
 */
/** @namespace Scandipwa/Util/Price/calculatePricePerMonth */
export const calculatePricePerMonth = (price, numberOfMonths, interestRateInDecimals, downPaymentPercent) => {
    const downPaymentInDecimals = downPaymentPercent / 100;

    const interestRate = interestRateInDecimals ?? DEFAULT_INTEREST_RATE / 100;

    const numberOfYears = numberOfMonths / 12;

    const priceAfterDP = price * (1 - downPaymentInDecimals);

    const {
        ConfigReducer: { ihf_rate = interestRate } = {}
    } = getStore().getState() || {};

    const pricePerMonth = (priceAfterDP + (priceAfterDP * ihf_rate * numberOfYears)) / numberOfMonths;
    return Math.round(pricePerMonth);
};
