import { Notification as SourceNotification } from 'SourceComponent/Notification/Notification.component';

import { ANIMATION_DURATION } from './Notification.config';

import 'Component/Notification/Notification.config';
import './Notification.override.style';

export * from 'SourceComponent/Notification/Notification.component';
/** @namespace Scandipwa/Component/Notification/Component */
export class NotificationComponent extends SourceNotification {
    /**
     * Added to bind the function
     */
    hideNotification = this._hideNotification.bind(this);

    /**
     * Override to change method name
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    _hideNotification() {
        const { onHideNotification, notificationId } = this.props;
        this.setState({ isNotificationVisible: false });

        // give CSS time to animate
        this.CSSHideTimeout = setTimeout(() => {
            onHideNotification(notificationId);
        }, ANIMATION_DURATION);
    }
}

export default NotificationComponent;
