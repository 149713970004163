import {
    FieldGroup as SourceFieldGroup
} from 'SourceComponent/FieldGroup/FieldGroup.component';

import './FieldGroup.override.style';

/** @namespace Scandipwa/Component/FieldGroup/Component */
export class FieldGroupComponent extends SourceFieldGroup {
    // overriden to render label as h2
    renderLabel() {
        const { label } = this.props;

        if (!label) {
            return null;
        }

        return (
            <h2 block="FieldGroup" elem="Label">
                { label }
            </h2>
        );
    }
}

export default FieldGroupComponent;
