/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase';

export const _getFilterArgumentMap = (args, callback, instance) => ({
    ...callback.apply(instance, args),
    parentCategory: (id) => ({ parent_category_id: { eq: id } })
});

export const _getArgumentsMap = (args, callback, instance) => {
    const filterArgumentMap = instance._getFilterArgumentMap();

    return {
        ...callback.apply(instance, args),
        filter: {
            type: 'ProductAttributeFilterInput!',
            handler: (initialOptions = {}) => {
                const { group_id } = BrowserDatabase.getItem(CUSTOMER) || {};

                const options = {
                    ...initialOptions,
                    customerGroupId: group_id || '0',
                    parentCategory: initialOptions.categoryIds
                };

                const {
                    customFilters: { category_id } = {}
                } = options;

                if (category_id) {
                    options.categoryIds = undefined;
                }

                const parsedOptions = Object.entries(options).reduce(
                    (acc, [key, option]) => {
                        if (!option || !filterArgumentMap[key]) {
                            return acc;
                        }

                        return { ...acc, ...filterArgumentMap[key](option) };
                    },
                    {}
                );

                return parsedOptions;
            }
        }
    };
};

export const _getProductFields = (args, callback, instance) => {
    const { requireInfo } = instance.options;

    if (requireInfo) {
        return [
            ...callback.apply(instance, args),
            'currency_rate',
            'min_price',
            'max_price'
        ];
    }

    return callback.apply(instance, args);
};

export default {
    'Query/ProductList/Query': {
        'member-function': {
            _getFilterArgumentMap,
            _getArgumentsMap,
            _getProductFields
        }
    }
};
