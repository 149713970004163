/* eslint-disable no-magic-numbers */
import { validate as sourceValidate } from 'SourceUtil/Validator/Validator.js';
import {
    FILE_VALIDATION_UNITS, FILE_VALIDATION_VALUES, VALIDATION_INPUT_TYPE, VALIDATION_MESSAGES
} from 'Util/Validator/Config';

export * from 'SourceUtil/Validator/Validator.js';

export const DEFAULT_SIZE = 4;

/** @namespace Scandipwa/Util/Validator/validate */
export const validate = (value, rule) => {
    const {
        fileSize,
        fileExtension,
        customErrorMessages: {
            onFileSizeFail,
            onExtensionFail,
            onInputTypeFail
        } = {},
        inputType
    } = rule;

    // eslint-disable-next-line no-unused-vars
    const { fileExtension: unused, ...restRule } = rule;

    const isFile = typeof value === 'object' && value !== null && value.name && value.size;

    // Validate field using default validations without `fileExtension`
    const baseValidation = sourceValidate(
        !isFile || !value ? value : value.name,
        restRule
    );

    const output = {
        value: isFile ? value.name : value,
        errorMessages: []
    };

    // Add default validations to the output
    if (baseValidation !== true) {
        output.value = baseValidation.value;
        output.errorMessages = baseValidation.errorMessages;
    }

    // custom validation for qid
    if (inputType === VALIDATION_INPUT_TYPE.QID && output.errorMessages.length === 0) {
        // if first digit is 3
        if (value[0] === '3') {
            // number from last 2 digits of current year
            const currentYear = Number(String(new Date().getFullYear()).slice(-2));
            // number from 2nd and 3rd digits of the value
            const qidYear = Number(value.at(1) + value.at(2));

            // compare the value year and the current year
            if (qidYear > currentYear) {
                output.errorMessages.push(onInputTypeFail || VALIDATION_MESSAGES[inputType]);
            }
        }
    }

    // Validate file size
    if (fileSize && output.value !== '') {
        const { max = DEFAULT_SIZE, unit = FILE_VALIDATION_UNITS.MB } = fileSize;

        const limit = FILE_VALIDATION_VALUES[unit]
            ? max * FILE_VALIDATION_VALUES[unit]
            : max * FILE_VALIDATION_VALUES[FILE_VALIDATION_UNITS.MB];

        if (value.size && value.size > limit) {
            output.errorMessages.push(onFileSizeFail || VALIDATION_MESSAGES.fileSize);
        }
    }

    // Validate file extension case insensitively
    if (fileExtension && output.value !== '') {
        const { accept } = fileExtension;
        const acceptedExtensions = accept.split(', ');
        const currentFileExtension = output.value.split('.').pop().toLowerCase();

        if (!acceptedExtensions.includes(currentFileExtension)) {
            output.errorMessages.push(onExtensionFail || VALIDATION_MESSAGES.fileExtension);
        }
    }

    const { errorMessages } = output;
    const messagesWithTranslations = errorMessages.map((value) => (typeof value === 'function' ? value() : value));

    return errorMessages.length === 0 || { ...output, errorMessages: messagesWithTranslations };
};

export default validate;
