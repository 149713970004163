export * from 'SourceUtil/Product/Transform';

/** @namespace Scandipwa/Util/Product/Transform/mergeSingleItem */
export const mergeSingleItem = (rawItem, supportiveItem, isVariant) => ({
    ...(isVariant ? rawItem.product : rawItem),
    ...(isVariant ? supportiveItem.product : supportiveItem)
});

/** @namespace Scandipwa/Util/Product/Transform/mergeProductData */
export const mergeProductData = (rawData, supportiveData, isVariants = false) => rawData.map((rawItem, idx) => {
    const { variants: rawVariants = [], ...restItem } = rawItem;
    const { variants: supportiveVariants = [], ...supportiveItem } = supportiveData[idx];

    const product = mergeSingleItem(restItem, supportiveItem, isVariants);

    if (isVariants) {
        return { product };
    }

    if (rawVariants.length > 0) {
        product.variants = mergeProductData(rawVariants, supportiveVariants, true);
    }

    return product;
});
