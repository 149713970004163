import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './EstimatedDelivery.style';

/** @namespace Scandipwa/Component/EstimatedDelivery/Component */
export class EstimatedDeliveryComponent extends PureComponent {
    static propTypes = {
        date: PropTypes.string.isRequired
    };

    render() {
        const { date } = this.props;

        if (!date) {
            return null;
        }

        return (
            <div block="EstimatedDelivery">
                <div block="EstimatedDelivery" elem="Image" />
                <div block="EstimatedDelivery" elem="Content">
                    <span block="EstimatedDelivery" elem="Header">
                        { __('The estimated delivery date') }
                    </span>
                    <span block="EstimatedDelivery" elem="Value">
                        { date }
                    </span>
                </div>
            </div>
        );
    }
}

export default EstimatedDeliveryComponent;
