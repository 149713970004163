import { connect } from 'react-redux';

import {
    mapStateToProps
} from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.container';
import {
    ForgotPasswordContainer as SourceForgotPasswordContainer,
    mapDispatchToProps as sourceMapDispatchToProps
} from 'SourceRoute/ForgotPassword/ForgotPassword.container';
import { appendWithStoreCode } from 'Util/Url';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

export { mapStateToProps };

/** @namespace Scandipwa/Route/ForgotPassword/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateBreadcrumbs: (breadcrumbs) => BreadcrumbsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.update(breadcrumbs, dispatch)
    )
});

/** @namespace Scandipwa/Route/ForgotPassword/Container */
export class ForgotPasswordContainer extends SourceForgotPasswordContainer {
    componentDidMount() {
        super.componentDidMount();
        const { toggleBreadcrumbs } = this.props;
        toggleBreadcrumbs(true);
        this.updateBreadcrumbs();
    }

    updateBreadcrumbs() {
        const { updateBreadcrumbs } = this.props;
        updateBreadcrumbs([{ name: '', url: '' }, {
            name: __('Back to login'),
            url: appendWithStoreCode('/customer/account/login')
        }]);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer);
