/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import MyAccountSignIn from 'Component/MyAccountSignIn';
import { MyAccountOverlay as SourceMyAccountOverlay }
from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.component';

/** @namespace Scandipwa/Component/MyAccountOverlay/Component */
export class MyAccountOverlay extends SourceMyAccountOverlay {
    renderSignIn() {
        const {
            state,
            onFormError,
            handleForgotPassword,
            handleCreateAccount,
            isCheckout,
            setLoadingState,
            onSignIn
        } = this.props;

        return (
            <MyAccountSignIn
              state={ state }
              onFormError={ onFormError }
              handleForgotPassword={ handleForgotPassword }
              handleCreateAccount={ handleCreateAccount }
              isCheckout={ isCheckout }
              setLoadingState={ setLoadingState }
              onSignIn={ onSignIn }
              isOTPSignIn
            />
        );
    }
}

export default MyAccountOverlay;
