import {
    VALIDATION_INPUT_TYPE as sourceInputType,
    VALIDATION_MESSAGES as sourceValidationMessages,
    VALIDATION_RULES as sourceValidationRules
} from 'SourceUtil/Validator/Config';

export * from 'SourceUtil/Validator/Config';

export const VALIDATION_INPUT_TYPE = {
    ...sourceInputType,
    QID: 'QID',
    mileage: 'mileage',
    VIN: 'VIN',
    license: 'license'
};

export const VALIDATION_MESSAGES = {
    ...sourceValidationMessages,
    isRequired: () => __('This field is required.'),
    [VALIDATION_INPUT_TYPE.QID]: () => __('Enter a valid QID'),
    [VALIDATION_INPUT_TYPE.VIN]: () => __('VIN must be 17 digits without any spaces'),
    [VALIDATION_INPUT_TYPE.phone]: () => __('The phone number should be 8 digits.'),
    [VALIDATION_INPUT_TYPE.mileage]: () => __('Mileage must be natural number but not 0!'),
    [VALIDATION_INPUT_TYPE.license]: () => __('License must be natural number but not 0!'),
    [VALIDATION_INPUT_TYPE.password]: () => __('Password must contain letters, numbers and special characters!'),
    fileSize: () => __('The uploaded file is too big'),
    [VALIDATION_INPUT_TYPE.email]: () => __('Incorrect email format!'),
    fileExtension: () => __('Incorrect File extension upload!'),
    [VALIDATION_INPUT_TYPE.alphaSpace]: () => __('Only Latin characters are allowed!')
};

export const VALIDATION_RULES = {
    ...sourceValidationRules,
    [VALIDATION_INPUT_TYPE.phone]: /^[\\+]?[0-9]{8}$/im,
    // checks all the country codes in QID to avoid consecutive digits
    // eslint-disable-next-line max-len
    [VALIDATION_INPUT_TYPE.QID]: /^(2|3)\d{2}(000|004|008|010|012|016|020|024|028|031|032|036|040|044|048|050|051|052|056|060|064|068|070|072|074|076|080|084|086|090|092|096|100|101|104|108|112|116|120|124|132|136|140|144|148|152|156|162|166|170|174|175|178|180|184|188|191|192|196|200|203|204|208|212|214|218|222|226|230|231|232|233|234|238|242|246|248|250|254|258|260|262|266|268|276|270|280|288|292|296|300|304|308|312|316|320|324|328|332|334|336|340|344|348|352|356|360|364|368|372|376|380|384|388|392|396|398|400|404|408|410|414|417|418|422|426|428|430|434|438|440|442|446|450|454|458|462|466|470|474|478|480|484|488|492|496|498|499|500|504|508|512|516|520|524|528|532|533|540|544|548|554|558|562|566|570|574|578|580|582|583|584|585|586|590|598|600|604|608|612|616|620|624|626|630|634|638|642|643|646|652|654|658|659|660|662|663|666|670|674|678|682|686|688|690|694|702|703|704|705|706|710|716|724|728|732|736|740|744|748|752|756|760|762|764|768|772|776|780|784|788|792|795|796|798|800|804|807|810|818|826|830|831|832|833|834|840|850|854|858|860|862|872|876|882|886|887|888|890|891|894|901|981|983|990|991|999)\d{5}$/,
    [VALIDATION_INPUT_TYPE.VIN]: /^([0-9]|[A-Z]|[a-z]){17}?$/,
    [VALIDATION_INPUT_TYPE.mileage]: /^[1-9][0-9]{0,3}(,[0-9]{3})*(,[0-9]+)?$/,
    [VALIDATION_INPUT_TYPE.license]: /^[1-9](\d+)?$/i,
    // eslint-disable-next-line max-len
    [VALIDATION_INPUT_TYPE.password]: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.,:^;`~'"<>])[A-Za-z\d@$!%*#?&.,:^;`~'"<>]{7,}$/,
    // eslint-disable-next-line max-len
    [VALIDATION_INPUT_TYPE.email]: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

};

export const FILE_VALIDATION_UNITS = {
    MB: 'MB',
    KB: 'KB'
};

export const FILE_VALIDATION_VALUES = {
    [FILE_VALIDATION_UNITS.MB]: 1048576,
    [FILE_VALIDATION_UNITS.KB]: 1024
};
