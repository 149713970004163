import ProductListQuery from 'Query/ProductList.query';
import { OrderQuery as SourceOrderQuery } from 'SourceQuery/Order.query';
import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/Order/Query */
export class OrderQuery extends SourceOrderQuery {
    _getOrderItemsFields(isSingleOrder) {
        return [
            ...super._getOrderItemsFields(isSingleOrder),
            this._getOrderListProductsField(),
            this._getLabelField(),
            this.getReservationDataPaymentField(),
            'quote_number',
            'quote_expiration_date',
            'info_message'
        ];
    }

    _getOrderListProductsField() {
        return new Field('order_list_products')
            .addFieldList(this._getOrderListProductsFields());
    }

    _getOrderListProductsFields() {
        return [
            'name',
            'is_car',
            'parentSku',
            'sku',
            this._getOrderListProductImageField(),
            ProductListQuery._getPriceRangeField(),
            ProductListQuery._getCategoriesField(),
            ProductListQuery._getAttributesField(false, true)
        ];
    }

    _getOrderListProductImageField() {
        return new Field('image')
            .addFieldList(this._getOrderListProductImageFields());
    }

    _getOrderListProductImageFields() {
        return [
            'label',
            'path',
            'url'
        ];
    }

    _getLabelField() {
        return new Field('label')
            .addFieldList([
                'text',
                'status',
                'sf_status'
            ]);
    }

    _getBaseOrderInfoFields() {
        return [
            'created_at',
            'currency_code',
            'grand_total',
            'id',
            'increment_id',
            'status',
            'status_label',
            'sub_total',
            'total_qty_ordered',
            'ihf_status'
        ];
    }

    _getOrderByIdField(orderId) {
        const orderProducts = new Field('order_products').addFieldList([
            ...ProductListQuery._getCartProductInterfaceFields(true),
            this._getOrderListProductImageField(),
            'is_car',
            'parentSku'
        ]);
        const baseOrderInfo = new Field('base_order_info').addFieldList(this._getBaseOrderInfoFields());

        return new Field('getOrderById')
            .addArgument('id', 'Int!', orderId)
            .addField(orderProducts)
            .addField(baseOrderInfo)
            .addField(this.getReservationDataPaymentField());
    }

    getReservationDataPaymentField() {
        return new Field('reservation_payment_data')
            .addFieldList(this._getReservationDataPaymentFieldList());
    }

    _getReservationDataPaymentFieldList() {
        return [
            'amount',
            'id',
            'masked_cc',
            'name',
            'payment_date',
            'processed',
            'type'
        ];
    }

    getCancelOrderMutation(orderId) {
        return new Field('cancelOrder')
            .addArgument('orderId', 'ID!', orderId);
    }

    getUpdatePaymentMutation(paymentInformation) {
        return new Field('updatePaymentInformation')
            .addArgument('paymentInformation', 'PaymentInformationInput!', paymentInformation)
            .addFieldList(this._getOrderPaymentMethodFields());
    }

    _getOrderPaymentMethodFields() {
        return [
            'name',
            'type',
            new Field('additional_data')
                .addFieldList([
                    'name',
                    'value'
                ])
        ];
    }

    getCheckVinAvailabilityMutation(orderId) {
        return new Field('checkVinAvailability')
            .addArgument('orderId', 'ID!', orderId);
    }

    getDeleteAllOrdersMutation() {
        return new Field('deleteAllOrders');
    }
}

export default new OrderQuery();
