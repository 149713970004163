/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const STORES = 'stores';
export const POSITION = 'position';

export const USAGE_ONLY_STORE_LIST = 0;
export const USAGE_ONLY_SERVICE = 1;
export const USAGE_BOTH = 2;
