/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional, @scandipwa/scandipwa-guidelines/only-render-in-component, react/jsx-no-bind */

import ProductTab from 'SourceComponent/ProductTab';

import './Tab.style';

/*
*   Tab component is using in Tabs component to render tabs.
*/

/** @namespace Scandipwa/Component/Tab/Component */
export class TabComponent extends ProductTab {
    render() {
        const { tabName, isActive } = this.props;

        return (
            <li
              block="Tab"
              elem="Item"
              mods={ { isActive } }
            >
                <button
                  mix={ { block: 'Tab', elem: 'Button' } }
                  onClick={ this.onClick }
                >
                    { tabName }
                </button>
            </li>
        );
    }
}

export default TabComponent;
