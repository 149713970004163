/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { UPDATE_LINKED_PRODUCTS, UPDATE_LOADING_STATUS } from 'Store/LinkedProducts/LinkedProducts.action';
import { LINKED_PRODUCTS } from 'Store/LinkedProducts/LinkedProducts.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase';

export const UPSELL = 'upsell';
export const RELATED = 'related';
export const CROSS_SELL = 'crosssell';
export const AUTOMATIC_RELATED = 'automatic_related';
export const INSURANCE = 'insurance';

/** @namespace Store/LinkedProducts/Reducer/getInitialState */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/use-namespace
export const getInitialState = () => ({
    linkedProducts: BrowserDatabase.getItem(LINKED_PRODUCTS) || {
        upsell: {},
        related: {},
        crosssell: {},
        automatic_related: {},
        insurance: {}
    },
    isLoading: false
});

/** @namespace Scandipwa/Store/LinkedProducts/Reducer/LinkedProductsReducer */
export const LinkedProductsReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_LINKED_PRODUCTS:
        const {
            linkedProducts: {
                [UPSELL]: upsell,
                [RELATED]: related,
                [CROSS_SELL]: crosssell,
                [AUTOMATIC_RELATED]: automatic_related,
                [INSURANCE]: insurance,
                updateCrossSell = false
            }
        } = action;

        const {
            linkedProducts: {
                [CROSS_SELL]: prevCrossSell
            }
        } = state;

        if (updateCrossSell) {
            return {
                ...state,
                linkedProducts: {
                    [UPSELL]: upsell,
                    [RELATED]: related,
                    [CROSS_SELL]: crosssell,
                    [AUTOMATIC_RELATED]: automatic_related,
                    [INSURANCE]: insurance
                },
                isLoading: false
            };
        }

        return {
            ...state,
            linkedProducts: {
                [UPSELL]: upsell,
                [RELATED]: related,
                [CROSS_SELL]: {
                    ...prevCrossSell,
                    ...related,
                    items: Object.values({
                        ...prevCrossSell.items,
                        ...crosssell.items
                    })
                },
                [AUTOMATIC_RELATED]: automatic_related,
                [INSURANCE]: insurance
            },
            isLoading: false
        };
    case UPDATE_LOADING_STATUS:
        const { payload: { status } } = action;

        return {
            ...state,
            isLoading: status
        };
    default:
        return state;
    }
};

export default LinkedProductsReducer;
