import { lazy } from 'react';

import Image from 'Component/Image/Image.component';
import {
    BASE_MEDIA_TYPE,
    TRIM
} from 'Component/ProductActions/ProductActions.config';
import ProductGalleryIcons from 'Component/ProductGalleryIcons';
import TextPlaceholder from 'Component/TextPlaceholder';
import fromCache from 'Util/Cache/Cache';
import { getProductInStock } from 'Util/Product/Extract';
import {
    findProductAccordingToMediaType
} from 'Util/Product/Product';

import { CATEGORY_PAGE } from '../../component/ProductLabel/ProductLabel.config';

export const ProductLabel = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-label" */
    '../../component/ProductLabel'
));

const renderProductLabel = (props) => {
    const {
        product: {
            variants = []
        },
        device: {
            isMobile
        },
        inStock
    } = props;

    if (!inStock) {
        const variant = variants.find((variant) => !fromCache(getProductInStock, [variant]) && (variant.am_label || [])?.length);

        if (variant) {
            return (
                <div block="GalleryTrim" elem="ProductLabel">
                    <ProductLabel
                      amLabel={ variant?.am_label }
                      productId={ variant.id }
                      amLabelMode={ CATEGORY_PAGE }
                      isMobile={ isMobile }
                    />
                </div>

            );
        }
    }

    return null;
};
const renderProductGallery = (args, callback, instance) => {
    const {
        activeProduct, areDetailsLoaded
    } = instance.props;

    const { iconsToRender } = args;

    const { attributes = {}, name } = activeProduct;

    const {
        attribute_options = {},
        attribute_value = {}
    } = attributes[TRIM] || {};

    const label = attribute_options[attribute_value]?.label;
    const onlyBaseMediaEntity = findProductAccordingToMediaType(activeProduct, BASE_MEDIA_TYPE);

    if (!onlyBaseMediaEntity && areDetailsLoaded) {
        return null;
    }

    const { large: { url } = {} } = onlyBaseMediaEntity || {};

    return (
        <>
            <div block="GalleryTrim">
                <TextPlaceholder content={ label } />
                { renderProductLabel(instance.props) }

            </div>
            <div
              block="ProductActions"
              elem="ProductGalleryWrapper"
            >
                <Image
                  isLazy={ false }
                  src={ url }
                  ratio="16x9"
                  alt={ name }
                  isPlaceholder={ !areDetailsLoaded }
                  hasAdditionalLogoOnLoader
                />
                <div block="GalleryIcons">
                    <ProductGalleryIcons iconsToRender={ iconsToRender } />
                </div>
            </div>
        </>
    );
};

export const config = {
    'Scandipwa/Component/ProductActions/Component': {
        'member-function': {
            renderProductGallery
        }
    }
};

export default config;
