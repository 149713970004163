import { withRouter } from 'react-router';

import { ProductGalleryBaseImageContainer as SourcePGBImageContainer }
from 'SourceComponent/ProductGalleryBaseImage/ProductGalleryBaseImage.container';
import media, { PRODUCT_MEDIA } from 'Util/Media/Media';

/** @namespace Scandipwa/Component/ProductGalleryBaseImage/Container */
export class ProductGalleryBaseImageContainer extends SourcePGBImageContainer {
    _getSrc() {
        const {
            mediaData: { file, large: { url: largeUrl } = {} },
            isZoomEnabled
        } = this.props;

        if (!isZoomEnabled) {
            return largeUrl || media(file, PRODUCT_MEDIA);
        }

        return file ? media(file, PRODUCT_MEDIA) : largeUrl;
    }
}

export default withRouter(
    ProductGalleryBaseImageContainer
);
