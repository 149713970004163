import { generateQuery } from 'SourceUtil/Url/Url';
import getStore from 'Util/Store';

export * from 'SourceUtil/Url/Url';

/** @namespace Scandipwa/Util/Url/setQueryParamsWithoutHistory */
export const setQueryParamsWithoutHistory = (keyValueObject, location, history) => {
    const { state } = location;
    const query = generateQuery(keyValueObject, location, history);

    history.replace({ search: query, state });
};

/** @namespace Scandipwa/Util/Url/getPathWithoutStoreCode */
export const getPathWithoutStoreCode = () => {
    const url = window.location.href;
    const { ConfigReducer: { base_link_url = window.location.href } = {} } = getStore().getState() || {};

    if (url.length < base_link_url.length) {
        return '/';
    }

    return url.replace(base_link_url, '/');
};
