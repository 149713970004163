import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FIELD_TYPE from 'Component/Field/Field.config';
import { FIELD_DATE_TYPE } from 'Component/FieldDate/FieldDate.config';
import {
    DatePickerContainer as SourceDatePickerContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'SourceComponent/DatePicker/DatePicker.container';
import { noopFn } from 'Util/Common';
import {
    getTimeFormat,
    getYearRangeAttributes
} from 'Util/Form/Extract';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandipwa/Component/DatePicker/Container */
export class DatePickerContainer extends SourceDatePickerContainer {
    static propTypes = {
        ...this.propTypes,
        isDisabled: PropTypes.bool,
        updateSelectedValues: PropTypes.func,
        filteredDays: PropTypes.arrayOf.string,
        fieldType: PropTypes.oneOf([
            FIELD_TYPE.date,
            FIELD_TYPE.dateWithMonthYearSelector
        ]),
        setRef: PropTypes.func
    };

    static defaultProps = {
        ...this.defaultProps,
        updateSelectedValues: () => {
        },
        isDisabled: false,
        fieldType: FIELD_TYPE.date,
        filteredDays: [],
        setRef: noopFn,
        isDaysDiscounts: false
    };

    containerFunctions = {
        ...this.containerFunctions,
        handleChange: this.handleChange.bind(this)
    };

    __construct(props) {
        super.__construct(props);
        this.state = {
            ...this.state,
            selectedDate: null
        };
    }

    handleChange(date) {
        const { onChange } = this.props;
        this.onSetDate(date);
        onChange({ value: date });
    }

    /**
     * Overridden to define props manually
     */
    containerProps() {
        const { selectedDate } = this.state;
        const {
            type,
            yearRange,
            timeFormat: magentoTimeFormat,
            uid,
            isRequired,
            dateFormat,
            availableDates,
            setRef,
            placeholder,
            isDisabled,
            maxDate,
            minDate,
            fieldType,
            value,
            filteredDays,
            selectedDate: preselectedDate,
            useValue,
            isDaysDiscounts,
            children
        } = this.props;

        const showTimeSelect = type === FIELD_DATE_TYPE.dateTime || type === FIELD_DATE_TYPE.time;
        const showTimeSelectOnly = type === FIELD_DATE_TYPE.time;
        const timeFormat = getTimeFormat(magentoTimeFormat);

        return {
            selectedDate: selectedDate || preselectedDate,
            showTimeSelect,
            showTimeSelectOnly,
            ...getYearRangeAttributes(yearRange),
            dateFormat,
            availableDates,
            timeFormat,
            uid,
            isClearable: !isRequired,
            setRef,
            placeholder,
            isDisabled,
            maxDate,
            minDate,
            fieldType,
            value,
            filteredDays,
            useValue,
            isDaysDiscounts,
            children
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatePickerContainer);
