import PropTypes from 'prop-types';

import {
    Image as SourceImage
} from 'SourceComponent/Image/Image.component';
import { IMAGE_LOADING } from 'SourceComponent/Image/Image.config';

import './Image.override.style.scss';

/** @namespace Scandipwa/Component/Image/Component */
export class ImageComponent extends SourceImage {
    static propTypes={
        ...SourceImage.propTypes,
        isLazy: PropTypes.bool,
        hasAdditionalLogoOnLoader: PropTypes.bool
    };

    static defaultProps = {
        ...SourceImage.defaultProps,
        isLazy: true,
        hasAdditionalLogoOnLoader: false
    };

    /*
    * Overridden to add isLazy prop for changing loading type
     */
    renderStyledImage() {
        const {
            alt,
            src,
            style,
            title,
            isLazy
        } = this.props;
        const { imageStatus } = this.state;

        return (
            <img
              block="Image"
              elem="Image"
              src={ src || '' }
              alt={ alt }
              mods={ {
                  isLoading: imageStatus === IMAGE_LOADING
              } }
              style={ style }
              title={ title }
              onLoad={ this.onLoad }
              onError={ this.onError }
              loading={ isLazy ? 'lazy' : 'eager' }
            />
        );
    }

    /*
    * Overridden to add isLazy prop for changing loading type
     */
    renderPlainImage() {
        const {
            alt,
            src,
            style,
            title,
            className,
            isLazy
        } = this.props;

        return (
            <img
              block={ className }
              src={ src || '' }
              alt={ alt }
              style={ style }
              title={ title }
              onLoad={ this.onLoad }
              onError={ this.onError }
              loading={ isLazy ? 'lazy' : 'eager' }
            />
        );
    }

    /**
     * Overridden to add hasAdditionalLogoOnLoader prop
     */
    render() {
        const {
            ratio,
            mix,
            isPlaceholder,
            wrapperSize,
            src,
            imageRef,
            className,
            isPlain,
            hasAdditionalLogoOnLoader
        } = this.props;

        const { imageStatus } = this.state;

        // render image as is: without additional container and additional styles
        if (isPlain) {
            return this.renderImage();
        }

        return (
            <div
              block="Image"
              ref={ imageRef }
              mods={ {
                  ratio,
                  imageStatus: imageStatus.toLowerCase(),
                  isPlaceholder,
                  hasSrc: !!src,
                  hasAdditionalLogoOnLoader
              } }
              mix={ mix }
              style={ wrapperSize }
              className={ className }
            >
                { this.renderImage() }
                { this.renderLoader() }
            </div>
        );
    }
}

export default ImageComponent;
