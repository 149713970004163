/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */

import ContentWrapper from 'Component/ContentWrapper';
import { COLOR_INTERIOR } from 'Component/ProductActions/ProductActions.config';
import Tab from 'Component/Tab';
import ProductTabs from 'SourceComponent/ProductTabs';

import './Tabs.style';

/*
*   Universal Tabs component. We can use it with any content.
*/

/** @namespace Scandipwa/Component/Tabs/Component */
export class TabsComponent extends ProductTabs {
    componentDidUpdate(prevProps) {
        const { tabs: prevTabs } = prevProps;
        const { tabs } = this.props;
        const [{ id: prevId }] = prevTabs;
        const [{ id }] = tabs;

        if (prevTabs.length !== tabs.length || prevId !== id) {
            this.setActiveTab(id);
        }
    }

    renderTab(item) {
        const { activeTab } = this.state;
        const { id, name } = item;

        return (
            <Tab
              tabName={ name }
              key={ id }
              onClick={ this.onTabClick }
              isActive={ id === activeTab }
            />
        );
    }

    renderTabs() {
        const { tabs } = this.props;
        const { activeTab } = this.state;

        const isInterior = activeTab === COLOR_INTERIOR;

        return (
            <>
                <ul block="Tabs" elem="List" mods={ { isInterior } }>
                    { tabs.map(this.renderTab.bind(this)) }
                </ul>
                { this.renderActiveTab() }
            </>
        );
    }

    render() {
        return (
            <ContentWrapper
              wrapperMix={ { block: 'Tabs', elem: 'Wrapper' } }
              label={ __('Tabs') }
            >
                { this.renderTabs() }
            </ContentWrapper>
        );
    }
}

export default TabsComponent;
