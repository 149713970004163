import { cloneElement } from 'react';

/** ProductLinks */
const renderProductCard = (args, callback) => {
    const position = args[1] + 1;
    const originalElement = callback(...args);

    return cloneElement(
        originalElement,
        { position }
    );
};

export default {
    'Scandipwa/Component/ProductLinks/Component': {
        'member-function': {
            renderProductCard
        }
    }
};
