import {
    ProductBundleOption as SourceProductBundleOption
} from 'SourceComponent/ProductBundleOption/ProductBundleOption.component';
import { ItemOptionsType } from 'Type/ProductList.type';

/** @namespace Scandipwa/Component/ProductBundleOption/Component */
export class ProductBundleOptionComponent extends SourceProductBundleOption {
    static propTypes = {
        ...this.propTypes,
        options: ItemOptionsType
    };
}

export default ProductBundleOptionComponent;
