export const TEXT_AREA_MAX_CHARACTERS = 1000;

export const SHOWROOM_BOOKING_BLOCK_ID = 'showroom-booking';

export const SHOWROOM_BOOKING_TIME_SLOT_LENGTH = 30;

export const SHOWROOM_BOOKING_SUCCESS_POPUP = 'SHOWROOM_BOOKING_SUCCESS_POPUP';

export const SHOWROOM_BOOKING_URL = '/showroom';

export const PREF_TIME_REGEX_SHOWROOM = /\d{1,2}:\d{2}/g;
