/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import LinkedProductsReducer from 'Store/LinkedProducts/LinkedProducts.reducer';
import { clearOptions, removeInsurance, removeOption } from 'Store/ProductOptions/ProductOptions.action';
import ProductOptionsReducer from 'Store/ProductOptions/ProductOptions.reducer';
import { withReducers } from 'Util/DynamicReducer';

import RelatedProductTotals from './RelatedProductTotals.component';

/** @namespace Scandipwa/Component/RelatedProductTotals/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    optionsData: _state.ProductOptionsReducer
});

/** @namespace Scandipwa/Component/RelatedProductTotals/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    removeOption: (params) => _dispatch(removeOption(params)),
    removeInsurance: () => _dispatch(removeInsurance()),
    clearOptions: () => _dispatch(clearOptions())
});

/** @namespace Scandipwa/Component/RelatedProductTotals/Container */
export class RelatedProductTotalsContainer extends PureComponent {
    static propTypes = {
        isCompact: PropTypes.bool,
        isInsurance: PropTypes.bool,
        removeInsurance: PropTypes.func.isRequired
    };

    static defaultProps = {
        isCompact: false,
        isInsurance: false
    };

    containerProps() {
        const {
            linkedProducts,
            optionsData: {
                items = {},
                categorizedItems = {},
                regularTotal: total,
                categorizedItemsTotals = {},
                currency,
                insurance = {}
            },
            isInsurance,
            clearOptions,
            isMobile,
            removeOption,
            removeInsurance,
            isCompact
        } = this.props;

        return {
            linkedProducts,
            items,
            clearOptions,
            categorizedItems,
            isMobile,
            total,
            categorizedItemsTotals,
            removeOption,
            currency,
            removeInsurance,
            isCompact,
            insurance,
            isInsurance
        };
    }

    render() {
        return (
            <RelatedProductTotals
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default
withRouter(
    withReducers(
        { LinkedProductsReducer, ProductOptionsReducer }
    )(connect(mapStateToProps, mapDispatchToProps)(RelatedProductTotalsContainer))
);
