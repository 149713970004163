/* eslint-disable react/prop-types */
/* eslint-disable max-len */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ToolTipPopup from 'Component/ToolTipPopup';
import { noopFn } from 'Util/Common';

import { TOOLTIP_POPUP_PREFIX } from './ToolTipButton.config';

import './ToolTipButton.style';

/** @namespace Scandipwa/Component/ToolTipButton/Component */
export class ToolTipButtonComponent extends PureComponent {
    renderIcon() {
        return (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.25 10C19.25 4.89137 15.1086 0.75 10 0.75C4.89137 0.75 0.75 4.89137 0.75 10C0.75 15.1086 4.89137 19.25 10 19.25C15.1086 19.25 19.25 15.1086 19.25 10Z" stroke="#FF9934" strokeWidth="1.5" />
                <path d="M11 10H9V16H11V10Z" fill="#FF9934" />
                <path d="M10 8C10.8284 8 11.5 7.32843 11.5 6.5C11.5 5.67157 10.8284 5 10 5C9.17157 5 8.5 5.67157 8.5 6.5C8.5 7.32843 9.17157 8 10 8Z" fill="#FF9934" />
            </svg>
        );
    }

    render() {
        const {
            toggleTooltip,
            tooltipContent,
            id,
            showTooltip: show,
            hideTooltip: hide,
            isHoverable,
            setActive,
            hasCloseBtn,
            isMobile
        } = this.props;

        const showTooltip = isHoverable ? show : noopFn;

        const hideTooltip = isHoverable ? hide : noopFn;

        const clickHandle = isHoverable && !isMobile ? noopFn : toggleTooltip;

        return (
            <div block="ToolTipButton">
            <button onClick={ clickHandle } onMouseEnter={ showTooltip } onMouseLeave={ hideTooltip } block="ToolTipButton" elem="Icon">
                { this.renderIcon() }
            </button>
            <ToolTipPopup
              id={ `${TOOLTIP_POPUP_PREFIX}-${id}` }
              clickOutside
              shouldPopupClose={ false }
              mix={ { block: 'ToolTip', elem: 'Popup' } }
              setActive={ setActive }
              hasCloseBtn={ hasCloseBtn }
            >
              { tooltipContent }
            </ToolTipPopup>
            </div>

        );
    }
}

export default ToolTipButtonComponent;
