import {
    CloseIcon as SourceCloseIcon
} from 'SourceComponent/CloseIcon/CloseIcon.component';

/** @namespace Scandipwa/Component/CloseIcon/Component */
export class CloseIconComponent extends SourceCloseIcon {
    /**
     * Overridden to update svg viewBox
     */
    render() {
        return (
            <svg
              block="CloseIcon"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M2 2L18 18" stroke="black" strokeWidth="3" />
                <path d="M18 2L2 18" stroke="black" strokeWidth="3" />
            </svg>

        );
    }
}

export default CloseIconComponent;
