import GoogleTagManager from '../component/GoogleTagManager';

export const MAX_POSITION = 1000;
export const INCREASED_POSITION = 10;
export const GOOGLE_TAG_MANAGER = 'GOOGLE_TAG_MANAGER';

const BEFORE_ITEMS_TYPE = (originalMember) => {
    const maxPosition = Math.max(
        ...originalMember.map((route) => route.position).filter((num) => num <= MAX_POSITION)
    );

    /*
    * Added this to prevent a duplicate item in BEFORE_ITEMS_TYPE
    */
    if (originalMember.find(({ name }) => name === GOOGLE_TAG_MANAGER)) {
        return originalMember;
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    return [
        ...originalMember,
        {
            component: <GoogleTagManager />,
            position: maxPosition + INCREASED_POSITION,
            name: GOOGLE_TAG_MANAGER
        }
    ];
};

export const config = {
    'Component/Router/Component': {
        'member-property': {
            BEFORE_ITEMS_TYPE
        }
    }
};

export default config;
