import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductPriceContainer as SourceProductPriceContainer
} from 'SourceComponent/ProductPrice/ProductPrice.container';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandipwa/Component/ProductPrice/Container */
export class ProductPriceContainer extends SourceProductPriceContainer {
    // Overridden to add price Range
    containerProps() {
        const {
            priceRange
        } = this.props;

        return {
            ...super.containerProps(),
            priceRange
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
