import PropTypes from 'prop-types';

import ChevronIcon from 'Component/ChevronIcon';
import {
    BOTTOM,
    TOP
} from 'Component/ChevronIcon/ChevronIcon.config';
import {
    ExpandableContent as SourceExpandableContent
} from 'SourceComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.override.style';

/** @namespace Scandipwa/Component/ExpandableContent/Component */
export class ExpandableContentComponent extends SourceExpandableContent {
    static propTypes = {
        ...super.propTypes,
        heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    };

    renderButtonIcon() {
        const { isContentExpanded } = this.state;
        const {
            isArrow,
            device: { isMobile },
            renderIcon
        } = this.props;

        if (!(isMobile || renderIcon)) {
            return null;
        }

        if (isArrow) {
            return <ChevronIcon direction={ isContentExpanded ? TOP : BOTTOM } />;
        }

        return this.renderTogglePlusMinus();
    }
}
export default ExpandableContentComponent;
