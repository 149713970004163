/* eslint-disable max-lines */
import { PRODUCT_TYPE } from 'Component/Product/Product.config';
import { formatPrice } from 'Util/Price';

export * from 'SourceUtil/Product/Extract';
/**
 * Returns price object for product.
 * @param priceRange
 * @param dynamicPrice
 * @param adjustedPrice
 * @param type
 * @param options
 * @returns {{originalPrice: {maxFinalPriceExclTax: {valueFormatted: string}, minFinalPrice: {valueFormatted: string}, minFinalPriceExclTax: {valueFormatted: string}, maxFinalPrice: {valueFormatted: string}}, price: {originalPriceExclTax: {currency: string, value: (number|number)}, originalPrice: {currency: string, value: (number|number)}, finalPriceExclTax: {currency: string, value: (number|number)}, finalPrice: {currency: string, value: (number|number)}, discount: {percentOff: number}}}}
 * @namespace Scandipwa/Util/Product/Extract/getPrice */
export const getPrice = (
    priceRange,
    dynamicPrice = false,
    adjustedPrice = {},
    type = PRODUCT_TYPE.simple,
    options = [],
    optionsTotalPrice = 0,
    ppValue = 0,
    mainProductPrice = {}
) => {
    if (type === 'dynamic' && ppValue) {
        const {
            minimum_price: {
                regular_price: { currency = 'USD' } = {}
            }
        } = priceRange || {};

        const { originalPrice: { maxFinalPrice: { value: mainFinalPrice } } } = mainProductPrice;
        const dynamicProductPrice = Math.round(Number(mainFinalPrice) * Number(ppValue));

        const configuration = {
            containsOptions: options && !!options.length,
            containsOptionsWithPrice: false,
            containsRequiredOptions: false,
            containsRequiredOptionsWithPrice: false
        };

        return {
            price: {
                finalPrice: {
                    currency,
                    value: dynamicProductPrice,
                    valueFormatted: formatPrice(dynamicProductPrice || 0, currency)
                },
                finalPriceExclTax: {
                    currency,
                    value: dynamicProductPrice,
                    valueFormatted: formatPrice(dynamicProductPrice || 0, currency)
                },
                originalPrice: {
                    currency,
                    value: dynamicProductPrice,
                    valueFormatted: formatPrice(dynamicProductPrice || 0, currency)
                },
                originalPriceExclTax: {
                    currency,
                    value: dynamicProductPrice,
                    valueFormatted: formatPrice(dynamicProductPrice || 0, currency)
                },
                discount: {
                    percentOff: 0
                }
            },
            originalPrice: {
                minRegularPrice: {
                    currency,
                    value: dynamicProductPrice,
                    valueFormatted: formatPrice(dynamicProductPrice || 0, currency)
                },
                minFinalPrice: {
                    currency,
                    value: dynamicProductPrice,
                    valueFormatted: formatPrice(dynamicProductPrice || 0, currency)
                },
                minFinalPriceExclTax: {
                    currency,
                    value: dynamicProductPrice,
                    valueFormatted: formatPrice(dynamicProductPrice || 0, currency)
                },
                maxRegularPrice: {
                    currency,
                    value: dynamicProductPrice,
                    valueFormatted: formatPrice(dynamicProductPrice || 0, currency)
                },
                maxFinalPrice: {
                    currency,
                    value: dynamicProductPrice,
                    valueFormatted: formatPrice(dynamicProductPrice || 0, currency)
                },
                maxFinalPriceExclTax: {
                    currency,
                    value: dynamicProductPrice,
                    valueFormatted: formatPrice(dynamicProductPrice || 0, currency)
                }
            },
            configuration
        };
    }

    const priceAcc = type === PRODUCT_TYPE.bundle
        ? 'default_final_price'
        : 'regular_price';
    const priceExcTaxAcc = type === PRODUCT_TYPE.bundle || type === PRODUCT_TYPE.configurable
        ? 'default_final_price_excl_tax'
        : 'regular_price_excl_tax';
    const priceExcTaxAccFallback = 'final_price_excl_tax';
    const accessRange = type === PRODUCT_TYPE.virtual || type === PRODUCT_TYPE.downloadable
        ? 'maximum_price'
        : 'minimum_price';

    const {
        [accessRange]: {
            [priceAcc]: { currency = 'USD', value: basePriceTemp = 0 } = {},
            [priceExcTaxAccFallback]: { value: basePriceExclTaxFallback = 0 } = {},
            [priceExcTaxAcc]: { value: basePriceExclTaxTemp = 0 } = {},
            discount: {
                percent_off: percentOffRef = 0,
                amount_off: amountOff = 0
            } = {}
        } = {},
        minimum_price: {
            regular_price: minRegularPrice = {},
            final_price: minFinalPrice = {},
            final_price_excl_tax: minFinalPriceExclTax = {}
        } = {},
        maximum_price: {
            regular_price: maxRegularPrice = {},
            final_price: maxFinalPrice = {},
            final_price_excl_tax: maxFinalPriceExclTax = {}
        } = {}
    } = priceRange || {};

    // Override change: add option total price to base price
    const basePrice = basePriceTemp + optionsTotalPrice;
    // * (JAID-570) Applying the `final_price_excl_tax` fallback in case the `default_final_price_excl_tax` equals 0
    const basePriceExclTax = (basePriceExclTaxTemp || basePriceExclTaxFallback) + optionsTotalPrice;

    // Fixes decimal misplacement for discount
    // eslint-disable-next-line no-magic-numbers
    const percentOffCalc = (amountOff / basePrice) * 100;
    // eslint-disable-next-line no-magic-numbers
    const percentOff = Math.round(percentOffCalc * 100) / 100 === percentOffRef
        ? percentOffCalc
        : percentOffRef;

    // eslint-disable-next-line no-magic-numbers
    const discountValue = (1 - percentOff / 100);
    // eslint-disable-next-line no-magic-numbers
    const discountValueRevert = discountValue === 0 ? 1 : discountValue;

    const basePriceExclDiscount = priceAcc === 'default_final_price'
        ? basePrice / discountValueRevert
        : basePrice;
    const basePriceExclDiscountExclTax = priceAcc === 'default_final_price'
        ? basePriceExclTax / discountValueRevert
        : basePriceExclTax;

    const priceValue = { value: dynamicPrice ? 0 : basePriceExclDiscount * discountValue, currency };
    const priceValueExclTax = { value: dynamicPrice ? 0 : basePriceExclDiscountExclTax * discountValue, currency };
    const priceValueExclDiscount = { value: dynamicPrice ? 0 : basePriceExclDiscount, currency };
    const priceValueExclDiscountExclTax = { value: dynamicPrice ? 0 : basePriceExclDiscountExclTax, currency };

    // Adds adjusted price
    Object.keys(adjustedPrice || {}).forEach((key) => {
        const { [key]: group } = adjustedPrice;
        const {
            inclTax = 0,
            exclTax = 0,
            requiresDiscountCalculations = true,
            hasDiscountCalculated = false
        } = group;

        if (requiresDiscountCalculations) {
            if (hasDiscountCalculated) {
                priceValue.value += inclTax;
                priceValueExclTax.value += exclTax;
                priceValueExclDiscount.value += inclTax / discountValueRevert;
                priceValueExclDiscountExclTax.value += exclTax / discountValueRevert;
            } else {
                priceValue.value += inclTax * discountValue;
                priceValueExclTax.value += exclTax * discountValue;
                priceValueExclDiscount.value += inclTax;
                priceValueExclDiscountExclTax.value += exclTax;
            }
        } else {
            priceValue.value += inclTax;
            priceValueExclTax.value += exclTax;
            priceValueExclDiscount.value += inclTax;
            priceValueExclDiscountExclTax.value += exclTax;
        }
    });

    // Adds formatted price option
    priceValue.valueFormatted = formatPrice(priceValue.value, currency);
    priceValueExclTax.valueFormatted = formatPrice(priceValueExclTax.value, currency);
    priceValueExclDiscount.valueFormatted = formatPrice(priceValueExclDiscount.value, currency);
    priceValueExclDiscountExclTax.valueFormatted = formatPrice(priceValueExclDiscountExclTax.value, currency);

    const configuration = {
        containsOptions: options && !!options.length,
        containsOptionsWithPrice: false,
        containsRequiredOptions: false,
        containsRequiredOptionsWithPrice: false
    };

    if (options) {
        configuration.containsOptionsWithPrice = !!options.find(
            ({ value = [] }) => Array.isArray(value) && value.find(({ price }) => price)
        );
        const requiredOptions = options.filter(({ required }) => required);
        configuration.containsRequiredOptions = !!requiredOptions.length;

        if (requiredOptions.length) {
            configuration.containsRequiredOptionsWithPrice = !!requiredOptions.find(
                ({ value = [] }) => Array.isArray(value) && value.find(({ price }) => price)
            );
        }
    }

    return {
        price: {
            finalPrice: priceValue,
            finalPriceExclTax: priceValueExclTax,
            originalPrice: priceValueExclDiscount,
            originalPriceExclTax: priceValueExclDiscountExclTax,
            discount: {
                percentOff
            }
        },
        originalPrice: {
            minRegularPrice: {
                ...minRegularPrice,
                valueFormatted: formatPrice(minRegularPrice.value || 0, currency)
            },
            minFinalPrice: {
                ...minFinalPrice,
                valueFormatted: formatPrice(minFinalPrice.value || 0, currency)
            },
            minFinalPriceExclTax: {
                ...minFinalPriceExclTax,
                valueFormatted: formatPrice(minFinalPriceExclTax.value || 0, currency)
            },
            maxRegularPrice: {
                ...maxRegularPrice,
                valueFormatted: formatPrice(maxRegularPrice.value || 0, currency)
            },
            maxFinalPrice: {
                ...maxFinalPrice,
                valueFormatted: formatPrice(maxFinalPrice.value || 0, currency)
            },
            maxFinalPriceExclTax: {
                ...maxFinalPriceExclTax,
                valueFormatted: formatPrice(maxFinalPriceExclTax.value || 0, currency)
            }
        },
        configuration
    };
};

/** @namespace Scandipwa/Util/Product/Extract/getAttribute */
export const getAttribute = (
    { attributes: variantAttributes = {} },
    parentAttributes,
    attributeName,
    parameters = {}
) => {
    const { [attributeName]: attributeDefaultValue = '' } = parameters;
    const { attribute_value = attributeDefaultValue } = variantAttributes?.[attributeName] || {};
    const { attribute_options = {} } = parentAttributes?.[attributeName] || {};
    const { label } = attribute_options?.[attribute_value] || {};
    return label;
};
