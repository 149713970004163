import {
    MY_ACCOUNT_LOGIN_POPUP_ID
} from 'Component/MyAccountLoginPopup/MyAccountLoginPopup.config';
import Popup from 'Component/Popup';
import { LoginAccountComponent } from 'Route/LoginAccount/LoginAccount.component';

import './MyAccountLoginPopup.style';

/** @namespace Scandipwa/Component/MyAccountLoginPopup/Component */
export class MyAccountLoginPopupComponent extends LoginAccountComponent {
    renderRequiredMark() {
        return (
            <div block="RequiredMark">
                <p>{ __('Required Fields') }</p>
                <span block="Field" elem="Label" mods={ { isRequired: true } }>
                        { ' *' }
                </span>
            </div>
        );
    }

    renderHeaderText() {
        const { title } = this.props;
        return (
            <h3 block="HeaderText">{ title }</h3>
        );
    }

    /**
     * Overridden to remove LoginHeader for popup.
     */
    renderSignInWrapper() {
        const { isMobile } = this.props;
        return (
            <div block="LoginAccount" elem="SignInWrapper">
                { isMobile && this.renderHeaderText() }
                <div block="LoginAccount" elem="ContentWrapper">
                { this.renderRequiredMark() }
                { this.renderSignIn() }
                </div>
            </div>
        );
    }

    render() {
        return (
            <Popup
              id={ MY_ACCOUNT_LOGIN_POPUP_ID }
              mix={ { block: 'MyAccountLoginPopup' } }
              clickOutside={ false }
            >
                { super.render() }
            </Popup>
        );
    }
}

export default MyAccountLoginPopupComponent;
