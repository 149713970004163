import { getStaticReducers as baseGetStaticReducers } from 'SourceStore/index';
import BreadcrumbsReducer from 'Store/Breadcrumbs/Breadcrumbs.reducer';
import ProductOptionsReducer from 'Store/ProductOptions/ProductOptions.reducer';
import ProductStepReducer from 'Store/ProductStep/ProductStep.reducer';

/** @namespace Scandipwa/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ...baseGetStaticReducers(),
    ProductStepReducer,
    ProductOptionsReducer,
    BreadcrumbsReducer
});

export default function injectStaticReducers(store) {
    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => store.injectReducer(name, reducer)
    );

    return store;
}
