import DownloadIcon from 'Component/DownloadIcon';
import FieldGroup from 'Component/FieldGroup';
import Link from 'Component/Link';
import {
    ProductDownloadableLinks as SourceProductDownloadableLinks
} from 'SourceComponent/ProductDownloadableLinks/ProductDownloadableLinks.component';

import './ProductDownloadableLinks.override.style';

/** @namespace Scandipwa/Component/ProductDownloadableLinks/Component */
export class ProductDownloadableLinksComponent extends SourceProductDownloadableLinks {
    /**
     * Overridden to add label and DownloadIcon
     */
    renderLink(link) {
        const { isOpenInNewTab } = this.props;
        const { sample_url } = link;

        if (!sample_url) {
            return null;
        }

        return (
            <Link
              to={ sample_url }
              isOpenInNewTab={ isOpenInNewTab }
              block="ProductDownloadableLink"
              elem="SampleLink"
            >
                { this.renderLabel(link) }
                <DownloadIcon />
            </Link>
        );
    }

    /**
     * Overridden to remove label
     */
    renderDownloadableLink(link) {
        const { id } = link;

        return (
            <div block="ProductDownloadableLink" key={ id }>
                { this.renderCheckBox.call(this, link) }
                { this.renderLink(link) }
            </div>
        );
    }

    /**
     * Overridden to add ProductDownloadableLinks-List class to div
     */
    renderLinks() {
        const { links, isRequired, setRef } = this.props;

        return (
            <FieldGroup
              validationRule={ {
                  isRequired
              } }
              validateOn={ ['onBlur'] }
            >
                <div
                  ref={ (elem) => setRef(elem) }
                  block="ProductDownloadableLinks"
                  elem="List"
                >
                    { links.map(this.renderDownloadableLink.bind(this)) }
                </div>
            </FieldGroup>
        );
    }

    /**
     * Overridden to switch from p to h2
     */
    renderTitle() {
        const { title, isRequired } = this.props;

        return (
            <h2 block="ProductDownloadableLinks" elem="Title">
                { title }
                { isRequired && <strong block="ProductDownloadableLinks" elem="Required"> *</strong> }
            </h2>
        );
    }

    /**
     * Overridden to remove ExpandableContent wrapper
     */
    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return this.renderPlaceholder();
        }

        return (
            <div
              block="ProductDownloadableLinks"
            >
                { this.renderContent() }
            </div>
        );
    }
}

export default ProductDownloadableLinksComponent;
