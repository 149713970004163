import { DELIVERY_STEP, ORDER_RESERVATION_STEP_NUMBER_MAP } from 'Route/OrderReservation';
import getStore from 'Util/Store';

import { EVENT_GTM_CHECKOUT_OPTION } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';
import { asyncEventDispatch } from '../util/Event';

const { dispatch } = getStore();

const componentDidMount = (args, callback, instance) => {
    const { method } = instance.state;

    const dispatchEvent = (eventName, payload) => dispatch(event(eventName, payload));

    asyncEventDispatch(dispatchEvent, EVENT_GTM_CHECKOUT_OPTION, {
        option: method,
        action: DELIVERY_STEP,
        step: ORDER_RESERVATION_STEP_NUMBER_MAP[DELIVERY_STEP]
    });

    callback(...args);
};

const componentDidUpdate = (args, callback, instance) => {
    const [_, prevState] = args;
    const { method: prevMethod = '' } = prevState;
    const { method = '' } = instance.state;

    if (method !== prevMethod) {
        dispatch(event(EVENT_GTM_CHECKOUT_OPTION, {
            option: method,
            action: DELIVERY_STEP,
            step: ORDER_RESERVATION_STEP_NUMBER_MAP[DELIVERY_STEP]
        }));
    }

    callback(...args);
};

export default {
    'Scandipwa/Component/DeliveryStep/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate
        }
    }
};
