export const ADD_OPTION = 'ADD_OPTION';
export const REMOVE_OPTION = 'REMOVE_OPTION';
export const CLEAR_OPTIONS = 'CLEAR_OPTIONS';
export const ADD_INSURANCE = 'ADD_INS';
export const REMOVE_INSURANCE = 'REMOVE_INS';

/** @namespace Scandipwa/Store/ProductOptions/Action/addOption */
export const addOption = (payload) => ({
    type: ADD_OPTION,
    payload
});

/** @namespace Scandipwa/Store/ProductOptions/Action/removeOption */
export const removeOption = (payload) => ({
    type: REMOVE_OPTION,
    payload
});

/** @namespace Scandipwa/Store/ProductOptions/Action/clearOptions */
export const clearOptions = () => ({
    type: CLEAR_OPTIONS
});

/** @namespace Scandipwa/Store/ProductOptions/Action/addInsurance */
export const addInsurance = (payload) => ({
    type: ADD_INSURANCE,
    payload
});

/** @namespace Scandipwa/Store/ProductOptions/Action/removeInsurance */
export const removeInsurance = () => ({
    type: REMOVE_INSURANCE
});
