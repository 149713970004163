import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MetaContainer as SourceMetaContainer
} from 'SourceComponent/Meta/Meta.container';
import OG_LOGO from 'Style/logos/logo.png';

import { OG_TYPE_MAP } from './Meta.config';

export {
    mapDispatchToProps
};

/** @namespace Scandipwa/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    type: state.UrlRewritesReducer.urlRewrite.type,
    locale: state.ConfigReducer.locale,
    og_image: state.MetaReducer.og_image
});

/** @namespace Scandipwa/Component/Meta/Container */
export class MetaContainer extends SourceMetaContainer {
    _generateOgMetaFromMetadata(metadata, param = 'property') {
        return Object.entries(metadata).reduce((acc, [key, value]) => {
            if (value) {
                acc.push({ [param]: key, content: value });
            }

            return acc;
        }, []);
    }

    _getOgType() {
        const { type } = this.props;

        return OG_TYPE_MAP[type] ?? 'website';
    }

    _getOgImage() {
        const {
            og_image: ogImage = ''
        } = this.props;

        const { location: { origin } } = window;

        if (!ogImage || ogImage.includes('placeholder')) {
            return `${origin}${OG_LOGO}`;
        }

        return ogImage;
    }

    /**
    * Overridden to add og_meta tags
    */
    _getMetadata() {
        const { locale } = this.props;

        const meta = {
            title: this._getTitle(),
            description: this._getDescription(),
            keywords: this._getKeywords()
        };

        const ogMeta = {
            'og:title': this._getTitle(),
            'og:type': this._getOgType(),
            'og:description': this._getDescription(),
            'og:url': window.location.href,
            'og:locale': locale,
            'og:image': this._getOgImage()
        };

        return [...this._generateMetaFromMetadata(meta), ...this._generateOgMetaFromMetadata(ogMeta)];
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
