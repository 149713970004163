// import PropTypes from 'prop-types';

import { ProductConfigurableAttributesContainer }
from 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.container';

import ProductFilteredConfigurableAttributes from './ProductFilteredConfigurableAttributes.component';

/** @namespace Scandipwa/Component/ProductFilteredConfigurableAttributes/Container */
export class ProductFilteredConfigurableAttributesContainer extends ProductConfigurableAttributesContainer {
    static propTypes = {
        // TODO: implement prop-types
    };

    containerFunctions = {
        handleOptionClick: this.handleOptionClick.bind(this),
        getSubHeading: this.getSubHeading.bind(this),
        isSelected: this.isSelected.bind(this),
        getLink: this.getLink.bind(this),
        getIsConfigurableAttributeAvailable: this.getIsConfigurableAttributeAvailable.bind(this)
    };

    containerProps() {
        return {
            ...super.containerProps()
        };
    }

    render() {
        return (
            <ProductFilteredConfigurableAttributes
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default ProductFilteredConfigurableAttributesContainer;
