/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/boolean-prop-naming */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { incrementParamsViewed } from 'Store/ProductStep/ProductStep.action';
import { ProductType } from 'Type/ProductList.type';
import { triggerPopup } from 'Util/Product/Product';

import ProductStepsTab from './ProductStepsTab.component';
import { STEP_POPUP_ID } from './ProductStepsTab.config';

/** @namespace Scandipwa/Component/ProductStepsTab/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace Scandipwa/Component/ProductStepsTab/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    incrementParamsViewed: () => dispatch(incrementParamsViewed())
});

/** @namespace Scandipwa/Component/ProductStepsTab/Container */
export class ProductStepsTabContainer extends PureComponent {
    static propTypes = {
        activeProduct: ProductType.isRequired,
        attribute_label: PropTypes.string,
        active: PropTypes.bool,
        selectedValueArray: PropTypes.arrayOf(PropTypes.object),
        isBehind: PropTypes.bool,
        isNextStep: PropTypes.bool,
        viewed: PropTypes.bool,
        isMobile: PropTypes.bool,
        inStock: PropTypes.bool,
        handleMobileBack: PropTypes.func.isRequired,
        isPopUpOnMobile: PropTypes.bool,
        popUpAttributes: PropTypes.shape({
            enabled: PropTypes.bool,
            popup_button_text: PropTypes.string,
            title: PropTypes.string
        }),
        triggerPopup: PropTypes.func.isRequired,
        handleOnClick: PropTypes.func.isRequired,
        incrementParamsViewed: PropTypes.func.isRequired,
        children: PropTypes.object.isRequired,
        stepCode: PropTypes.string.isRequired
    };

    static defaultProps = {
        attribute_label: '',
        active: false,
        isBehind: false,
        isNextStep: false,
        inStock: false,
        viewed: false,
        isMobile: false,
        selectedValueArray: [],
        isPopUpOnMobile: false,
        popUpAttributes: {
            enabled: false,
            popup_button_text: '',
            title: ''
        }
    };

    state = {
        viewed: false
    };

    containerFunctions = {
        triggerPopup: this.triggerPopup.bind(this)
    };

    /*
    * JAID-136 - Overriden to add viewed update method
    */
    componentDidUpdate() {
        this.updateViewed();
    }

    /*
    * JAID-136 - Created to determine if the attribute was manually selected by the user instead or is the default value
    * JAID-109 - Added param increment to verify if all the attributes were viewed
    */
    updateViewed() {
        const { active, incrementParamsViewed } = this.props;
        const { viewed } = this.state;
        if (active && !viewed) {
            this.setState({ viewed: true });
            incrementParamsViewed();
        }
    }

    triggerPopup() {
        const { popUpAttributes: { title }, stepCode } = this.props;
        triggerPopup(`${STEP_POPUP_ID}_${stepCode}`, { title });
    }

    containerProps() {
        const {
            activeProduct,
            attribute_label,
            active,
            children,
            selectedValueArray,
            handleOnClick,
            isBehind,
            isNextStep,
            isMobile,
            handleMobileBack,
            isPopUpOnMobile,
            popUpAttributes,
            inStock,
            stepCode
        } = this.props;

        const { viewed } = this.state;

        return {
            activeProduct,
            attribute_label,
            active,
            children,
            selectedValueArray,
            handleOnClick,
            isBehind,
            isNextStep,
            viewed,
            isMobile,
            handleMobileBack,
            isPopUpOnMobile,
            popUpAttributes,
            inStock,
            stepCode
        };
    }

    render() {
        return (
            <ProductStepsTab
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductStepsTabContainer);
