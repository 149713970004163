import { connect } from 'react-redux';

import {
    ATTRIBUTE_CODES_ARRAY
} from 'Component/ProductActions/ProductActions.config';
import {
    mapDispatchToProps, mapStateToProps,
    ProductContainer as SourceProductContainer
} from 'SourceComponent/Product/Product.container';
import fromCache from 'Util/Cache/Cache';
import { history } from 'Util/History';
import { getNewParameters, getVariantIndex } from 'Util/Product';
import { getProductInStock } from 'Util/Product/Extract';
import { setQueryParamsWithoutHistory } from 'Util/Url';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandipwa/Component/Product/Container */
export class ProductContainer extends SourceProductContainer {
    /*
    * JAID-136 - Overridden to add new default state
    */
    state = {
        ...this.state,
        firstLoad: true,
        configurableAttributePrices: {}
    };

    /**
     * Updates configurable products selected variant
     * @param key
     * @param value
     */
    updateConfigurableVariant(key, value, checkEmptyValue = false) {
        /**
         * Used determine which attributes should be changed when variant is not found based on key value pair
         * Ex: one engine attribute is selected in the left menu, but the variant that contains that specific engine was deleted.
         * In order to get an existing variant, we need to change attributes that come after engine, i.e. color_exterior or color_interior values
         * so that they match an existing variant
         */
        const { parameters: prevParameters, selectedProduct } = this.state;
        const { product: { variants, configurable_options } } = this.props;

        const { location } = history;

        const newParameters = getNewParameters(prevParameters, key, value);

        const { [key]: oldValue, ...currentParameters } = newParameters;
        const parameters = oldValue === '' && checkEmptyValue ? currentParameters : newParameters;

        this.setState({ parameters });

        const configurableOptionsKeys = Object.keys(configurable_options);
        const newIndex = Object.keys(parameters).length === configurableOptionsKeys.length
            ? getVariantIndex(variants, parameters, false)
            // Not all parameters are selected yet or there are no product in stock, therefore variantIndex must be invalid
            : -1;

        /**
         * Overridden to get variant if no product was found after searching based on selected parameter
         */
        if (newIndex !== -1) {
            // If variant was found, set it as the selected product
            const newProduct = variants[newIndex];

            if (newProduct && newProduct !== selectedProduct) {
                this.setState({
                    selectedProduct: newProduct,
                    parameters
                });
                setQueryParamsWithoutHistory(parameters, location, history);
            }

            const isInStock = fromCache(getProductInStock, [newProduct]);

            if (isInStock) {
                return;
            }
        }

        // eslint-disable-next-line fp/no-let
        let memorizedOutOfStockVariant = {};

        // Otherwise, get the variant that has the selected key=>value pair and all the already selected attributes
        const newProduct = variants.find(
            (variant) => {
                // First, check whether a product is in stock or not
                const isInStock = fromCache(getProductInStock, [variant]);

                // Get the ranks that are before the attribute
                const indexOfKey = ATTRIBUTE_CODES_ARRAY.indexOf(key);
                const ranksBeforeKey = indexOfKey !== -1
                    ? ATTRIBUTE_CODES_ARRAY.slice(0, indexOfKey)
                    : [];

                // If it is the first attribute, get the first variant that has that specific value
                if (ranksBeforeKey.length === 0) {
                    if (isInStock) {
                        return variant.attributes?.[key].attribute_value === value;
                    }

                    if (variant.attributes?.[key].attribute_value === value) {
                        memorizedOutOfStockVariant = variant;
                    }

                    return false;
                }

                if (!isInStock) {
                    return false;
                }

                // Filter based on lower attribute values
                const checkLowerRankedAttributes = ranksBeforeKey.every(
                    (element) => variant.attributes?.[element].attribute_value === parameters[element]
                );

                return variant.attributes?.[key].attribute_value === value && checkLowerRankedAttributes;
            }
            // if no variant was in stock
        ) ?? memorizedOutOfStockVariant;

        // Get new params from the selected variant
        const paramsAfterNewIndex = configurableOptionsKeys
            .reduce((params, option) => {
                // eslint-disable-next-line no-param-reassign
                params[option] = newProduct.attributes?.[option].attribute_value;

                return params;
            }, {});

        // Set the new parameters and the new product
        if (newProduct && newProduct !== selectedProduct) {
            this.setState({
                selectedProduct: newProduct,
                parameters: paramsAfterNewIndex
            });
            setQueryParamsWithoutHistory(paramsAfterNewIndex, location, history);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
