import { hash } from 'Util/Request/Hash';

import { HASH_SEED } from '../component/GoogleTagManager/GoogleTagManager.config';

/** @namespace GtmNew/EventData/ServiceBooking/Data/getServiceVehicleData */
export const getServiceVehicleData = (vehicle = {}) => {
    const {
        license_num = '',
        make_code,
        model,
        model_code,
        model_year,
        vin = ''
    } = vehicle;

    return {
        licenseNumber: hash(license_num, HASH_SEED),
        makeCode: make_code,
        model,
        modelCode: model_code,
        modelYear: model_year,
        vin: hash(vin, HASH_SEED)
    };
};

/** @namespace GtmNew/EventData/ServiceBooking/Data/getServiceContractData */
export const getServiceContractData = (vehicle = {}) => {
    const {
        service_contract,
        service_contract_valid,
        service_contract_valid_date,
        service_contract_valid_mileage
    } = vehicle;

    return {
        serviceContract: service_contract,
        serviceContractValid: service_contract_valid,
        serviceContractValidDate: service_contract_valid_date,
        serviceContractValidMileage: service_contract_valid_mileage
    };
};

/** @namespace GtmNew/EventData/ServiceBooking/Data/getServiceBookingData */
export const getServiceBookingData = (props = {}) => {
    const {
        selectedDate,
        selectedLocation,
        selectedTime,
        locationsList = [],
        serviceBookingSso
    } = props;
    const location = locationsList.find((item) => item?.filter_location_code === selectedLocation)?.store_name;

    return {
        serviceBookingSso,
        location,
        selectedDate,
        selectedTime
    };
};

/** @namespace GtmNew/EventData/ServiceBooking/Data/getServicePackageData */
export const getServicePackageData = (props = {}) => {
    const {
        selectedServicePackage,
        servicePackages = []
    } = props;

    const currentServicePackage = servicePackages.find((item) => item?.identifier === selectedServicePackage);
    const {
        identifier,
        description,
        main_group_code,
        make_code,
        mileage,
        price
    } = currentServicePackage || {};

    return {
        identifier,
        description,
        mainGroupCode: main_group_code,
        makeCode: make_code,
        mileage,
        price
    };
};

/** @namespace GtmNew/EventData/ServiceBooking/Data/getRevenue */
export const getRevenue = (props = {}) => {
    const {
        isPaid,
        selectedServicePackage,
        servicePackages = []
    } = props;

    const { price } = getServicePackageData({ selectedServicePackage, servicePackages });

    return isPaid ? price : 0;
};
