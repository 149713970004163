import { COLOR_EXTERIOR, COLOR_INTERIOR } from 'Component/ProductActions/ProductActions.config';

export const PRODUCT_ATTS_POPUP_ID = 'PRODUCT_ATTS_POPUP_ID';
export const VEHICLE_ATTR = [
    'engine',
    'displacement',
    'max_power',
    'max_torque',
    'transmission',
    'fuel_type',
    'wheel'
];
export const COLOR_ATTR = [
    COLOR_EXTERIOR,
    COLOR_INTERIOR
];
