import { connect } from 'react-redux';

import { MY_VEHICLES_URL } from 'Component/Header/Header.config';
import {
    MY_ACCOUNT_LOGIN_POPUP_ID
} from 'Component/MyAccountLoginPopup/MyAccountLoginPopup.config';
import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps as sourceMapDispatchProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/AddToCart/AddToCart.container';
import { updateIsAdding } from 'Store/Cart/Cart.action';
import { updateLinkedProductsLoadingStatus } from 'Store/LinkedProducts/LinkedProducts.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { clearOptions } from 'Store/ProductOptions/ProductOptions.action';
import { advanceStep } from 'Store/ProductStep/ProductStep.action';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { prepareCarWithOptions } from 'Util/Product';
import { appendWithStoreCode } from 'Util/Url';

import { MY_ACCOUNT_TEXT_CONSTANTS } from '../MyAccountLoginPopup/MyAccountLoginPopup.config';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const LinkedProductsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/LinkedProducts/LinkedProducts.dispatcher'
);

/** @namespace Scandipwa/Component/AddToCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    currentStepNumber: state.ProductStepReducer.currentStepNumber,
    paramsViewed: state.ProductStepReducer.paramsViewed,
    productOptionsData: state.ProductOptionsReducer
});

/** @namespace Scandipwa/Component/AddToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchProps(dispatch),
    moveToNextStep: () => dispatch(advanceStep()),
    showLoginPopup: (payload) => dispatch(showPopup(MY_ACCOUNT_LOGIN_POPUP_ID, payload)),
    clearProductOptions: () => dispatch(clearOptions()),
    resetCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.resetGuestCart(dispatch)
    ),
    updateIsQuoteCreating: (isAdding) => dispatch(updateIsAdding(isAdding)),
    updateLinkedProducts: (products) => LinkedProductsDispatcher.then(
        ({ default: dispatcher }) => {
            if (products && products.length) {
                dispatch(updateLinkedProductsLoadingStatus(true));
                dispatcher.handleData(dispatch, products);
            } else {
                dispatcher.clearLinkedProducts(dispatch);
            }
        }
    )
});

/** @namespace Scandipwa/Component/AddToCart/Container */
export class AddToCartContainer extends SourceAddToCartContainer {
    containerFunctions = {
        ...this.containerFunctions,
        onSaveClick: this.onSaveClick.bind(this)
    };

    saveCart = this.saveCart.bind(this);

    /*
    * JAID-136 - Overriden to pass current step, PDP check and click handler to component
    */
    containerProps() {
        const {
            currentStepNumber,
            moveToNextStep,
            isPdp,
            paramsViewed,
            productOptionsData: {
                insurance: {
                    product: {
                        sku
                    }
                }
            }
        } = this.props;

        return {
            ...super.containerProps(),
            currentStepNumber,
            moveToNextStep,
            isPdp,
            paramsViewed,
            isInsuranceSelected: !!sku
        };
    }

    containerFunctions = {
        ...this.containerFunctions,
        updateLinkedProducts: this.updateLinkedProducts.bind(this)
    };

    componentDidMount() {
        this.saveCartAfterSignUp();
    }

    // the approach is used for saving a cart once a customer is signed up (JAID-287)
    // didn't manage to find better way out of props drilling, a huge components nesting, etc
    // currently it works with passing custom route state at the MyAccountOverlay.container.js
    // TODO: find a better way to do this in case this is real
    saveCartAfterSignUp() {
        const {
            location: {
                state: {
                    shouldAddToCart = false
                } = {}
            }
        } = history;

        if (shouldAddToCart) {
            // added timeout to run a bit later (otherwise it fails)
            // 5 ms due to chrome 104 update (https://support.google.com/chrome/a/answer/7679408#nestSetTimeInt104)
            const TIME = 5;
            const timeout = setTimeout(() => {
                this.saveCart();
                clearTimeout(timeout);
            }, TIME);
        }
    }

    async saveCart() {
        const {
            getActiveProduct,
            fallbackAddToCart,
            productOptionsData: {
                items,
                insurance: {
                    product
                }
            },
            resetCart,
            updateIsQuoteCreating
        } = this.props;
        const { sku } = getActiveProduct();

        this.setState({ isAdding: true });
        updateIsQuoteCreating(true);

        const carOptions = product.sku ? {
            ...items,
            [product.sku]: product
        } : items;

        const products = prepareCarWithOptions(sku, carOptions);
        try {
            await resetCart();

            await fallbackAddToCart({ products });

            this.handleSuccessSave();
        } finally {
            this.setState({ isAdding: false });
            updateIsQuoteCreating(false);
        }
    }

    handleSuccessSave() {
        const { clearProductOptions } = this.props;

        clearProductOptions();
        history.push(appendWithStoreCode(MY_VEHICLES_URL));
    }

    onSaveClick() {
        const { showLoginPopup, isPdp } = this.props;

        const { location: { pathname, search } } = history;

        if (!isSignedIn()) {
            showLoginPopup({
                title: typeof MY_ACCOUNT_TEXT_CONSTANTS.popupHeaderText === 'function'
                    ? MY_ACCOUNT_TEXT_CONSTANTS.popupHeaderText()
                    : MY_ACCOUNT_TEXT_CONSTANTS.popupHeaderText,
                stateToPush: { isPdp, prevLocation: pathname, prevSearch: search },
                onSignIn: this.saveCart
            });

            return;
        }

        this.saveCart();
    }

    async updateLinkedProducts() {
        const { getActiveProduct, updateLinkedProducts } = this.props;
        const { automatic_related_products = [] } = getActiveProduct();

        updateLinkedProducts(automatic_related_products);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
