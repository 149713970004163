// import PropTypes from 'prop-types';

import { COLOR_EXTERIOR } from 'Component/ProductActions/ProductActions.config';
import ProductAttributeValue from 'Component/ProductAttributeValue';
import { ProductConfigurableAttributesComponent }
from 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.component';

import './ProductFilteredConfigurableAttributes.style';

/** @namespace Scandipwa/Component/ProductFilteredConfigurableAttributes/Component */
export class ProductFilteredConfigurableAttributesComponent extends ProductConfigurableAttributesComponent {
    /*
    * JAID-107 - Overriden to remove onclick
     */
    renderConfigurableAttributeValue(attribute) {
        const {
            getIsConfigurableAttributeAvailable,
            getLink,
            isSelected,
            showProductAttributeAsLink,
            inStock
        } = this.props;

        const { attribute_value } = attribute;

        return (
            <ProductAttributeValue
              key={ attribute_value }
              attribute={ attribute }
              isSelected={ isSelected(attribute) }
              isAvailable={ getIsConfigurableAttributeAvailable(attribute) && inStock }
              getLink={ getLink }
              showProductAttributeAsLink={ showProductAttributeAsLink }
            />
        );
    }

    /*
    * JAID-107 - Overriden to render only color exterior attribute
     */
    render() {
        const {
            configurable_options: {
                [COLOR_EXTERIOR]: option = {}
            } = {}
        } = this.props;

        return (
            <div block="ProductFilteredConfigurableAttributes">
                <h2 block="ProductConfigurableAttributes" elem="Title">
                    { __('Colors') }
                </h2>
                { this.renderSwatch(option) }
            </div>
        );
    }
}

export default ProductFilteredConfigurableAttributesComponent;
