const getActiveProduct = (args, callback, instance) => {
    const { product } = instance.props;
    const { selectedProduct } = instance.state;
    const { type_id } = product;

    if (type_id === 'configurable') {
        if (selectedProduct) {
            const fixedProduct = {
                ...selectedProduct,
                qty: 1,
                type_id: 'configurable'
            };

            return fixedProduct ?? product;
        }
    }

    return selectedProduct ?? product;
};

export default {
    'Component/Product/Container': {
        'member-function': {
            getActiveProduct
        }
    }
};
