import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import EstimatedDelivery from './EstimatedDelivery.component';
import { DELIVERY_EXTRA_DAYS_CONFIG } from './EstimatedDelivery.config';

/** @namespace Scandipwa/Component/EstimatedDelivery/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    extraDays: state.ConfigReducer[DELIVERY_EXTRA_DAYS_CONFIG]
});

/** @namespace Scandipwa/Component/EstimatedDelivery/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/EstimatedDelivery/Container */
export class EstimatedDeliveryContainer extends PureComponent {
    static propTypes = {
        date: PropTypes.oneOfType([
            PropTypes.string,
            null
        ]),
        extraDays: PropTypes.number.isRequired
    };

    static defaultProps = {
        date: ''
    };

    containerProps() {
        return {
            date: this.getDate()
        };
    }

    getDate() {
        const {
            date: dateString,
            extraDays
        } = this.props;

        if (!dateString) {
            return dateString;
        }

        const date = new Date(dateString.replaceAll('-', '/'));

        date.setDate(date.getDate() + extraDays);

        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        const prefix = this.getDatePrefix(date);

        return `${prefix}-${month}-${year}`;
    }

    /* eslint-disable no-magic-numbers */
    getDatePrefix(date) {
        const dateNr = date.getDate();

        switch (true) {
        case (this.isInRange(1, 10, dateNr)):
            return 'beginning';
        case (this.isInRange(11, 20, dateNr)):
            return 'mid';
        case (this.isInRange(21, 31, dateNr)):
            return 'end';
        default:
            return 'mid';
        }
    }
    /* eslint-enable no-magic-numbers */

    isInRange(rangeStart, rangeEnd, target) {
        return target >= rangeStart && target <= rangeEnd;
    }

    render() {
        return (
            <EstimatedDelivery
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EstimatedDeliveryContainer);
