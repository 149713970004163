/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component, react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-lines */

import { lazy, PureComponent, Suspense } from 'react';
import { withRouter } from 'react-router';

import ContentWrapper from 'Component/ContentWrapper';
import DealersOfferStep from 'Component/DealersOfferStep';
import Loader from 'Component/Loader';
import { SF_ORDER_STATUS_MAP } from 'Component/MyVehiclesQuotationPod/MyVehiclesQuotationPod.config';
import { scrollToTop } from 'Util/Browser';
import { getCarFromOrder } from 'Util/Orders/Orders';

import {
    CUSTOM_STYLING_STEPS,
    DEALERS_OFFER_STEP,
    ORDER_RESERVATION_STEP_NUMBER_MAP,
    PAYMENT_STEP, SUCCESS_STEP,
    SUMMARY_STEP,
    TRADE_IN_STEP
} from './OrderReservation.config';

import './OrderReservation.style.scss';

export const TradeInStep = lazy(() => import(
/* webpackMode: "lazy", webpackChunkName: "oder-reservation-trade-in" */
    'Component/TradeInStep'
));

export const PaymentStep = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "oder-reservation-payment" */
    'Component/PaymentStep'
));

export const DeliveryStep = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "oder-reservation-delivery" */
    'Component/DeliveryStep'
));

export const SuccessStep = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "oder-reservation-success" */
    'Component/SuccessStep'
));

export const SummaryStep = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "oder-reservation-summary" */
    'Component/SummaryStep'
));

/** @namespace Scandipwa/Route/OrderReservation/Component */
export class OrderReservationComponent extends PureComponent {
    stepMap = {
        [DEALERS_OFFER_STEP]: {
            number: ORDER_RESERVATION_STEP_NUMBER_MAP[DEALERS_OFFER_STEP],
            title: __('Vehicle Details'),
            url: `/${DEALERS_OFFER_STEP}`,
            render: this.renderDealersOfferStep.bind(this),
            areTotalsVisible: false
        },
        [PAYMENT_STEP]: {
            number: ORDER_RESERVATION_STEP_NUMBER_MAP[PAYMENT_STEP],
            title: __('Financing'),
            url: `/${PAYMENT_STEP}`,
            render: this.renderPaymentStep.bind(this),
            areTotalsVisible: false
        },
        [SUMMARY_STEP]: {
            number: ORDER_RESERVATION_STEP_NUMBER_MAP[SUMMARY_STEP],
            title: __('Summary'),
            url: `/${SUMMARY_STEP}`,
            render: this.renderSummaryStep.bind(this),
            areTotalsVisible: false
        },
        [SUCCESS_STEP]: {
            number: ORDER_RESERVATION_STEP_NUMBER_MAP[SUCCESS_STEP],
            title: __('Success'),
            url: `/${SUCCESS_STEP}`,
            render: this.renderSuccessStep.bind(this),
            areTotalsVisible: false
        }
    };

    componentDidMount() {
        this.updateStep();
    }

    componentDidUpdate(prevProps) {
        const { step } = this.props;
        const { step: prevStep } = prevProps;

        if (step !== prevStep) {
            this.updateStep();
        }
    }

    updateStep() {
        const {
            step,
            updateMetaTitle
        } = this.props;
        const { title } = this.stepMap[step];

        updateMetaTitle(title);
        scrollToTop({ behavior: 'smooth' });
    }

    renderDealersOfferStep() {
        const {
            handleDealersOfferStep,
            order,
            isMobile,
            isIos,
            baseOrderInfo
        } = this.props;

        if (!order || !order?.length) {
            return null;
        }

        return (
            <DealersOfferStep
              handleDealersOfferStep={ handleDealersOfferStep }
              order={ order }
              isMobile={ isMobile }
              isIos={ isIos }
              baseOrderInfo={ baseOrderInfo }
            />
        );
    }

    renderPaymentStep() {
        const {
            savePayment,
            baseOrderInfo: { grand_total, ihf_status },
            isIhfAvailableSAP
        } = this.props;

        const isIHFDeclined = ihf_status === SF_ORDER_STATUS_MAP.declined;

        return (
            <PaymentStep
              handlePaymentStep={ savePayment }
              orderTotal={ grand_total }
              isIhfAvailable={ !isIHFDeclined }
              isIhfAvailableSAP={ isIhfAvailableSAP }
            />
        );
    }

    renderTradeInStep() {
        const { saveTradeIn } = this.props;

        return (
            <TradeInStep
              saveTradeIn={ saveTradeIn }
            />
        );
    }

    renderDeliveryStep() {
        const { saveDeliveryOptions } = this.props;

        return (
            <DeliveryStep
              saveDeliveryOptions={ saveDeliveryOptions }
            />
        );
    }

    renderSummaryStep() {
        const { saveSummary } = this.props;

        return (
            <SummaryStep
              saveSummary={ saveSummary }
            />
        );
    }

    renderSuccessStep() {
        const {
            order,
            location
        } = this.props;

        if (!getCarFromOrder(order)) {
            return null;
        }

        return (
            <SuccessStep location={ location } />
        );
    }

    renderHeading() {
        const { step, isMobile } = this.props;

        if (isMobile || step === SUCCESS_STEP) {
            return null;
        }

        const { title } = this.stepMap[step];

        if (step === TRADE_IN_STEP) {
            return (
                <div block="TradeInHeader" elem="Wrapper">
                    <h1>{ title }</h1>
                    { this.renderRequiredFieldsText() }
                </div>
            );
        }

        return <h1>{ title }</h1>;
    }

    renderRequiredFieldsText() {
        return (
            <span block="RequiredFields" elem="RequiredText">
                { __('Required Fields') }
                <span block="RequiredFields" elem="RequiredStar">*</span>
            </span>
        );
    }

    renderStep() {
        const { step } = this.props;
        const { render } = this.stepMap[step];

        if (render) {
            return (
                <>
                    { this.renderHeading() }
                    <Suspense fallback={ <Loader isPayment={ step === SUCCESS_STEP } /> }>
                        { render() }
                    </Suspense>
                </>
            );
        }

        return null;
    }

    renderLeftMenuItem(item) {
        if (item === SUCCESS_STEP) {
            return null;
        }
        const { title, number } = this.stepMap[item];
        const {
            step,
            changeStep,
            handleStepBack,
            isMobile
        } = this.props;
        const isCurrent = step === item;
        const isCompleted = number < this.stepMap[step].number;
        const isFirstStep = !this.stepMap[step].number;

        const onClickHandlerDesktop = () => {
            if (!isCompleted) {
                return;
            }
            changeStep(item);
        };
        const onClickHandlerMobile = () => {
            if (isFirstStep) {
                return;
            }
            const prevStep = Object.keys(this.stepMap).find(
                (item) => this.stepMap[item].number === this.stepMap[step].number - 1
            );

            handleStepBack(prevStep);
        };

        return (
            <button
              key={ number }
              block="OrderReservation"
              elem="LeftMenuItem"
              onClick={ isMobile ? onClickHandlerMobile : onClickHandlerDesktop }
              mods={ { isCompleted } }
            >
                { isMobile && !isFirstStep && (
                    <span
                      block="OrderReservation"
                      elem="ChevronIcon"
                    />
                ) }
                <span
                  block="OrderReservation"
                  elem="LeftMenuItemTitle"
                  mods={ { isCurrent } }
                >
                    { `${number + 1}. ${title}` }
                </span>
                { isCompleted && !isMobile && (
                    <span
                      block="OrderReservation"
                      elem="LeftMenuItemTick"
                    />
                ) }
            </button>
        );
    }

    renderLeftMenu() {
        const { isMobile } = this.props;

        if (isMobile) {
            const { step } = this.props;

            if (step === TRADE_IN_STEP) {
                return (
                    <div block="TradeInButton">
                        { this.renderLeftMenuItem(step) }
                        { this.renderRequiredFieldsText() }
                    </div>
                );
            }

            return this.renderLeftMenuItem(step);
        }

        return Object.keys(this.stepMap).map((item) => this.renderLeftMenuItem(item));
    }

    render() {
        const { step, isOrderLoading } = this.props;
        const isSuccessStep = step === SUCCESS_STEP;

        return (
            <ContentWrapper wrapperMix={ { block: 'OrderReservation', elem: 'Page', mods: { isSuccessStep } } }>
                { !isSuccessStep && (
                    <div
                      block="OrderReservation"
                      elem="LeftMenu"
                    >
                        { this.renderLeftMenu() }
                    </div>
                ) }
                <div
                  block="OrderReservation"
                  elem="MainView"
                  mods={ { withInnerPadding: CUSTOM_STYLING_STEPS.includes(step) } }
                >
                    { this.renderStep() }
                </div>
                <Loader
                  isLoading={ isOrderLoading }
                  isPayment={ isSuccessStep }
                />
            </ContentWrapper>
        );
    }
}

export default withRouter(OrderReservationComponent);
