/* eslint-disable max-lines */

import PropTypes from 'prop-types';
import {
    createRef,
    PureComponent
} from 'react';
import { connect } from 'react-redux';

import { TRIM } from 'Component/ProductActions/ProductActions.config';
import { DeviceType } from 'Type/Device.type';
import { ProductType } from 'Type/ProductList.type';
import { noopFn } from 'Util/Common';
import { getIndexedConfigurableOptions } from 'Util/Product';
import { debounce } from 'Util/Request';

import CompareTrims from './CompareTrims.component';
import {
    ATTRIBUTES_TO_COMPARE,
    COLUMN_SCROLL,
    INVALID_ATTRIBUTE_THRESHOLD,
    NAV_LEFT,
    NAV_RIGHT,
    SCROLL_EVENT_DELAY
} from './CompareTrims.config';

/** @namespace Scandipwa/Component/CompareTrims/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/CompareTrims/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Scandipwa/Component/CompareTrims/Container */
export class CompareTrimsContainer extends PureComponent {
    static propTypes = {
        device: DeviceType.isRequired,
        onBack: PropTypes.func,
        product: ProductType
    };

    static defaultProps = {
        onBack: noopFn,
        product: {}
    };

    containerFunctions = {
        onTableNavigation: this.onTableNavigation.bind(this),
        setTableRef: this.setTableRef.bind(this),
        onBack: this.onBack.bind(this),
        onScrollControl: this.onScrollControl.bind(this)
    };

    tableRef = createRef();

    state = {
        scrollVisibility: {}
    };

    componentDidMount() {
        this.setState({
            scrollVisibility: {
                [NAV_LEFT]: this.getScrollVisibility(NAV_LEFT),
                [NAV_RIGHT]: this.getScrollVisibility(NAV_RIGHT)
            }
        });
    }

    componentWillUnmount() {
        this.tableRef.removeEventListener(
            'scroll',
            debounce(
                this.onScrollEvent.bind(this),
                SCROLL_EVENT_DELAY
            )
        );
    }

    containerProps() {
        const { device } = this.props;
        const { scrollVisibility } = this.state;

        return {
            trims: this.getTrims(),
            model: this.getModelName(),
            isScrolled: this.getIsScrolled(),
            scrollVisibility,
            device
        };
    }

    getModelName() {
        const { product: { name = '' } } = this.props;

        return name;
    }

    getTrims() {
        const {
            product: {
                configurable_options: {
                    [TRIM]: trim
                } = {}
            }
        } = this.props;

        if (!trim) {
            return {};
        }

        const { attribute_values: trimIds = [] } = this.getTrimsData();

        return trimIds.reduce((trims, trimId) => {
            const trimData = this.getTrimAttributes(trimId);

            if (!this.validateTrimData(trimData)) {
                return trims;
            }

            return {
                ...trims,
                [this.getTrimLabel(trimId)]: trimData
            };
        }, {});
    }

    validateTrimData(data = {}) {
        const invalidAttributes = Object
            .values(data)
            .reduce((acc, { value }) => (value ? acc : acc + 1), 0);

        return invalidAttributes <= INVALID_ATTRIBUTE_THRESHOLD;
    }

    getTrimsData() {
        const {
            product: {
                attributes = {}
            }
        } = this.props;

        const trim = this.getTrimFromConfigurableOptions() || {};
        const { [TRIM]: result = {} } = getIndexedConfigurableOptions(
            [trim],
            attributes
        );

        return result;
    }

    getTrimFromConfigurableOptions() {
        const {
            product: {
                configurable_options = {},
                configurable_options: {
                    [TRIM]: trim
                } = {}
            }
        } = this.props;

        // configurable_options could be either an object or an array
        if (Array.isArray(configurable_options)) {
            return configurable_options.find(({ attribute_code }) => attribute_code === 'trim');
        }

        return trim;
    }

    getTrimLabel(targetTrimId = '') {
        const { attribute_options = {} } = this.getTrimsData();
        const { label: result = '' } = attribute_options[targetTrimId];

        return result;
    }

    getTrimAttributes(targetTrimId = '') {
        const { product: { variants = [] } } = this.props;
        const { attributes } = variants.find(({
            attributes: {
                [TRIM]: {
                    attribute_value: currentTrimId
                } = {}
            } = {}
        }) => currentTrimId === targetTrimId) || {};

        return this.getAttributesData(ATTRIBUTES_TO_COMPARE, attributes);
    }

    getAttributesData(targetAttributeCodes = [], attributes = {}) {
        return targetAttributeCodes.reduce((result, targetCode) => {
            const {
                attribute_label: label,
                attribute_value: value
            } = attributes[targetCode] || {};

            return {
                ...result,
                [targetCode]: {
                    label,
                    value: value === null ? '-' : value
                }
            };
        }, {});
    }

    onTableNavigation(direction) {
        switch (direction) {
        case NAV_LEFT:
            break;
        case NAV_RIGHT:
            break;
        default:
        }
    }

    setTableRef(elem) {
        if (elem && this.tableRef !== elem) {
            this.tableRef = elem;
            this.tableRef.addEventListener(
                'scroll',
                debounce(
                    this.onScrollEvent.bind(this),
                    SCROLL_EVENT_DELAY
                )
            );
        }
    }

    onBack() {
        const { onBack } = this.props;

        onBack();
    }

    onScrollControl(direction) {
        if (direction === NAV_LEFT) {
            this.scroll(-COLUMN_SCROLL);

            return;
        }

        this.scroll(COLUMN_SCROLL);
    }

    scroll(value) {
        this.tableRef.scroll({
            left: this.tableRef.scrollLeft + value,
            behavior: 'smooth'
        });
    }

    toggleNavVisibility() {
        const { scrollVisibility } = this.state;
        const scrollDirections = [NAV_LEFT, NAV_RIGHT];

        const newState = scrollDirections.reduce((state, direction) => ({
            ...state,
            [direction]: this.getScrollVisibility(direction)
        }), scrollVisibility);

        this.setState({ scrollVisibility: newState });
    }

    getScrollVisibility(direction) {
        const OFFSET_CORRECTION = 0.25;
        const tableWidth = this.tableRef.firstChild?.offsetWidth;
        const maxPossibleScroll = tableWidth - this.tableRef?.offsetWidth - OFFSET_CORRECTION;
        const scrollState = this.tableRef.scrollLeft || 0;

        switch (direction) {
        case NAV_LEFT:
            return scrollState !== 0;
        case NAV_RIGHT:
            return scrollState <= maxPossibleScroll;
        default:
            return true;
        }
    }

    onScrollEvent() {
        this.toggleNavVisibility();
    }

    getIsScrolled() {
        return this.tableRef.scrollLeft > 0;
    }

    render() {
        return (
            <CompareTrims
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompareTrimsContainer);
