import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './RemoveOptionButton.style';

/** @namespace Scandipwa/Component/RemoveOptionButton/Component */
export class RemoveOptionButtonComponent extends PureComponent {
    static propTypes = {
        handleRemoveOption: PropTypes.func.isRequired
    };

    render() {
        const { handleRemoveOption } = this.props;

        return (
            <button onClick={ handleRemoveOption } block="RelatedProductTotals" elem="DeleteBtn">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 0H0V16H16V0Z" fill="#D9D9D9" />
                    <path d="M4 4L8.5 8.4988L12 12" stroke="white" strokeWidth="1.5" />
                    <path d="M12 4L7.5012 8.4988L4 12" stroke="white" strokeWidth="1.5" />
                </svg>
            </button>
        );
    }
}

export default RemoveOptionButtonComponent;
