import { STEPS_ORDER } from 'Component/ProductActions/ProductActions.config';

import {
    ADVANCE_STEP, CHANGE_STEP, INCREMENT_VIEWED, RESET_STEPS
} from './ProductStep.action';

/** @namespace Scandipwa/Store/ProductStep/Reducer/getInitialState */
export const getInitialState = () => ({
    currentStepNumber: 1,
    paramsViewed: 0
});

/** @namespace Scandipwa/Store/ProductStep/Reducer/setStep */
export const setStep = (index) => ({
    currentStepNumber: index
});

/** @namespace Scandipwa/Store/ProductStep/Reducer/ProductStepReducer */
export const ProductStepReducer = (state = getInitialState(), action) => {
    const { currentStepNumber, paramsViewed } = state;
    switch (action.type) {
    case ADVANCE_STEP:

        if (currentStepNumber < STEPS_ORDER.length) {
            return {
                ...state,
                ...setStep(currentStepNumber + 1)
            };
        }

        return state;

    case RESET_STEPS:

        return {
            ...state,
            ...setStep(1),
            paramsViewed: 1
        };

    case CHANGE_STEP:

        if (action.payload <= STEPS_ORDER.length) {
            return {
                ...state,
                ...setStep(parseInt(action.payload, 10))
            };
        }

        return state;

    case INCREMENT_VIEWED:

        if (paramsViewed < STEPS_ORDER.length) {
            return {
                ...state,
                paramsViewed: paramsViewed + 1
            };
        }

        return state;

    default:
        return state;
    }
};

export default ProductStepReducer;
