/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction, react/prop-types, max-len, react/jsx-no-bind, jsx-a11y/interactive-supports-focus, @scandipwa/scandipwa-guidelines/always-both-mappings, jsx-a11y/click-events-have-key-events */
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import './AccordionTabs.style';

/** @namespace Scandipwa/Component/AccordionTabs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Scandipwa/Component/AccordionTabs/Container */
export class AccordionTabsContainer extends PureComponent {
    state = {
        currentTab: 0
    };

    static propTypes = {
        isAccordionOnDesktop: PropTypes.bool
    };

    static defaultProps = {
        isAccordionOnDesktop: false
    };

    renderContent(child, idx) {
        const { currentTab } = this.state;

        const { children } = child;
        const filteredChildren = this.filterChildren(children);
        const tabContent = filteredChildren.find(({ attribs: { class: className } }) => className.includes('AccordionContent'));
        const { children: tabContentChildren } = tabContent;

        return (
            <div { ...attributesToProps(tabContent?.attribs) } mix={ { block: 'AccordionTabs', elem: 'TabContent', mods: { isVisible: currentTab === idx } } }>
                { domToReact(tabContentChildren) }
            </div>
        );
    }

    handleTabOnClick(idx, isClosable) {
        const { currentTab } = this.state;
        if (isClosable) {
            if (currentTab === idx) {
                this.setState({ currentTab: -1 });
            } else {
                this.setState({ currentTab: idx });
            }
        } else {
            this.setState({ currentTab: idx });
        }
    }

    renderTab(child, idx) {
        const { data: { attribs } } = this.props;
        const { children } = child;
        const { currentTab } = this.state;
        const filteredChildren = this.filterChildren(children);
        const tab = filteredChildren.find(({ attribs: { class: className } }) => className.includes('AccordionHeading'));
        const { children: tabNameChildren } = tab;
        const isClosable = (attribs?.class || '')?.includes('isClosable');

        return (
            <div
              { ...attributesToProps(tab?.attribs) }
              onClick={ () => {
                  this.handleTabOnClick(idx, isClosable);
              } }
              role="button"
              mix={ { block: 'AccordionTabs', elem: 'TabName', mods: { isSelected: currentTab === idx, isDarkChevron: (attribs?.class || '')?.includes('isDarkChevron') } } }
            >
                { domToReact(tabNameChildren) }
            </div>
        );
    }

    renderChild(child, idx) {
        const { attribs } = child;

        return (
            <div { ...attributesToProps(attribs) }>
                { this.renderTab(child, idx) }
                { this.renderContent(child, idx) }
            </div>
        );
    }

    filterChildren(children) {
        return children.reduce((acc, child) => {
            if (child?.data && !child?.data.replace(/\u21b5/g, '').replace(/\s/g, '').length) {
                return acc;
            }

            return [...acc, child];
        }, []);
    }

    render() {
        const { data: { children, attribs }, isAccordionOnDesktop, device: { isMobile } } = this.props;

        const filteredChildren = this.filterChildren(children);

        if (isMobile || isAccordionOnDesktop || (attribs?.class || '')?.includes('isAccordionOnDesktop')) {
            return (
                <div { ...attributesToProps(attribs) } mix={ { block: 'AccordionTabs', elem: 'ContentWrapperAccordion' } }>
                    { filteredChildren.map((child, idx) => this.renderChild(child, idx)) }
                </div>
            );
        }

        return (
            <div { ...attributesToProps(attribs) } mix={ { block: 'AccordionTabs', elem: 'ContentWrapper' } }>
                <div block="AccordionTabs" elem="TabsWrapper">
                    { filteredChildren.map((child, idx) => this.renderTab(child, idx)) }
                </div>
                <div block="AccordionTabs" elem="ContentWrapper">
                    { filteredChildren.map((child, idx) => this.renderContent(child, idx)) }
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(AccordionTabsContainer);
