import { withRouter } from 'react-router-dom';

import {
    ForgotPasswordComponent as SourceForgotPasswordComponent
} from 'SourceRoute/ForgotPassword/ForgotPassword.component';

import './ForgotPassword.override.style';

/** @namespace Scandipwa/Route/ForgotPassword/Component */
export class ForgotPasswordComponent extends SourceForgotPasswordComponent {
    /**
     * Overridden to remove everything except email-for-password-prompt
     */
    renderAdditionalContent() {
        return null;
    }

    /**
     * Created to move rendering logic to separate function
     */
    renderWrapperHeader() {
        return (
            <div block="ForgotPassword" elem="WrapperHeader">
                <h1>{ __('Reset Password') }</h1>
                <div block="RequiredMark">
                    <p>{ __('Required Fields') }</p>
                    <span block="Field" elem="Label" mods={ { isRequired: true } }>
                        { ' *' }
                    </span>
                </div>
            </div>
        );
    }

    /**
     * Overridden to change labels
     */
    renderForgotPasswordWrapper() {
        return (
            <div block="ForgotPassword" elem="ContainerWrapper">
                { this.renderWrapperHeader() }
                { this.renderForgotPassword() }
            </div>
        );
    }
}

export default withRouter(
    ForgotPasswordComponent
);
