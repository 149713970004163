import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    RouterContainer as BaseRouterContainer
} from 'SourceComponent/Router/Router.container';

export * from 'SourceComponent/Router/Router.container';

/** @namespace Scandipwa/Component/Router/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    canonical_url: state.MetaReducer.canonical_url
});

/** @namespace Scandipwa/Component/Router/Container */
export class RouterContainer extends BaseRouterContainer {
    /**
     * Overridden to only dispatch the dynamic title value in the updateMeta
     * title key as oppose to dispatching the default title first,
     * then the the dynamic title
     */
    componentDidUpdate(prevProps) {
        const { isLoading, updateMeta } = this.props;
        const { isLoading: prevIsLoading } = prevProps;

        if (!isLoading && isLoading !== prevIsLoading) {
            const {
                default_description,
                default_keywords,
                default_title,
                title_prefix,
                title_suffix,
                meta_title,
                status_code,
                canonical_url
            } = this.props;

            updateMeta({
                default_title,
                title: meta_title,
                default_description,
                description: default_description,
                default_keywords,
                keywords: default_keywords,
                title_prefix,
                title_suffix,
                status_code,
                canonical_url
            });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
