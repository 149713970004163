/* eslint-disable jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events, react/forbid-prop-types, react/boolean-prop-naming */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup';
import { OPTIONS_STEP } from 'Component/ProductActions/ProductActions.config';
import RelatedProductTotals from 'Component/RelatedProductTotals';
import TextPlaceholder from 'Component/TextPlaceholder';
import { ProductType } from 'Type/ProductList.type';
import { noopFn } from 'Util/Common';
import { getSelectedValueText } from 'Util/Product';

import { STEP_POPUP_ID } from './ProductStepsTab.config';

import './ProductStepsTab.style';

/** @namespace Scandipwa/Component/ProductStepsTab/Component */
export class ProductStepsTabComponent extends PureComponent {
    static propTypes = {
        attribute_label: PropTypes.string,
        active: PropTypes.bool,
        selectedValueArray: PropTypes.arrayOf(PropTypes.object),
        isBehind: PropTypes.bool,
        isNextStep: PropTypes.bool,
        viewed: PropTypes.bool,
        isMobile: PropTypes.bool,
        children: PropTypes.object.isRequired,
        handleMobileBack: PropTypes.func.isRequired,
        isPopUpOnMobile: PropTypes.bool,
        popUpAttributes: PropTypes.shape({
            enabled: PropTypes.bool,
            popup_button_text: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        }),
        triggerPopup: PropTypes.func.isRequired,
        handleOnClick: PropTypes.func.isRequired,
        activeProduct: ProductType.isRequired,
        inStock: ProductType.bool,
        stepCode: PropTypes.string.isRequired
    };

    static defaultProps = {
        attribute_label: '',
        active: false,
        isBehind: false,
        isNextStep: false,
        viewed: false,
        isMobile: false,
        selectedValueArray: [],
        isPopUpOnMobile: false,
        popUpAttributes: {
            enabled: false,
            popup_button_text: ''
        },
        inStock: false
    };

    /*
    * Renders popup with steps header content for mobile devices
    */
    renderPopUp() {
        const { children, stepCode } = this.props;
        return (
            <Popup
              id={ `${STEP_POPUP_ID}_${stepCode}` }
              hasCloseBtn
              clickOutside={ false }
              shouldPopupClose={ false }
              mix={ { block: 'ProductStepsTab', elem: 'Popup' } }
            >
                { children }
            </Popup>
        );
    }

    /*
    * JAID-136 - Renders the tab header for mobile and desktop
    * JAID-112 - Overridden to add popup to mobile devices
    */
    renderTabHeader() {
        const {
            attribute_label,
            activeProduct: {
                name
            },
            active,
            selectedValueArray,
            handleMobileBack,
            viewed,
            isMobile,
            isPopUpOnMobile,
            triggerPopup,
            stepCode,
            popUpAttributes: {
                enabled,
                popup_button_text
            } = {}
        } = this.props;

        if (isMobile) {
            if (isPopUpOnMobile && enabled) {
                const popupButtonText = typeof popup_button_text === 'function'
                    ? popup_button_text()
                    : popup_button_text;

                return (
                    <div
                      block="ProductStepsTab"
                      elem="TabHeader"
                    >
                        <div
                          role="button"
                          onClick={ handleMobileBack }
                          block="ProductStepsTab"
                          elem="TabHeaderTextWrapper"
                        >
                            <span block="TabHeaderChevron" />
                            <span block="TabHeaderText">{ attribute_label || <TextPlaceholder /> }</span>
                        </div>
                        <button onClick={ triggerPopup } block="PopupButton">
                            { popupButtonText }
                        </button>
                        { this.renderPopUp() }
                    </div>
                );
            }

            return (
                <div
                  role="button"
                  onClick={ handleMobileBack }
                  block="ProductStepsTab"
                  elem="TabHeader"
                >
                    <div block="ProductStepsTab" elem="TabHeaderTextWrapper">
                        <span block="TabHeaderChevron" />
                        <span block="TabHeaderText">{ attribute_label || <TextPlaceholder /> }</span>
                    </div>
                </div>
            );
        }

        if (stepCode === OPTIONS_STEP && !active && viewed) {
            return (
                <div
                  block="ProductStepsTab"
                  elem="TabHeader"
                  mods={ { isValueSelected: selectedValueArray.length && viewed } }
                >
                    <div block="ProductStepsTab" elem="TabHeaderTextWrapper">
                        <span block="TabHeaderText">{ attribute_label || <TextPlaceholder /> }</span>
                        <div block="ProductStepsTab" elem="TabSubHeader">
                                <RelatedProductTotals isCompact isMobile={ isMobile } />
                        </div>
                    </div>
                    <span block="TabChevron" />
                </div>
            );
        }

        return (
            <div
              block="ProductStepsTab"
              elem="TabHeader"
              mods={ { isValueSelected: selectedValueArray.length && viewed } }
            >
                <div block="ProductStepsTab" elem="TabHeaderTextWrapper">
                    <span block="TabHeaderText">{ attribute_label || <TextPlaceholder /> }</span>
                    <div block="ProductStepsTab" elem="TabSubHeader">
                        { selectedValueArray.map(({ value, label, code }) => value && (
                            <span
                              key={ value }
                              block="SelectedValueText"
                            >
                                { getSelectedValueText(value, label, name, code) }
                            </span>
                        )) }
                    </div>
                </div>
                <span block="TabChevron" />
            </div>
        );
    }

    render() {
        const {
            active = false,
            children = [],
            handleOnClick,
            isBehind = false,
            isNextStep,
            isMobile,
            isPopUpOnMobile,
            inStock,
            stepCode
        } = this.props;

        const clickHandler = active || isMobile ? noopFn : handleOnClick;

        const childrenToRender = isPopUpOnMobile && isMobile ? null : children;

        const isClickable = (isBehind || isNextStep) && inStock;

        return (
            <div
              role="button"
              onClick={ clickHandler }
              block="ProductStepsTab"
              elem="Tab"
              mods={ { active, isClickable, stepCode } }
            >
                { this.renderTabHeader() }
                <div block="ProductStepsTab" elem="TabContent">
                    { childrenToRender }
                </div>
            </div>
        );
    }
}

export default ProductStepsTabComponent;
