import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { MixType, ModsType, RefType } from 'Type/Common.type';
import { noopFn } from 'Util/Common';

import './DragScroll.style';

/** @namespace Scandipwa/Component/DragScroll/Component */
export class DragScrollComponent extends PureComponent {
    static propTypes = {
        scrollRef: RefType,
        children: PropTypes.arrayOf(PropTypes.node).isRequired,
        mix: MixType.isRequired,
        mods: ModsType.isRequired,
        isScrolling: PropTypes.bool.isRequired
    };

    static defaultProps = {
        scrollRef: noopFn
    };

    render() {
        const {
            scrollRef,
            children,
            isScrolling,
            mix,
            mods
        } = this.props;

        return (
            <div
              block="DragScroll"
              mix={ mix }
              mods={ { ...mods, isScrolling } }
              ref={ scrollRef }
            >
                { children }
            </div>
        );
    }
}

export default DragScrollComponent;
