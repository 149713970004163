import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductGalleryContainer as SourceProductGalleryContainer
} from 'SourceComponent/ProductGallery/ProductGallery.container';

export {
    mapDispatchToProps,
    mapStateToProps
};

/** @namespace Scandipwa/Component/ProductGallery/Container */
export class ProductGalleryContainer extends SourceProductGalleryContainer {
    static propTypes = {
        ...this.propTypes,
        mediaType: PropTypes.string.isRequired,
        isInterior: PropTypes.bool,
        isZoomDisabled: PropTypes.bool,
        isOnlyHQSource: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        isInterior: false,
        // A little confusing with source prop isZoomEnabled,
        // but it seems it is used for something else
        // (by default isZoomEnabled is false)
        isZoomDisabled: true,
        isOnlyHQSource: false
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            isTransitionOff: false
        };
    }

    componentDidUpdate(prevProps) {
        const {
            isInterior: prevIsInterior,
            product: { media_gallery_entries: prevMediaGallery = [] },
            isZoomEnabled: prevZoomEnabled
        } = prevProps;
        const {
            isInterior,
            product: { media_gallery_entries: mediaGallery = [] },
            isZoomEnabled,
            areDetailsLoaded
        } = this.props;

        if (isInterior !== prevIsInterior
        ) {
            this.setIsTransition(true);
        }

        if (
            JSON.stringify(mediaGallery) !== JSON.stringify(prevMediaGallery)
            || isZoomEnabled !== prevZoomEnabled
        ) {
            this.onGalleryChange(this.getBaseImage());

            if (areDetailsLoaded && mediaGallery.length > 0) {
                this.cacheImages();
            }
        }
    }

    setIsTransition(value) {
        this.setState({
            isTransitionOff: value
        });
    }

    onActiveImageChange(activeImage) {
        const { isTransitionOff } = this.state;
        if (isTransitionOff) {
            this.setIsTransition(false);
        }

        super.onActiveImageChange(activeImage);
    }

    onGalleryChange(activeImage) {
        super.onActiveImageChange(activeImage);
    }

    /*
    * Overridden to remove caching images, it will prevent loading unnecessary images
     */
    cacheImages() {
        return null;
    }

    /**
     * Overridden to pass mediaType prop and turn off zoom for interior
     */
    containerProps() {
        const {
            mediaType,
            isZoomDisabled,
            isOnlyHQSource,
            isInterior
        } = this.props;
        const { isTransitionOff } = this.state;

        return {
            ...super.containerProps(),
            mediaType,
            isTransitionOff,
            isZoomDisabled,
            isOnlyHQSource,
            isInterior
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductGalleryContainer);
