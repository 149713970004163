/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import RemoveOptionButton from './RemoveOptionButton.component';

/** @namespace Scandipwa/Component/RemoveOptionButton/Container */
export class RemoveOptionButtonContainer extends PureComponent {
    static propTypes = {
        handler: PropTypes.func.isRequired,
        handlerParams: PropTypes.object
    };

    static defaultProps = {
        handlerParams: null
    };

    containerFunctions = {
        handleRemoveOption: this.handleRemoveOption.bind(this)
    };

    handleRemoveOption() {
        const { handler, handlerParams } = this.props;
        if (handlerParams) {
            handler(handlerParams);
        } else {
            handler();
        }
    }

    render() {
        return (
            <RemoveOptionButton
              { ...this.containerFunctions }
            />
        );
    }
}

export default RemoveOptionButtonContainer;
