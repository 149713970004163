// import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ProductConfigurableAttributesContainer
from 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.container';
import { changeStep } from 'Store/ProductStep/ProductStep.action';
import { toggleScroll } from 'Util/Browser';

import ProductSteps from './ProductSteps.component';

/** @namespace Scandipwa/Component/ProductSteps/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    currentStepNumber: state.ProductStepReducer.currentStepNumber
});

/** @namespace Scandipwa/Component/ProductSteps/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    changeStep: (options) => dispatch(changeStep(options))
});

/** @namespace Scandipwa/Component/ProductSteps/Container */
export class ProductStepsContainer extends ProductConfigurableAttributesContainer {
    containerFunctions = {
        ...this.containerFunctions,
        toggleCompareTrims: this.toggleCompareTrims.bind(this)
    };

    state = {
        ...this.state,
        isCompareTrimsVisible: false
    };

    containerProps() {
        const {
            changeStep,
            currentStepNumber,
            isMobile,
            variants,
            activeProduct,
            areDetailsLoaded,
            isWithEmptySwitcher,
            showLoader
        } = this.props;
        const { isCompareTrimsVisible } = this.state;

        return {
            ...super.containerProps(),
            changeStep,
            currentStepNumber,
            isMobile,
            variants,
            activeProduct,
            areDetailsLoaded,
            isWithEmptySwitcher,
            showLoader,
            isCompareTrimsVisible
        };
    }

    toggleCompareTrims(isVisible = false) {
        const { isCompareTrimsVisible } = this.state;
        const { activeProduct: { attributes } = {} } = this.props;

        if ((isVisible === isCompareTrimsVisible) || (isVisible && !attributes)) {
            return;
        }

        this.setState(
            { isCompareTrimsVisible: isVisible },
            () => toggleScroll(!isVisible)
        );
    }

    render() {
        return (
            <ProductSteps
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductStepsContainer);
