import {
    filterData,
    getInitialState as sourceGetInitialState,
    MetaReducer,
    updateEveryTime as sourceUpdateEveryTime
} from 'SourceStore/Meta/Meta.reducer';

export {
    filterData,
    MetaReducer
};

export const updateEveryTime = [
    ...sourceUpdateEveryTime,
    'og_image'
];

/** @namespace Scandipwa/Store/Meta/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    og_image: ''
});

export default MetaReducer;
