import { connect } from 'react-redux';

import { STATE_CONFIRM_EMAIL } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import SmsVerificationQuery from 'Query/SmsVerification.query';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountCreateAccountContainer as SourceMyAccountCreateAccountContainer,
    MyAccountDispatcher
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.container';
import { updateMeta } from 'Store/Meta/Meta.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { scrollToTop } from 'Util/Browser';
import { getGuestQuoteId } from 'Util/Cart';
import transformToNameValuePair from 'Util/Form/Transform';
import { getSelectOptions } from 'Util/MyAccount';
import { fetchMutation, getErrorMessage } from 'Util/Request';

import { SMS_CODE_POPUP } from '../SmsCodePopup/SmsCodePopup.config';
import MyAccountCreateAccount from './MyAccountCreateAccount.component';
import {
    CITY_LIST,
    CONFIRMATION_REQUIRED,
    SALUTATION_LIST
} from './MyAccountCreateAccount.config';

export { MyAccountDispatcher };

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandipwa/Component/MyAccountCreateAccount/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    is_sms_enabled: state.ConfigReducer.sms_signup_verification_enable
});

/** @namespace Scandipwa/Component/MyAccountCreateAccount/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showSmsPopup:
        (phone, onSuccess, onError = () => {}) => dispatch(showPopup(SMS_CODE_POPUP, { onSuccess, onError, phone })),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    createNewCartForGuest: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.createGuestEmptyCart(dispatch)
    )
});

/** @namespace Scandipwa/Component/MyAccountCreateAccount/Container */
export class MyAccountCreateAccountContainer extends SourceMyAccountCreateAccountContainer {
    containerProps() {
        return {
            ...super.containerProps(),
            cities: getSelectOptions(CITY_LIST),
            salutations: getSelectOptions(SALUTATION_LIST)
        };
    }

    componentDidMount() {
        this.updateMetaTitle();
        scrollToTop({ behavior: 'smooth' });
    }

    updateMetaTitle() {
        const { updateMeta } = this.props;

        updateMeta({
            title: __('Sign Up')
        });
    }

    async onSuccess(form, fields) {
        const {
            createAccount,
            onSignIn,
            setSignInState,
            setLoadingState,
            isLoading,
            isLandingPage,
            showNotification,
            isMobile,
            is_sms_enabled,
            showSmsPopup,
            createNewCartForGuest
        } = this.props;

        const {
            password,
            email,
            firstname,
            lastname,
            is_subscribed,
            taxvat,
            phone = '1111111',
            qid,
            salutation,
            street,
            city
        } = transformToNameValuePair(fields);

        const customerData = {
            customer: {
                firstname,
                lastname,
                email,
                is_subscribed,
                taxvat,
                phone,
                qid,
                salutation,
                street,
                city
            },
            password
        };

        if (isLoading) {
            return;
        }

        const createAccountFunction = async () => {
            try {
                const code = await createAccount(customerData).catch(
                    /** @namespace Scandipwa/Component/MyAccountCreateAccount/Container/MyAccountCreateAccountContainer/onSuccess/createAccountFunction/code/createAccount/catch */
                    () => process.exit(1)
                );

                // if user needs confirmation
                if (code === CONFIRMATION_REQUIRED) {
                    setSignInState(STATE_CONFIRM_EMAIL);

                    if (isLandingPage || isMobile) {
                        showNotification(
                            'success',
                            // eslint-disable-next-line max-len
                            __('The email confirmation link has been sent to your email. Please confirm your account to proceed.')
                        );
                    }
                } else if (code !== false) {
                    onSignIn();
                }
            } finally {
                setLoadingState(false);
            }
        };

        if (is_sms_enabled) {
            const options = {
                quote_id: getGuestQuoteId(),
                phone,
                email
            };

            setLoadingState(true);
            await fetchMutation(SmsVerificationQuery.getCreationMutation(options)).then(
                /** @namespace Scandipwa/Component/MyAccountCreateAccount/Container/MyAccountCreateAccountContainer/onSuccess/then/finally/fetchMutation/then */
                () => {
                    showSmsPopup(phone, createAccountFunction);
                },
                /** @namespace Scandipwa/Component/MyAccountCreateAccount/Container/MyAccountCreateAccountContainer/onSuccess/then/finally/fetchMutation/then/catch */
                async (error) => {
                    // explicitly check for this message, because then making another request is pointless
                    if (getErrorMessage(error) === 'The provided email already exists on website!') {
                        // eslint-disable-next-line max-len
                        showNotification('error', __('The entered email address is associated with an account on Hyundai website, use Phone + QID to login'));
                    } else {
                        await createNewCartForGuest();
                        await fetchMutation(SmsVerificationQuery.getCreationMutation(options));
                        showSmsPopup(phone, createAccountFunction);
                    }
                }
            ).finally(
                /** @namespace Scandipwa/Component/MyAccountCreateAccount/Container/MyAccountCreateAccountContainer/onSuccess/then/finally/setLoadingState */
                () => setLoadingState(false)
            );
        } else {
            await createAccountFunction();
        }
    }

    render() {
        return (
            <MyAccountCreateAccount
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCreateAccountContainer);
