import {
    ProductBundleOptionsContainer as SourceProductBundleOptionsContainer
} from 'SourceComponent/ProductBundleOptions/ProductBundleOptions.container';
import { ItemOptionsType } from 'Type/ProductList.type';

/** @namespace Scandipwa/Component/ProductBundleOptions/Container */
export class ProductBundleOptionsContainer extends SourceProductBundleOptionsContainer {
    static propTypes = {
        ...this.propTypes,
        options: ItemOptionsType
    };
}

export default ProductBundleOptionsContainer;
