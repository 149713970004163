import {
    ProductCustomizableOptionsContainer as SourceProductCustomizableOptionsContainer
} from 'SourceComponent/ProductCustomizableOptions/ProductCustomizableOptions.container';

/** @namespace Scandipwa/Component/ProductCustomizableOptions/Container */
export class ProductCustomizableOptionsContainer extends SourceProductCustomizableOptionsContainer {
    static defaultProps = {
        options: []
    };
}

export default ProductCustomizableOptionsContainer;
