/* eslint-disable max-lines */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { createPortal } from 'react-dom';

import ChevronIcon from 'Component/ChevronIcon';
import DragScroll from 'Component/DragScroll';
import Loader from 'Component/Loader';
import { DeviceType } from 'Type/Device.type';
import { noopFn } from 'Util/Common';

import {
    ATTRIBUTES_TO_COMPARE,
    NAV_LEFT,
    NAV_RIGHT
} from './CompareTrims.config';

import './CompareTrims.style';

/** @namespace Scandipwa/Component/CompareTrims/Component */
export class CompareTrimsComponent extends PureComponent {
    static propTypes = {
        model: PropTypes.string,
        device: DeviceType,
        onBack: PropTypes.func,
        onScrollControl: PropTypes.func,
        scrollVisibility: PropTypes.shape({}),
        setTableRef: PropTypes.func,
        isScrolled: PropTypes.bool,
        trims: PropTypes.shape({}).isRequired
    };

    static defaultProps = {
        model: '',
        device: {
            isMobile: false
        },
        onBack: noopFn,
        onScrollControl: noopFn,
        setTableRef: noopFn,
        scrollVisibility: {
            [NAV_LEFT]: true,
            [NAV_RIGHT]: true
        },
        isScrolled: false
    };

    renderContent() {
        const {
            setTableRef,
            isScrolled
        } = this.props;

        return (
            <>
                { this.renderHeading() }
                <div
                  block="CompareTrims"
                  elem="Wrapper"
                  mods={ { isScrolled } }
                >
                    <DragScroll
                      mix={ { block: 'CompareTrims', elem: 'Table' } }
                      setRef={ setTableRef }
                    >
                        { this.renderTable() }
                    </DragScroll>
                </div>

            </>
        );
    }

    renderHeading() {
        const {
            model,
            device: {
                isMobile
            },
            trims
        } = this.props;

        if (!model) {
            return null;
        }

        return (
            <div block="CompareTrims" elem="Heading">
                { isMobile && this.renderBackButton() }
                <h3>{ __('Trim Comparison of %s', model) }</h3>
                { !!Object.keys(trims).length && this.renderScrollControlButtons() }
            </div>
        );
    }

    renderBackButton(label = '') {
        const { onBack } = this.props;

        return (
            <button
              onClick={ onBack }
              aria-label="go back"
              mix={ [
                  { block: 'CompareTrims', elem: 'BackButton' },
                  { block: 'Breadcrumb', elem: 'Link' }
              ] }
            >
                <ChevronIcon direction={ NAV_LEFT } />
                { label && <span>{ label }</span> }
            </button>
        );
    }

    renderScrollControlButtons() {
        return (
            <div block="CompareTrims" elem="ScrollButtons">
                { this.renderScrollButton(NAV_LEFT, 'ScrollLeft') }
                { this.renderScrollButton(NAV_RIGHT, 'ScrollRight') }
            </div>
        );
    }

    renderScrollButton(direction, elem) {
        const {
            onScrollControl,
            scrollVisibility
        } = this.props;

        return (
            <button
              mix={ [
                  { block: 'CompareTrims', elem },
                  {
                      block: 'CompareTrims',
                      elem: 'ScrollButton',
                      mods: { isDisabled: !scrollVisibility[direction] }
                  }
              ] }
                  // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => onScrollControl(direction) }
              aria-label={ elem }
            >
                <ChevronIcon direction={ direction } />
            </button>
        );
    }

    renderTable() {
        const { trims } = this.props;

        if (!Object.keys(trims).length) {
            return <h3>{ __('Not enough attribute data to make a comparison') }</h3>;
        }

        return (
            <table>
                { this.renderTitles() }
                { this.renderAttributes() }
            </table>
        );
    }

    renderTitles() {
        const { trims } = this.props;

        return (
            <thead>
                <tr>
                    { this.renderHeadField(__('Feature')) }
                    { Object
                        .keys(trims)
                        .map((trimLabel) => this.renderHeadField(trimLabel)) }
                </tr>
            </thead>
        );
    }

    renderAttributes() {
        return (
            <tbody>
                { Object
                    .values(ATTRIBUTES_TO_COMPARE)
                    .map((trimAttributeCode) => this.renderAttribute(trimAttributeCode)) }
            </tbody>
        );
    }

    renderAttribute(attributeCode) {
        const { trims } = this.props;
        const { label } = Object.values(trims)[0][attributeCode];

        return (
            <tr>
                { this.renderField(label) }
                { Object
                    .values(trims)
                    .map((data) => this.renderAttributeValue.bind(this)(data[attributeCode])) }
            </tr>
        );
    }

    renderAttributeValue({ value = '' }) {
        return this.renderField(value || <Loader isLoading />);
    }

    renderHeadField(value = '') {
        return (
            <th>
                { value }
            </th>
        );
    }

    renderField(value = '') {
        return (
            <td>
                { value }
            </td>
        );
    }

    renderBreadcrumbs() {
        return (
            <div
              mix={ [
                  { block: 'CompareTrims', elem: 'Breadcrumbs' },
                  { block: 'Breadcrumbs' },
                  { block: 'Breadcrumb' }
              ] }
            >
                { this.renderBackButton(__('Select Trims')) }
            </div>
        );
    }

    render() {
        const { device: { isMobile } } = this.props;

        return createPortal(
            <div
              mix={ [
                  { block: 'CompareTrims' },
                  { block: 'Popup', mods: { isVisible: true } }
              ] }
            >
                { !isMobile && this.renderBreadcrumbs() }
                <div block="Popup" elem="Content">
                    { this.renderContent() }
                </div>
            </div>,
            document.body
        );
    }
}

export default CompareTrimsComponent;
