export * from 'SourceType/Account.type';

export const MY_ACCOUNT = 'edit-profile';
export const ACCOUNT_INFORMATION = 'change-password';
export const VERIFICATION_DETAILS = 'verification-details';
export const VERIFICATION_DETAILS_URL = '/verification-details';

export const REQUIRED_FIELDS_LABEL = {
    [MY_ACCOUNT]: true,
    [ACCOUNT_INFORMATION]: true,
    [VERIFICATION_DETAILS]: true
};
