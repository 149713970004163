import BrowserDatabase from 'Util/BrowserDatabase';
import { getQueryParam } from 'Util/Url';

import {
    EVENT_GTM_FORM_SUBMIT_SERVICE, EVENT_GTM_SERVICE_BOOKING_PAID,
    EVENT_GTM_SERVICE_BOOKING_PAY_LATER
} from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';
import { asyncEventDispatch } from '../util/Event';

export const SERVICE_BOOKING_TEMP_DATA = 'SERVICE_BOOKING_TEMP_DATA';
export const SERVICE_BOOKING_TEMP_DATA_EXPIRATION_TIME = 300;

export const beforeSuccessSubmitForm = async (args, callback, instance) => {
    const [serviceBookingSso] = args;
    const { event, locationsList, location } = instance.props;
    const {
        vehicle: {
            license_num
        } = {},
        vehicle,
        selectedServicePackage,
        selectedDate,
        selectedLocation,
        selectedTime,
        servicePackages,
        request
    } = instance.state;
    const serviceId = getQueryParam('serviceId', location);

    // Trigger "form submit service" event each time on success form submit
    event(EVENT_GTM_FORM_SUBMIT_SERVICE);

    /*
    * Trigger "service booking pay later" event in case we have licence number
    * registered in DB and vehicle attached to it, with option "Pay later" selected
    */
    if (license_num) {
        const serviceBookingPayload = {
            vehicle,
            selectedServicePackage,
            selectedDate,
            selectedLocation,
            selectedTime,
            servicePackages,
            locationsList,
            request,
            serviceBookingSso
        };

        await asyncEventDispatch(event, EVENT_GTM_SERVICE_BOOKING_PAY_LATER, serviceBookingPayload);
    }

    /*
    * Trigger "service booking paid" event in case we choose "pay now" option and the page was
    * loaded with transaction serviceId in query params after payment.
    */
    if (serviceId) {
        const serviceBookingData = BrowserDatabase.getItem(SERVICE_BOOKING_TEMP_DATA);

        BrowserDatabase.deleteItem(SERVICE_BOOKING_TEMP_DATA);
        await asyncEventDispatch(
            event,
            EVENT_GTM_SERVICE_BOOKING_PAID,
            {
                ...serviceBookingData,
                isPaid: true,
                serviceBookingSso
            }
        );
    }

    await callback(...args);
};

export const beforeRedirectToPayment = async (args, callback, instance) => {
    const { locationsList } = instance.props;
    const {
        vehicle,
        selectedServicePackage,
        selectedDate,
        selectedLocation,
        selectedTime,
        servicePackages,
        request
    } = instance.state;

    const serviceBookingData = {
        vehicle,
        selectedServicePackage,
        selectedDate,
        selectedLocation,
        selectedTime,
        servicePackages,
        locationsList,
        request
    };

    if (BrowserDatabase.getItem(SERVICE_BOOKING_TEMP_DATA)) {
        BrowserDatabase.deleteItem(SERVICE_BOOKING_TEMP_DATA);
    }

    BrowserDatabase.setItem(
        serviceBookingData,
        SERVICE_BOOKING_TEMP_DATA,
        SERVICE_BOOKING_TEMP_DATA_EXPIRATION_TIME
    );

    await callback(...args);
};

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export default {
    'Scandipwa/Route/ServiceBooking/Container': {
        'member-function': {
            successSubmitForm: beforeSuccessSubmitForm,
            redirectToPayment: beforeRedirectToPayment
        }
    },
    'Scandipwa/Route/ServiceBooking/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
