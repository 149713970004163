import { ERROR_NOTIFICATION_LIFETIME } from 'Component/Notification/Notification.config';
import { getAuthorizationToken } from 'Util/Auth';

export const DOCUMENT_UPLOAD_URL = '/formdataupload/documentupload/upload';
export const CREDIT_REPORT_RADIO_FIELD = 'creditReportOption';
export const CREDIT_DOCUMENT_FIELD = 'creditdoc';

/** @namespace Scandipwa/Util/File/Helper/uploadFiles */
export const uploadFiles = async (options) => {
    const { body, onComplete, onError } = options;

    try {
        const response = await fetch(DOCUMENT_UPLOAD_URL, {
            method: 'POST',
            headers: new Headers({
                authorization: `Bearer ${getAuthorizationToken()}`
            }),
            body
        });

        if (onComplete) {
            onComplete();
        }

        return response;
    } catch (_error) {
        onError();

        // wait ERROR_NOTIFICATION_LIFETIME = 2.5s before new request
        await new Promise((resolve) => {
            setTimeout(resolve, ERROR_NOTIFICATION_LIFETIME);
        });

        return uploadFiles(options);
    }
};

/** @namespace Scandipwa/Util/File/Helper/uploadFilesUtil */
export const uploadFilesUtil = async ({ fields, onComplete, onError }) => {
    const fileFields = fields.filter((field) => field.type === 'file');
    const creditOption = fields.find(({ name, value }) => name === CREDIT_REPORT_RADIO_FIELD && value);

    const { value = CREDIT_DOCUMENT_FIELD } = creditOption;

    const fileData = fileFields.reduce((formData, fileField) => {
        const { field: { files }, name } = fileField;
        if (files[0]) {
            formData.append('documents[]', files[0]);
            formData.append('documentTypes[]', name === CREDIT_DOCUMENT_FIELD ? value : name);
        }

        return formData;
    }, new FormData());

    const hasDataToUpload = !!Array.from(fileData.entries()).length;

    if (hasDataToUpload) {
        const response = await uploadFiles({ body: fileData, onComplete, onError });
        const json = await response.json();

        return json.reduce((acc, value, index) => {
            const { name } = value;
            const { name: fieldName } = fileFields[index];

            acc[fieldName] = name;

            return acc;
        }, {});
    }

    onComplete(false);

    return {};
};
