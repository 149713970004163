import { connect } from 'react-redux';

import ProductConfigurableAttributeDropdown
from 'SourceComponent/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdown.container';

/** @namespace Scandipwa/Component/ProductConfigurableAttributeDropdown/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    showUnavailableOptions: state.ConfigReducer.show_unavailable_options
});

/** @namespace Scandipwa/Component/ProductConfigurableAttributeDropdown/Container */
export class ProductConfigurableAttributeDropdownContainer extends ProductConfigurableAttributeDropdown {
    _getSelectOptions() {
        const {
            option: {
                attribute_options,
                attribute_code
            },
            getIsConfigurableAttributeAvailable,
            showUnavailableOptions
        } = this.props;

        if (!attribute_options) {
            // eslint-disable-next-line no-console
            console.warn(`Please make sure "${ attribute_code }" is visible on Storefront.`);

            return [];
        }

        return Object.values(attribute_options)
            .reduce((acc, option) => {
                const { value } = option;

                const isAvailable = getIsConfigurableAttributeAvailable({
                    attribute_code,
                    attribute_value: value
                });

                if (!isAvailable && !showUnavailableOptions) {
                    return acc;
                }

                return [...acc, {
                    ...option,
                    id: value,
                    isAvailable
                }];
            }, []);
    }
}

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/always-both-mappings
export default connect(mapStateToProps)(ProductConfigurableAttributeDropdownContainer);
